import React from "react";
import SelectInput from "../../shared/SelectInput";
import TextInput from "../../shared/TextInput";

export default function Discount({ discount, onChange }) {
  return (
    <div className="confirm-declaration">
      <h3>Special Discount</h3>
      <TextInput
          name="discount"
          label="Enter discount for this order"
          value={discount}
          placeholder="Enter discount for this order"
          onChange={onChange}
        />
      {/* <SelectInput
        name="discount"
        label="Enter discount for this order"
        onChange={onChange}
        defaultOption={discount}
        value={discount}
        options={[
          {
            value: "1",
            text: "1%",
          },
          {
            value: "2",
            text: "2%",
          },
          {
            value: "3",
            text: "3%",
          },
          {
            value: "4",
            text: "4%",
          },
          {
            value: "5",
            text: "5%",
          },
          {
            value: "3036",
            text: "30:36 program",
          },
        ]}
      /> */}
    </div>
  );
}
