import * as types from "./actionTypes";
import * as reportApi from "../../api/reportApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadReportSuccess(report) {
  return { type: types.LOAD_REPORT_SUCCESS, report };
}

export function loadReport() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return reportApi
      .getStylesOrdered()
      .then((report) => {
        dispatch(loadReportSuccess(report));
        return report;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
