import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/accountlist";

export function getAccounts(auth) {
  return fetch(baseUrl, {
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    method: "GET",
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
