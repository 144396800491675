import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSalesReps } from "../../../api/orderApi";
import "../report/index.css";
import { cancelReturns } from "../../../api/orderApi";
import { FlexboxGrid } from "rsuite";
import { Grid, Row, Col } from "rsuite";

function OrderDetail({ adminAuth, auth, history }) {
  const [filter, setFilter] = useState("");
  const [salesReps, setSalesReps] = useState([]);
  const [displayExcelBtn, setDisplayExcelBtn] = useState(true);
  const [returnsData, setReturnsData] = useState([]);
  const [result, setResult] = useState([]);
  useEffect(() => {
    console.log("init call", adminAuth);
    if (adminAuth) {
      auth = adminAuth;
    }
    cancelReturns(auth)
      .then((response) => {
        console.log("getReturns", response);
        setReturnsData(response);
        setResult(response);
      })
      .catch((e) => {
        console.log("catch", e);
      });
    // testcall(adminAuth).then((response) => {
    //   console.log('test call',response);
    //   // setOrder(response[0]);
    //   // setOrderWithStyles(response);
    // })
    // .catch((e) => {
    //   console.log("test call catch", e);
    //   // setOnline(false);
    // });
    if (!salesReps || salesReps.length === 0) {
      getSalesReps(adminAuth)
        .then((response) => {
          setSalesReps(response);
          // setOrder(response[0]);
          // setOrderWithStyles(response);
        })
        .catch((e) => {
          console.log("catch", e);
          // setOnline(false);
        });
    }
  }, []);
  useEffect(() => {
    console.log("displayExcelBtn", displayExcelBtn);
  }, [displayExcelBtn]);

  const handleChange = async (event) => {
    const { value } = event.target;
    setFilter(value);
    const res = returnsData.filter(eachreturn);

    function eachreturn(eachreturn) {
      return eachreturn.Order_id.includes(value);
    }
    setResult(res);
    console.log("changed", value);
    console.log("name", res);

    // if (name.indexOf("filter") !== -1) {
    //   console.log("name", name);
    //   setFilter(value);
    //   if (value.length > 0) {
    //     let search = await arraySearch(fetchedData, value);
    //     setFilterData(search);
    //     setExcelData(search);
    //   } else {
    //     setFilterData(fetchedData);
    //     setExcelData(fetchedData);
    //   }

    //   // setCount(search.length)
    // } else if (name.indexOf("fetch_from_opera") !== -1) {
    //   if (checked) {
    //     setFetchOperaFields(true);
    //   } else {
    //     setFetchOperaFields(false);
    //   }
    // }
  };
  function viewItemDetail(returnId, orderId) {
    console.log("viewReturns");
    if (adminAuth) {
      auth = adminAuth;
    }
    // getReturnById(returnId, auth).then((response) => {
    //   console.log("response", response);
    //   setOrderData(response);
    // });
    history.push("/admins/viewDetails/" + returnId + "/returns/" + orderId);
    // getReturnById(returnId, auth).then((response) => {
    //   console.log("response return", response);
    //   // setOrderData(response);
    // });
  }
  return (
    <div className="report_page">
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <div
            className="form-group custom_group"
            style={{ marginTop: "10px" }}
          >
            <div className="field">
              <input
                type="text"
                name="filter"
                className="form-control custom_filter"
                placeholder="Filter with order id"
                value={filter}
                onChange={handleChange}
              />
            </div>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <div className="orders_data">
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Return #</th>
                    <th>Currency</th>
                    <th>Customer id</th>
                    <th>Date created</th>
                    <th>Order id</th>
                    <th>Total Value</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {result.map((eachreturn, i) => {
                    // console.log("ordersReturn", eachreturn);
                    return (
                      // i === 0 || (
                      // !returnsIds.includes(parseInt(order.Id)) ? (
                      <tr className="tb_row">
                        <td>
                          {eachreturn.Id.length > 0
                            ? "#" + eachreturn.Id[0]
                            : "#" + eachreturn.Id}
                        </td>
                        <td>{eachreturn.Currency}</td>
                        <td>
                          {eachreturn.Customer_id.length > 0
                            ? "#" + eachreturn.Customer_id
                            : "#" + eachreturn.Customer_id}
                        </td>

                        <td>{eachreturn.DateCreated}</td>
                        <td>
                          {eachreturn.Order_id.length > 0
                            ? "#" + eachreturn.Order_id
                            : "#" + eachreturn.Order_id}
                        </td>
                        <td>{eachreturn.TotalValue}</td>
                        <td className="setPad">
                          <div>
                            <button
                              className="viewitems1"
                              onClick={() =>
                                viewItemDetail(
                                  eachreturn.Id,
                                  eachreturn.Order_id
                                )
                              }
                            >
                              Details
                            </button>
                          </div>
                          {/* <div className="flex20">
                              <button
                                className="confirm1"
                                onClick={() =>
                                  updateTable(
                                    eachreturn.Id,
                                    eachreturn.Order_id
                                  )
                                }
                              >
                                Confirm
                              </button>
                              <button
                                className="deny1"
                                onClick={() =>
                                  denyTable(eachreturn.Id, eachreturn.Order_id)
                                }
                              >
                                Deny
                              </button>
                            </div> */}
                        </td>
                      </tr>
                    );
                    // );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    adminAuth: state.adminAuth,
    auth: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
