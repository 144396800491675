import React from "react";
import { NavLink } from "react-router-dom";
import Logout from "../auth/Logout";
import OrderLinks from "./OrderLinks";
import "./Index.css";

export default function ({ customer, auth }) {
  return auth ? (
    <nav className="main-nav">
      <NavLink exact to="/">
        Home
      </NavLink>
      <OrderLinks customer={customer} />
      <NavLink to="/reps/buildreport">BuildReport</NavLink>
      <NavLink to="/reps/reports">Reports</NavLink>
      <NavLink to="/orders">My Orders</NavLink>
      {/* <NavLink to="/offline">My Draft Orders</NavLink> */}

      <NavLink to="/draftorders/1">My Draft Orders</NavLink>
      <NavLink to="/returns">Returns</NavLink>
      <NavLink to="/stock">Stock</NavLink>
      {/* <NavLink to="/report">Reporting</NavLink> */}
      <Logout />
    </nav>
  ) : null;
}
