import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Row, Col } from "rsuite";
import "./Index.css";
import { getReturns } from "../../api/orderApi";

function ReturnsList({ history, auth, user, ...props }) {
  const [returnsData, setReturnsData] = useState([]);

  useEffect(() => {
    if (!returnsData || returnsData.length === 0) {
      console.log("init call");
      getReturns(auth)
        .then((response) => {
          console.log("getReturns", response);
          setReturnsData(response);
        })
        .catch((e) => {
          console.log("catch", e);
          setReturnsData([]);
        });
    }
  }, []);
  function handleReturn(Id) {
    console.log("id", Id);
    history.replace("/getreturn/" + Id);
  }
  return (
    <div className="content">
      {returnsData ? (
        <div className="orders_data">
          <Grid fluid>
            <Row gutter={16}>
              <Col xs={24}>
                <h3 style={{ marginBottom: "20px", textAlign: "center" }}>
                  Returns Requests
                </h3>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Order #</th>
                      <th>Customer</th>
                      <th>RMA</th>
                      <th>Total Value</th>
                      <th>Created Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {returnsData.map((item, i) => {
                      let date = item.DateCreated.split("T");
                      return (
                        <tr className="tb_row">
                          <td>{item.Order_id}</td>
                          <td>{item.Customer_id}</td>
                          <td>{item.RMA}</td>
                          <td>
                            {item.Currency}
                            {item.TotalValue}
                          </td>
                          <td>{date[0]}</td>
                          <td class="returns-action-btn">
                            <button
                              onClick={() => {
                                handleReturn(item.Order_id);
                              }}
                              className="btn btn-primary"
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Grid>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  console.log("state.orders", state.orders);
  return {
    auth: state.auth,
    user: state.user,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnsList);
