import React from "react";
import SelectInput from "../shared/SelectInput";

export default function ({warehouse, onChange }) {
  return (
    <div className="warehouse">
      <SelectInput
        name="warehouse"
        label="Warehouse"
        onChange={onChange}
        defaultOption={warehouse}
        value={warehouse}
        options={[
          {
            value: "USA",
            text: "USA",
          },
          {
            value: "MAIN",
            text: "UK",
          }
        ]}
      />
    </div>
  );
}
