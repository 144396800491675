import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function customerSaveInState(response) {
  return { type: types.CUSTOMER_SAVE_IN_STATE, response };
}

export function customerSaveInStateSuccess() {
  return { type: types.CUSTOMER_SAVE_IN_STATE_SUCCESS };
}

export function loadCustomerSuccess() {
  return { type: types.LOAD_CUSTOMER_SUCCESS };
}

export function loadCustomer() {
  return { type: types.LOAD_CUSTOMER };
}

export function saveCustomer(customer) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(customerSaveInState(customer));
      dispatch(customerSaveInStateSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}
