import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function adminAuthReducer(
  state = initialState.adminAuth,
  action
) {
  console.log("action.type", action.type);
  switch (action.type) {
    case types.ADMIN_AUTH_LOGIN_AUTHENTICATED:
      return (state = action.response.token);
    case types.ADMIN_AUTH_SESSION_CLEAR:
      return (state = null);
    default:
      return state;
  }
}
