import * as types from "./actionTypes";
import * as styleApi from "../../api/styleApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadStylesSuccess(styles) {
  return { type: types.LOAD_STYLES_SUCCESS, styles };
}

export function loadStyles(category, auth, currency) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return styleApi
      .getProductsByCategory(category, auth, currency)
      .then((styles) => {
        let sortedStyles = styles;

        sortedStyles.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        dispatch(loadStylesSuccess(sortedStyles));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
