import React from "react";
import "./Clear.css";

export default function ({ cart, emptyCart, label, customer, addresses }) {
  return (cart && cart.length > 0) ||
    (customer && Object.keys(customer).length) ||
    (addresses && addresses.length) ? (
    <button className="btn btn-clear-cart" onClick={emptyCart}>
      {label}
    </button>
  ) : null;
}
