import React from "react";
import { NavLink } from "react-router-dom";

export default function ({ customer, addresses }) {
  return Object.keys(customer).length === 1 ? (
    <>
      <NavLink to="/customer">New Order</NavLink>
    </>
  ) : (
    <>
      <NavLink to="/customer">Customer</NavLink>
      <NavLink to="/styles">Add Styles</NavLink>
    </>
  );
}
