import React, { useState, useEffect } from "react";
import { Offline } from "react-detect-offline";
import "./Index.css";
import { getNetwork } from "../../api/networkTest";
import { connect } from "react-redux";

function Network() {
  const [online, setOnline] = useState(true);

  useEffect(() => {
    getNetwork()
      .then((e) => {
        setOnline(true);
      })
      .catch((e) => {
        setOnline(false);
      });
  });

  return !online ? (
    <div className="network-banner">
      <Offline>
        You are offline, any orders will be submitted once back online
      </Offline>
      <p>
        Connection to server is down, you won't be able to submit orders and you
        may see old data (last updated)
      </p>
    </div>
  ) : null;
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Network);
