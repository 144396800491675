import React from "react";

const showDetailHeaders = (showDetail) => {
  return showDetail ? (
    <>
      <th>SKU</th>
      <th>Desc</th>
      <th>Size</th>
      <th>On Order</th>
      <th>Sales Orders</th>
      <th>In Stock</th>
      <th class="available">Available</th>
      <th class="available">Available Now</th>
    </>
  ) : (
    <>
      <th>SKU</th>
      <th>Desc</th>
      <th>Size</th>

      <th class="available">Available</th>
      <th class="available">Available Now</th>
    </>
  );
};

const showDetailTable = (showDetail, item) => {
  return showDetail ? (
    <>
      <td>{item.sku}</td>
      <td>{item.description}</td>
      <td>{getSize(item.sku)}</td>
      <td>{item.onorder}</td>
      <td>{item.saleorder}</td>
      <td>{item.instock}</td>
      <td class="available">{item.instock + item.onorder - item.saleorder}</td>
      <td class="available">
        {item.instock <= 0 ? 0 : item.instock - item.saleorder}
      </td>
    </>
  ) : (
    <>
      <td>{item.sku}</td>
      <td>{item.description}</td>
      <td>{getSize(item.sku)}</td>
      <td class="available">{item.instock + item.onorder - item.saleorder}</td>
      <td class="available">
        {item.instock <= 0 ? 0 : item.instock - item.saleorder}
      </td>
    </>
  );
};

const getSize = (sku) => {
  if (sku && sku != undefined) {
    let splitSku = sku.split("-");

    return "UK " + splitSku[1];
  }
};

export default function ({ stockList, onClick, showDetail }) {
  return stockList && stockList.length > 0 ? (
    <table onClick={onClick} class="stockTable">
      <thead>{showDetailHeaders(showDetail)}</thead>
      <tbody>
        {stockList.map((item, i) => {
          return <tr>{showDetailTable(showDetail, item)}</tr>;
        })}
      </tbody>
    </table>
  ) : null;
}
