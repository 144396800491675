import * as types from "./actionTypes";
import * as seasonApi from "../../api/seasonApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadSeasonsSuccess(categories) {
  return { type: types.LOAD_SEASONS_SUCCESS, categories };
}

export function setCurrentSeason(season) {
  return { type: types.SET_CURRENT_SEASON, season };
}

export function loadSeasons(auth) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return seasonApi
      .getSeasons(auth)
      .then((categories) => {
        dispatch(loadSeasonsSuccess(categories));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
