import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_BASE_URL;

const categoryId = process.env.REACT_APP_MAIN_CATEGORY;

function extractData(res) {
  return res.json().then((response) => {
    if (response && response.total_count > 0) {
      console.log('style response',response.items);
      return response.items;
    } else {
      return null;
    }
  });
}

export function getCategoryById(auth) {
  return fetch(baseUrl + "/getCategoryById?category=" + categoryId, {
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getProductsByCategory(category, auth, currency) {
  //prettier-ignore
  return fetch(
    baseUrl+"/productsByCategory?category="+category+"&type="+currency,
    {
      headers: {
        "authorization": "Bearer " + auth,
        "Content-Type": "application/json"
      },
      method: 'GET'
    }
  )
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
