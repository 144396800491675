import React from "react";
import { NavLink } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import "./Declaration.css";

export default function Declaration({ setSignature, signature, amend }) {
  let sigPad = {};

  const onChange = () => {
    setSignature(sigPad.getTrimmedCanvas().toDataURL("image/png"));
  };

  return !amend ? (
    <div className="confirm-declaration">
      <h3>Declaration</h3>
      <div className="declaration">
        <p>
          To protect the Strive brand and to give retailers a framework to work
          within when promoting the brand, we have developed a strict
          <NavLink to="/map"> Minimum Advertised Price (MAP) Policy</NavLink>.
        </p>
        <p>1)	By submitting this application, I/we understand that Strive Footwear shall not be obliged to accept this application nor give any reason for refusing the same, nor enter into any correspondence in regard thereto. I/we conﬁrm that the information given in this application for credit facility is in all respects true and accurate.</p>
        <p>2)	Where I/we provide you with personal data, I/we understand that the data will be held securely, in conﬁdence and processed for the purpose of carrying out our business activities. In considering this application, I/we accept that you may consult with and disclose the data to credit reference agencies, banks, credit insurers and other responsible organisations outside your business that you have nominated and that such third parties may process the data.</p>
        <p>3)	I/we understand that under the Data Protection Act 1998 I/we have the right to know what data you hold on us if I apply to you in writing.</p>
        <SignatureCanvas
          penColor="black"
          canvasProps={{
            width: 500,
            height: 300,
            className: "sigCanvas",
          }}
          ref={(ref) => {
            sigPad = ref;
          }}
          onEnd={onChange}
        />
      </div>
    </div>
  ) : null;
}
