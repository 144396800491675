import React, { useState, useEffect } from "react";
import Items from "../checkout/Items";

export default function CartList({
  cart,
  currency,
  addresses,
  totals,
  handleClearCart,
  getStyle,
}) {
  const getCartForAddress = (address, type) => {
    let cartList = cart.filter(function (item) {
      return item.address.address1 === address.address1;
    });

    if (!type) {
      return cartList;
    }

    let items = cartList.filter(function (item) {
      if (item.type) {
        return item.type.toLowerCase() === type;
      }
      return false;
    });
    return items;
  };

  return (
    <>
      <Items
        cart={cart}
        totals={totals}
        currency={currency}
        emptyCart={handleClearCart}
        getStyle={getStyle}
        addresses={addresses}
        getCartForAddress={getCartForAddress}
      />
    </>
  );
}
