import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SelectInput from "../../shared/SelectInput";
import Checkbox from "../../shared/Checkbox";
import { BsPlus } from "react-icons/bs";
import { FaMinus } from "react-icons/fa";
import {
  getOrderReport,
  getSalesReps,
  getQueryById,
} from "../../../api/orderApi";
// import "./index.css";
import ReactExport from "react-export-excel";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ReportForm({ adminAuth }) {
  const [queryName, setQueryName] = useState("");
  const [orderFromDate, setOrderFromDate] = useState("");
  const [orderToDate, setOrderToDate] = useState("");
  const [noOrderDate, setNoOrderDate] = useState("");
  const [dbColumns, setdbColumns] = useState([
    { name: "Style", value: "Style", checked: false },
    { name: "Colour", value: "Colour Name", checked: false },
    { name: "Size", value: "Size", checked: false },
    { name: "[Style].Price", value: "Price", checked: false },
    { name: "CustomerId", value: "Rep", checked: false },
  ]);
  const [allColumns, setAllColumns] = useState([]);
  const [saveQuery, setSaveQuery] = useState("");
  const [orderData, setOrderData] = useState("");

  const [seasonValues, setSeasonValues] = useState([]);
  const [repValues, setRepValues] = useState([]);
  const [statusValues, setStatusValues] = useState([]);

  const [salesReps, setSalesReps] = useState([]);
  const [queryData, setQueryData] = useState({});
  const [replaceExisting, setReplaceExisting] = useState("");

  const { Id } = useParams();
  let history = useHistory();

  let sizeArray = [
    "UK2",
    "UK3",
    "UK4",
    "UK4half",
    "UK5",
    "UK5half",
    "UK6",
    "UK6half",
    "UK7",
    "UK8",
    "UK9",
    "UK10",
    "UK11",
    "UK12",
    "UK34",
    "UK56",
    "UK78",
    "UK910",
    "UK1112",
  ];

  useEffect(() => {
    if (queryData && queryData.Season) {
      setQueryName(queryData.QueryName);
      setOrderToDate(queryData.ToDate);
      setOrderFromDate(queryData.FromDate);
      setNoOrderDate(queryData.NoOrderDate);

      let columns = '"' + queryData.Columns + '"';
      let clm = columns.split(",");

      let tempColumns = dbColumns;
      let contain_size = 0;
      let is_size_updated = 0;
      clm.map((e) => {
        let finalString = e.replaceAll('"', "");
        // if (finalString == "[Style].Price") {
        //   finalString = "Price";
        // }
        if (sizeArray.includes(finalString)) {
          contain_size = 1;
          tempColumns.map((elem, i) => {
            if (!is_size_updated) {
              if (elem.name == "Size") {
                is_size_updated = 1;
                dbColumns[i].checked = true;
              }
            }
          });
        } else {
          tempColumns.map((elem, i) => {
            if (elem.name == finalString) {
              dbColumns[i].checked = true;
            }
          });
        }
      });
      //   if (contain_size) tempColumns.push({ name: "Size", checked: true });
      //   console.log("dbColumns", dbColumns);
      setdbColumns(dbColumns);

      //   let seasons = '"' + queryData.Season + '"';

      let seasons = queryData.Season;
      seasons = seasons.split(",");
      let tempSeason = [];
      seasons.map((e) => {
        let finalString = e.replaceAll('"', "");
        finalString = finalString.replaceAll("'", "");

        tempSeason.push({ season: finalString });
      });
      setSeasonValues(tempSeason);

      let status = queryData.Status;
      status = status.split(",");
      let tempStatus = [];
      status.map((e) => {
        let finalString = e.replaceAll('"', "");
        finalString = finalString.replaceAll("'", "");
        tempStatus.push({ status: finalString });
      });
      setStatusValues(tempStatus);

      let reps = queryData.Reps;
      reps = reps.split(",");
      let tempReps = [];
      reps.map((e) => {
        let finalString = e.replaceAll('"', "");
        finalString = finalString.replaceAll("'", "");
        tempReps.push({ rep: finalString });
      });
      setRepValues(tempReps);
    }
  }, [queryData]);

  useEffect(() => {
    if (
      queryData &&
      Object.keys(queryData).length === 0 &&
      Object.getPrototypeOf(queryData) === Object.prototype
    ) {
      getQueryById(Id, adminAuth)
        .then((response) => {
          console.log("response querydata", response);
          setQueryData(response[0]);
        })
        .catch((e) => {
          console.log("catch", e);
          setQueryData({});
        });
    }

    if (!salesReps || salesReps.length === 0) {
      getSalesReps(adminAuth)
        .then((response) => {
          setSalesReps(response);
          // setOrder(response[0]);
          // setOrderWithStyles(response);
        })
        .catch((e) => {
          console.log("catch", e);
          // setOnline(false);
        });
    }
  }, []);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name.indexOf("query_name") !== -1) {
      setQueryName(value);
    } else if (name.indexOf("from_date") !== -1) {
      setOrderFromDate(value);
    } else if (name.indexOf("to_date") !== -1) {
      setOrderToDate(value);
    } else if (name.indexOf("no_order_date") !== -1) {
      if (checked) {
        setNoOrderDate(true);
      } else {
        setNoOrderDate(false);
      }
    } else if (name.indexOf("save_query") !== -1) {
      if (checked) {
        setSaveQuery(true);
      } else {
        setSaveQuery(false);
      }
    } else if (name.indexOf("replace_existing") !== -1) {
      if (checked) {
        setReplaceExisting(true);
      } else {
        setReplaceExisting(false);
      }
    }
  };
  const handleCheckboxChange = (i, e) => {
    let { name, value, checked } = e.target;

    if (checked) {
      let newDbColumns = [...dbColumns];
      if (!newDbColumns[i]) {
        newDbColumns[i] = { name: "", checked: checked };
      }

      if (name == "Price") {
        name = "[Style].Price";
      }
      newDbColumns[i].name = name;
      newDbColumns[i].checked = checked;

      setdbColumns(newDbColumns);
    } else {
      let newDbColumns = [...dbColumns];
      if (name == "Size") {
        newDbColumns = newDbColumns.filter((cl) => {
          if (sizeArray.includes(cl.name)) {
            return false;
          }
          return true;
        });
      }
      // newDbColumns.splice(i, 1);
      newDbColumns[i].name = "";
      newDbColumns[i].checked = false;

      setdbColumns(newDbColumns);
    }
  };

  //season events
  let handleSeasonchange = (i, e) => {
    let newSeasonValues = [...seasonValues];
    newSeasonValues[i][e.target.name] = e.target.value;
    setSeasonValues(newSeasonValues);
  };

  let addSeasonFields = () => {
    setSeasonValues([...seasonValues, { season: "" }]);
  };

  let removeSeasonFields = (i) => {
    let newSeasonValues = [...seasonValues];
    newSeasonValues.splice(i, 1);
    setSeasonValues(newSeasonValues);
  };
  //season events ends

  //Reps events
  let handleRepchange = (i, e) => {
    let newSeasonValues = [...repValues];
    newSeasonValues[i][e.target.name] = e.target.value;
    setRepValues(newSeasonValues);
  };

  let addRepFields = () => {
    setRepValues([...repValues, { rep: "" }]);
  };

  let removeRepFields = (i) => {
    let newRepValues = [...repValues];
    newRepValues.splice(i, 1);
    setRepValues(newRepValues);
  };
  //Reps events ends
  //Status events
  let handleStatuschange = (i, e) => {
    let newStatusValues = [...statusValues];
    newStatusValues[i][e.target.name] = e.target.value;
    setStatusValues(newStatusValues);
  };

  let addStatusFields = () => {
    setStatusValues([...statusValues, { status: "" }]);
  };

  let removeStatusFields = (i) => {
    let newStatusValues = [...statusValues];
    newStatusValues.splice(i, 1);
    setStatusValues(newStatusValues);
  };
  //Status events ends

  // let handleSubmit = (event) => {
  //   event.preventDefault();
  //   alert(JSON.stringify(seasonValues));
  // };

  function getRepsOptions() {
    let repOptions = [];
    if (salesReps && salesReps.length > 0) {
      salesReps.map((rep) => {
        let name = rep.User;
        repOptions.push({
          value: name,
          text: name,
        });
      });
    }
    return repOptions.reverse();
  }
  function getColumnName(name) {
    if (name == "[Style].Price") {
      return "Price";
    }
    return name;
  }
  const handleSubmit = (event) => {
    if (!noOrderDate) {
      if (orderFromDate != "" && orderToDate != "") {
        var strtDt = new Date(orderFromDate);
        var endDt = new Date(orderToDate);
        if (endDt <= strtDt) {
          toast.error("Order End Date Should be Greater Than From Date", {
            autoClose: true,
          });
          return;
        }
      }
    }

    let seasonString = seasonValues.map((e) => "'" + e.season + "'").join(",");
    let statusString = statusValues.map((e) => "'" + e.status + "'").join(",");
    let repString = repValues.map((e) => "'" + e.rep + "'").join(",");

    let finalColumns = dbColumns;

    let sizes_exist = finalColumns.find((e) => e.name == "UK3");
    if (!sizes_exist) {
      dbColumns.map((column) => {
        if (column.name == "Size") {
          if (column.checked) {
            sizeArray.map((size) => {
              finalColumns.push({ name: size });
            });
          }
        }
      });
    }

    const results = finalColumns.filter((element) => {
      if (element.name != "") {
        if (element.name != "Size") {
          return true;
        }
      }
      return false;
    });

    setAllColumns(results);

    let columnsString = results.map((e) => e.name).join(",");

    let formData = {
      Id: Id,
      query_name: queryName,
      season: seasonString,
      status: statusString,
      rep: repString,
      no_order_date: noOrderDate,
      from_date: orderFromDate,
      to_date: orderToDate,
      save_query: saveQuery,
      columns: columnsString,
      replace: replaceExisting,
    };
    let is_empty = 1;
    if (saveQuery) {
      if (formData.query_name == "") {
        toast.error("Please Write Query Name in order to save to Database", {
          autoClose: true,
        });
        return;
      }
    }
    if (formData.columns == "") {
      toast.error("Select Atleast single Order Column", {
        autoClose: true,
      });
      return;
    }
    if (formData.season != "''") {
      is_empty = 0;
    }
    if (formData.rep != "''") {
      is_empty = 0;
    }
    if (formData.status != "''") {
      is_empty = 0;
    }
    if (formData.no_order_date == "" || !formData.no_order_date) {
      if (formData.from_date != "") {
        is_empty = 0;
      }
      if (formData.to_date != "") {
        is_empty = 0;
      }
    }

    if (is_empty == 1) {
      toast.error("Fill Atleast single field to fetch order", {
        autoClose: true,
      });
      return;
    }
    console.log("formData", formData);
    return;
    getOrderReport(formData, adminAuth)
      .then((response) => {
        setOrderData(JSON.parse(response.body));
        let msg = "Report Fetched Successfully";
        if (saveQuery || replaceExisting) {
          msg = "Report Fetched and Query saved Successfully";
        }
        toast.success(msg, {
          autoClose: true,
        });
        // setOrder(response[0]);
        // setOrderWithStyles(response);
      })
      .catch((e) => {
        toast.error("Unable to Fetch Report, Try again", {
          autoClose: true,
        });
        // setOnline(false);
      });
  };
  const getFileName = () => {
    let string = queryName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
    let fileName = string.replaceAll(" ", "_").toLowerCase();
    if (!fileName) {
      fileName = "Download";
    }
    return fileName;
  };

  return (
    <div className="report_page">
      <h1>Report builder</h1>
      <div className="form-group">
        <label htmlFor="query_name">
          <b>Search Query</b>
        </label>
        <div className="field">
          <input
            type="text"
            name="query_name"
            className="form-control"
            placeholder="Query Name"
            value={queryName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="season_wrapper">
        <h5>Season</h5>
        {seasonValues && seasonValues.length > 0
          ? seasonValues.map((element, index) => (
              <>
                <div className="single_season" key={"season_" + index}>
                  <SelectInput
                    name="season"
                    label=""
                    onChange={(e) => handleSeasonchange(index, e)}
                    // defaultOption={getDefaultSeasonOption(element)}
                    defaultOption={"season"}
                    value={element.season}
                    options={[
                      {
                        value: "INSOLE",
                        text: "INSOLE",
                      },
                      {
                        value: "AW2020",
                        text: "AW20",
                      },
                      {
                        value: "SS2021",
                        text: "SS21",
                      },
                      {
                        value: "AW2021",
                        text: "AW21",
                      },
                      {
                        value: "SS2022",
                        text: "SS22",
                      },

                      {
                        value: "AW2022",
                        text: "AW22",
                      },
                      {
                        value: "SS2023",
                        text: "SS23",
                      },
                      {
                        value: "AW2023",
                        text: "AW23",
                      },
                      {
                        value: "SS2024",
                        text: "SS24",
                      },
                      {
                        value: "AW2024",
                        text: "AW24",
                      },
                      {
                        value: "SS2025",
                        text: "SS25",
                      },
                    ]}
                  />
                  {index > 0 ? (
                    <FaMinus
                      className="remove_icon"
                      onClick={() => removeSeasonFields(index)}
                    />
                  ) : (
                    <BsPlus
                      className="add_icon"
                      onClick={() => addSeasonFields()}
                    />
                  )}
                </div>
              </>
            ))
          : null}
      </div>
      <div className="form-group order_date">
        <label>
          <b>Order Date</b>
        </label>
        <div className="field">
          <input
            type="date"
            name="from_date"
            className="form-control from_date"
            placeholder="YYYY-MM-DD From"
            value={orderFromDate}
            onChange={handleChange}
          />
          <input
            type="date"
            name="to_date"
            className="form-control to_date"
            placeholder="YYYY-MM-DD To"
            value={orderToDate}
            onChange={handleChange}
          />
          <input
            type="checkbox"
            name="no_order_date"
            className="no_order_date"
            placeholder="To"
            value={noOrderDate}
            checked={noOrderDate}
            onChange={handleChange}
          />
          <label>No Date</label>
        </div>
      </div>

      {salesReps ? (
        <div className="reps_wrapper">
          <h5>Rep</h5>
          {repValues.map((element, index) => (
            <div className="single_rep" key={"rep_" + index}>
              <SelectInput
                name="rep"
                label=""
                onChange={(e) => handleRepchange(index, e)}
                defaultOption={"rep1"}
                value={element.rep}
                options={getRepsOptions()}
              />
              {index > 0 ? (
                <FaMinus
                  className="remove_icon"
                  onClick={() => removeRepFields(index)}
                />
              ) : (
                <BsPlus className="add_icon" onClick={() => addRepFields()} />
              )}
            </div>
          ))}
        </div>
      ) : null}
      <div className="status_wrapper">
        <h5>Status</h5>
        {statusValues.map((element, index) => (
          <div className="single_status" key={"status_" + index}>
            <SelectInput
              name="status"
              label=""
              onChange={(e) => handleStatuschange(index, e)}
              defaultOption={"rep1"}
              value={element.status}
              options={[
                {
                  value: 0,
                  text: "Confirmed",
                },
                {
                  value: 1,
                  text: "Draft",
                },
              ]}
            />
            {index > 0 ? (
              <FaMinus
                className="remove_icon"
                onClick={() => removeStatusFields(index)}
              />
            ) : (
              <BsPlus className="add_icon" onClick={() => addStatusFields()} />
            )}
          </div>
        ))}
      </div>
      {dbColumns ? (
        <div className="form-group db_columns">
          <h5>Report Columns</h5>
          {dbColumns.map((elem, i) => {
            return elem.hasOwnProperty("value") ? (
              <>
                <div className="field">
                  <input
                    type="checkbox"
                    name={elem.value}
                    className="no_order_date"
                    placeholder="To"
                    //   value={dbColumns.Colour}
                    checked={elem.checked}
                    onChange={(e) => handleCheckboxChange(i, e)}
                  />
                  <label>{elem.value}</label>
                </div>
              </>
            ) : null;
          })}
          {/* <div className="field">
            <input
              type="checkbox"
              name="Style"
              className="no_order_date"
              placeholder="To"
              //   value={dbColumns.Colour}
              checked={() => {
                return dbColumns[0].checked ? dbColumns[0].checked : false;
              }}
              onChange={(e) => handleCheckboxChange(0, e)}
            />
            <label>Style</label>
          </div>
          <div className="field">
            <input
              type="checkbox"
              name="Colour"
              className="no_order_date"
              placeholder="To"
              //   value={dbColumns.Colour}
              checked={getStatus()}
              onChange={(e) => handleCheckboxChange(1, e)}
            />
            <label>Colour Name</label>
          </div>
          <div className="field">
            <input
              type="checkbox"
              name="Size"
              className="no_order_date"
              placeholder="To"
              // value={noOrderDate}
              checked={() => {
                return dbColumns[2].checked ? dbColumns[2].checked : false;
              }}
              onChange={(e) => handleCheckboxChange(2, e)}
            />
            <label>Size</label>
          </div>
          <div className="field">
            <input
              type="checkbox"
              name="[Style].Price"
              className="no_order_date"
              placeholder="To"
              // value={noOrderDate}
              checked={() => {
                return dbColumns[3].checked ? dbColumns[3].checked : false;
              }}
              onChange={(e) => handleCheckboxChange(3, e)}
            />
            <label>Price</label>
          </div>
          <div className="field">
            <input
              type="checkbox"
              name="CustomerId"
              className="no_order_date"
              placeholder="To"
              // value={noOrderDate}
              checked={() => {
                return dbColumns[4].checked ? dbColumns[4].checked : false;
              }}
              onChange={(e) => handleCheckboxChange(4, e)}
            />
            <label>Rep</label>
          </div> */}
        </div>
      ) : null}
      <div className="form-group save_query">
        <h5>Save Query For Future</h5>
        <div className="field">
          <input
            type="checkbox"
            name="replace_existing"
            className="no_order_date"
            value={saveQuery}
            onChange={handleChange}
          />
          <label>Replace Existing Report</label>
        </div>
        <div className="field">
          <input
            type="checkbox"
            name="save_query"
            className="no_order_date"
            value={saveQuery}
            onChange={handleChange}
          />
          <label>Save as new Report</label>
        </div>
        <b>
          NOTE: if both checkboxes are selected replace checkbox will be used
        </b>
      </div>

      <a
        onClick={handleSubmit}
        //   disabled={saving}
        className="btn btn-primary btn-next btn-link-custom_ margin0"
      >
        Submit
      </a>
      {orderData ? (
        <div className="download_btn">
          <ExcelFile filename={getFileName()}>
            <ExcelSheet data={orderData} name="Order">
              <ExcelColumn label="OrderId" value="order_id" />
              <ExcelColumn label="Season" value="Season" />
              <ExcelColumn label="Type" value="Type" />
              <ExcelColumn label="Currency" value="Currency" />
              <ExcelColumn label="Discount" value="Discount" />
              {allColumns.map((e) => (
                <ExcelColumn
                  label={getColumnName(e.name)}
                  value={getColumnName(e.name)}
                />
              ))}
              <ExcelColumn label="Customer Email" value="Email" />
              <ExcelColumn label="Account Name" value="Store" />
              <ExcelColumn label="Street1" value="Street1" />
              <ExcelColumn label="Street2" value="Street2" />
              <ExcelColumn label="Town" value="Town" />
              <ExcelColumn label="County" value="County" />
              <ExcelColumn label="Postcode" value="Postcode" />
              <ExcelColumn label="Country" value="Country" />
              <ExcelColumn label="User" value="User" />
              <ExcelColumn label="is_draft" value="is_draft" />
              <ExcelColumn label="DateCreated" value="DateCreated" />

              {/* <ExcelColumn label="OrderId" value="order_id" />
              <ExcelColumn label="Colour" value="Colour" />
              <ExcelColumn label="Style" value="Style" /> */}
            </ExcelSheet>
          </ExcelFile>
        </div>
      ) : null}
    </div>
  );
}
function mapStateToProps(state) {
  return {
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReportForm);
