import React, { useEffect, useState } from "react";
import SelectInput from "../shared/SelectInput";
import "./Season.css";

const Seasons = ({ seasons, cart, seasonId, onChange, errors = {} }) => {
  const [seasonText, setSeasonText] = useState("not set");

  useEffect(() => {
    if (cart && cart.length != 0) {
      let seasonOptions = [];
      if (seasons && seasons.length > 0) {
        seasons.map((season) => {
          if (season.id == seasonId) {
            setSeasonText(season.name);
          }
          seasonOptions.push({
            value: season.id,
            text: season.name,
          });
        });
      }
    }
  }, []);

  function getSeasonOptions() {
    let seasonOptions = [];
    if (seasons && seasons.length > 0) {
      seasons.map((season) => {
        seasonOptions.push({
          value: season.id,
          text: season.name,
        });
      });
    }
    return seasonOptions.reverse();
  }

  return (
    <div className="season">
      {cart && cart.length == 0 ? (
        <SelectInput
          name="season"
          label="Season"
          onChange={onChange}
          defaultOption={
            seasons.length > 0 ? seasons[0] : { value: "not found" }
          }
          value={seasonId}
          error={errors.season}
          options={getSeasonOptions()}
        />
      ) : (
        <h3>SEASON {seasonText}</h3>
      )}
    </div>
  );
};

export default Seasons;
