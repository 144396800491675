import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function customerReducer(state = initialState.customer, action) {
  switch (action.type) {
    case types.LOAD_CUSTOMER:
      return state;
    case types.CANCEL_ORDER:
      return (state = { account: "NEW" });
    case types.CUSTOMER_SAVE_IN_STATE:
      return (state = action.response);
    case types.CUSTOMER_SAVE_IN_STATE_SUCCESS:
      return action.response;
    // case types.SUBMIT_ORDER_SUCCESS:
    //   return (state = { account: "NEW" });
    case types.AUTH_SESSION_CLEAR:
      return (state = { account: "NEW" });
    case types.LOAD_OFFLINE_ORDER:
      let cust = action.order.customer;
      cust["signature"] = action.order.signature;
      cust["comments"] = action.order.comments;
      cust["storageId"] = action.order.id;
      return (state = cust);
    case types.LOAD_ORDER_AMEND:
      let customer = {
        account: action.order[0].AccNo,
        storeName: action.order[0].Store,
        contactNameFirst: action.order[0].Forename,
        contactNameLast: action.order[0].Surname,
        email: action.order[0].Email,
        phone: action.order[0].Tel,
        type: "CURRENT",
      };
      return (state = customer);
    default:
      return state;
  }
}
