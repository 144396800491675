import React from "react";
import TextInput from "../shared/TextInput";
import SelectInput from "../shared/SelectInput";
import "./AddressForm.css";
import Checkbox from "../shared/Checkbox";

const AddressForm = ({
  address,
  customer,
  onSave,
  onChange,
  saving = false,
  errors = {},
  addressFormOpen,
  addressFormEdit,
  closeAddressForm,
  buttonToShow = true,
}) => {
  const isNew = () => {
    return customer && customer.account === "NEW" ? (
      <div>
        <Checkbox
          name="billing"
          value={address.billing}
          label={"Same as Billing Address"}
          onChange={onChange}
          error={errors.billing}
        />
      </div>
    ) : null;
  };

  return (
    <form onSubmit={(e) => onSave(e, addressFormEdit)}>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      {buttonToShow && isNew()}
      {addressFormOpen ? (
        <>
          {" "}
          <SelectInput
            name="country"
            label="Country"
            onChange={onChange}
            defaultOption={"United Kingdom"}
            value={address.country}
            error={errors.country}
            options={[
              { value: "United Kingdom", text: "United Kingdom" },
              { value: "Ireland", text: "Ireland" },
              { value: "United States", text: "United States" },
            ]}
          />
          <TextInput
            name="address1"
            label="Address Line 1"
            value={address.address1}
            placeholder="Address Line 1"
            onChange={onChange}
            error={errors.address1}
          />
          <TextInput
            name="address2"
            label="Address Line 2"
            value={address.address2}
            placeholder="Address Line 2"
            onChange={onChange}
            error={errors.address2}
          />
          <TextInput
            name="town"
            label="Town/City"
            value={address.town}
            placeholder="Contact Name"
            onChange={onChange}
            error={errors.town}
          />
          <TextInput
            name="county"
            label={address.country === "United States" ? "State" : "County"}
            value={address.county}
            placeholder={
              address.country === "United States" ? "State" : "County"
            }
            onChange={onChange}
            error={errors.county}
          />
          <TextInput
            name="postcode"
            label={address.country === "United States" ? "Zip" : "Postcode"}
            value={address.postcode}
            placeholder={
              address.country === "United States" ? "Zip" : "Postcode"
            }
            onChange={onChange}
            error={errors.postcode}
          />
          {buttonToShow ? (
            <>
              {addressFormEdit ? (
                <>
                  <button
                    type="submit"
                    disabled={saving}
                    className="btn btn-primary"
                  >
                    {saving ? "Submitting..." : "Submit Change"}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={closeAddressForm}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    disabled={saving}
                    className="btn btn-primary"
                  >
                    {saving ? "Submitting..." : "Submit Address"}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={closeAddressForm}
                  >
                    Cancel
                  </button>
                </>
              )}
            </>
          ) : null}
        </>
      ) : buttonToShow ? (
        <>
          {addressFormEdit ? (
            <>
              <button
                type="submit"
                disabled={saving}
                className="btn btn-primary"
              >
                {saving ? "Submitting..." : "Submit Change"}
              </button>
              <button className="btn btn-primary" onClick={closeAddressForm}>
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                type="submit"
                disabled={saving}
                className="btn btn-primary"
              >
                {saving ? "Submitting..." : "Submit Address"}
              </button>
              <button className="btn btn-primary" onClick={closeAddressForm}>
                Cancel
              </button>
            </>
          )}
        </>
      ) : null}
    </form>
  );
};

export default AddressForm;
