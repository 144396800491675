import React from "react";
import "./Items.css";

export default function ({ pos }) {
  return pos && pos.length > 0 ? (
    <div class="address-delivery">
      <table>
        <tbody>
          {pos.map((item, i) => (
            <tr key={item + i}>
              <td>
                {item.qty} x {item.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
}
