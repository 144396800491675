import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.AUTH_LOGIN_AUTHENTICATED:
      return (state = action.response.token);
    case types.AUTH_SESSION_CLEAR:
      return (state = null);
    default:
      return state;
  }
}
