import React, { useEffect, useState } from "react";
import "../../order/Index.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import CustomerDetails from "../../customer/CustomerDetails";
import { loadStyles } from "../../../redux/actions/styleActions";
import Spinner from "../../shared/Spinner";
import { Grid, Row, Col } from "rsuite";
import { insertReturn, getExistingReturn } from "../../../api/orderApi";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "../Index.css";

const categoryId = process.env.REACT_APP_MAIN_CATEGORY;

function Index({
  order,
  latestAmendedOrder,
  orders,
  styles,
  loadStyles,
  auth,
}) {
  let cart;
  const [orderData, setOrderData] = useState([]);
  const [totalReturnValue, setTotalReturnValue] = useState([]);
  const [existingReturnsData, setExisitingReturnsData] = useState();
  const [alreadyReturned, setAlreadyReturned] = useState([]);

  let history = useHistory();

  let sizes = {
    UK2: 0,
    UK3: 0,
    UK4: 0,
    UK4half: 0,
    UK5: 0,
    UK5half: 0,
    UK6: 0,
    UK6half: 0,
    UK7: 0,
    UK7half: 0,
    UK8: 0,
    UK9: 0,
    UK10: 0,
    UK11: 0,
    UK12: 0,
    UK34: 0,
    UK56: 0,
    UK78: 0,
    UK910: 0,
    UK1112: 0,
  };

  useEffect(() => {
    if (styles.length === 0) {
      //hard coded to select the master category, not ideal
      loadStyles(categoryId, auth).catch((error) => {
        console.log("Loading styles failed" + error);
      });
    }
  }, []);

  useEffect(() => {
    if (existingReturnsData == undefined) {
      getExistingReturn(order[0].OrderId, auth)
        .then((response) => {
          console.log("getReturns", response);
          setExisitingReturnsData(response);
        })
        .catch((e) => {
          console.log("catch", e);
          setExisitingReturnsData([]);
        });
    }
  });

  useEffect(() => {
    if (existingReturnsData != undefined) {
      if (order && order.length > 0) {
        // getExistingReturn(order[0].OrderId, auth)
        //   .then((response) => {
        //     console.log("getReturns", response);
        //     setExisitingReturnsData(response);
        //   })
        //   .catch((e) => {
        //     console.log("catch", e);
        //     setExisitingReturnsData([]);
        //   });
        console.log("order", order);
        console.log("existingReturnsData", existingReturnsData);
        let data = [];
        let total = 0;
        order.map((item) => {
          console.log("item", item);
          console.log("price", item.price);
          for (let size in sizes) {
            console.log("size", item["" + size + ""]);
            console.log("color", item["Colour"]);

            if (item["" + size + ""] > 0) {
              let size_arr = size.split("UK");
              let item_size = size_arr[1];
              if (size == "UK4half") item_size = "4.5";
              if (size == "UK5half") item_size = "5.5";
              if (size == "UK6half") item_size = "6.5";
              if (size == "UK7half") item_size = "7.5";
              let sku = item.Sku + "-" + item_size;
              let qty = item["" + size + ""];
              let singleItemPrice = (item.Price[1] / item.Qty[1]).toFixed(2);
              total += parseFloat(singleItemPrice);
              let skuToUse =
                item.Type != null
                  ? item.Type.toLowerCase() != "pos"
                    ? sku
                    : item.Sku
                  : sku;
              let alreadyRetunedItem = existingReturnsData.filter(
                (item) => item.Sku == skuToUse
              );
              let alreadyReturnedQty = 0;
              if (alreadyRetunedItem.length > 0) {
                let totalReturnItemsQty = 0;
                alreadyRetunedItem.map((ite) => {
                  totalReturnItemsQty += parseInt(ite.ReturnQty);
                });
                qty = qty - totalReturnItemsQty;
                alreadyReturnedQty = totalReturnItemsQty;
                // if (qty < 0) {
                //   qty = 0;
                // }
              }
              setAlreadyReturned(alreadyRetunedItem);
              console.log("alreadyRetunedItem", alreadyRetunedItem);
              let temp = {
                sku: skuToUse,
                style: item.Style,
                qty: qty,
                size: item_size,
                colour: item.Colour,
                value: singleItemPrice,
                returnQty: 0,
                returnValue: 0,
                itemData: item,
                deliveryDate: item.RequiredDate,
                currency: getCurrencyLabel(item.Currency),
                orderId: item.OrderId,
                styleId: item.Id[2],
                alreadyReturnedQty: alreadyReturnedQty,
              };
              data.push(temp);
            }
          }
        });
        setTotalReturnValue(total.toFixed(2));
        setOrderData(data);
      }
    }
  }, [existingReturnsData]);

  // useEffect(() => {
  //   if (order && order.length > 0) {
  //     console.log("order", order);
  //     console.log("existingReturnsData", existingReturnsData);

  //     let data = [];
  //     let total = 0;
  //     order.map((item) => {
  //       console.log("item", item);
  //       console.log("price", item.price);
  //       for (let size in sizes) {
  //         console.log("size", item["" + size + ""]);
  //         console.log("color", item["Colour"]);

  //         if (item["" + size + ""] > 0) {
  //           let size_arr = size.split("UK");
  //           let item_size = size_arr[1];
  //           if (size == "UK4half") item_size = "4.5";
  //           if (size == "UK5half") item_size = "5.5";
  //           if (size == "UK6half") item_size = "6.5";
  //           if (size == "UK7half") item_size = "7.5";
  //           let sku = item.Sku + "-" + item_size;
  //           let qty = item["" + size + ""];
  //           let singleItemPrice = (item.Price[1] / item.Qty[1]).toFixed(2);
  //           total += parseFloat(singleItemPrice);
  //           let temp = {
  //             sku:
  //               item.Type != null
  //                 ? item.Type.toLowerCase() != "pos"
  //                   ? sku
  //                   : item.Sku
  //                 : sku,
  //             style: item.Style,
  //             qty: qty,
  //             size: item_size,
  //             colour: item.Colour,
  //             value: singleItemPrice,
  //             returnQty: 0,
  //             returnValue: 0,
  //             itemData: item,
  //             deliveryDate: item.RequiredDate,
  //             currency: getCurrencyLabel(item.Currency),
  //             orderId: item.OrderId,
  //             styleId: item.Id[2],
  //           };
  //           data.push(temp);
  //         }
  //       }
  //     });
  //     setTotalReturnValue(total.toFixed(2));
  //     setOrderData(data);
  //   }
  // }, []);
  useEffect(() => {
    console.log("orderdata", orderData);
  }, [orderData]);
  function getUSSize(size) {
    switch (parseFloat(size)) {
      case 2:
        return 35;
      case 3:
        return 36;
      case 4:
        return 37;
      case 4.5:
        return 37.5;
      case 5:
        return 38;
      case 5.5:
        return 38.5;
      case 6:
        return 39;
      case 6.5:
        return 40;
      case 7:
        return 41;
      case 8:
        return 42;
      case 9:
        return 43;
      case 10:
        return 44;
      case 11:
        return 45;
      default:
        return null;
    }
  }
  const getCurrencyLabel = (curr) => {
    let currency = null;
    switch (curr) {
      case "GBP":
        currency = "£";
        break;
      case "EUR":
        currency = "€";
        break;
      case "USD":
        currency = "$";
        break;
      default:
        currency = "£";
        break;
    }
    return currency;
  };

  const getCustomer = (currentOrder) => {
    let customer = null;
    if (currentOrder && currentOrder.length > 0 && currentOrder[0].CustomerId) {
      customer = {
        account: currentOrder[0].CustomerId,
        storeName: currentOrder[0].Store,
        contactNameFirst: currentOrder[0].Forename,
        contactNameLast: currentOrder[0].Surname,
        email: currentOrder[0].Email,
        phone: currentOrder[0].Tel,
      };
    }
    return customer;
  };

  const getStatus = (order) => {
    console.log("status order", order);
    if (order && order.length > 0) {
      if (order[0].Cancelled) {
        return <h2 class="order-notice cancelled">Cancelled</h2>;
      } else if (order[0].Imported) {
        return <h2 class="order-notice imported">Imported</h2>;
      } else if (order[0].Confirmed) {
        return <h2 class="order-notice confirmed">Confirmed</h2>;
      } else if (order[0].Amended) {
        return <h2 class="order-notice amended">Amended</h2>;
      }
    }
  };
  const getDiscount = () => {
    console.log(order);
    if (order[0].SpecialDiscount) {
      return order[0].SpecialDiscount + "% off initial order";
      // if (order[0].SpecialDiscount > 5) {
      //   return order[0].SpecialDiscount + " Program Offer";
      // } else {
      //   return order[0].SpecialDiscount + "% off initial order";
      // }
    }
  };
  const handleInput = (e, index) => {
    const { name, value, checked } = e.target;
    let totalQty = orderData[index]["qty"];
    if (value < 0) {
      alert("value should be equal or greator than 0");
      return;
    }
    if (totalQty < value) {
      alert("value should be equal or less than Total Quantity");
      return;
    }

    let newOrderData = [...orderData];
    newOrderData[index]["returnQty"] = value;
    newOrderData[index]["returnValue"] = value * newOrderData[index]["value"];
    let total = 0;
    newOrderData.map((item) => {
      total += parseFloat(item.returnValue);
    });
    setTotalReturnValue(total.toFixed(2));
    setOrderData(newOrderData);
  };
  const handleSubmit = () => {
    let finalData = orderData.filter((item) => item.returnQty > 0);
    if (finalData && finalData.length < 1) {
      console.log("no data");
      toast.error(
        "Select Atleast one item to return.All items contains 0 return quantity ",
        {
          autoClose: true,
        }
      );
      return;
    }
    // return;
    let formData = {
      orderId: order.length > 0 ? order[0].OrderId : 0,
      customerId: order.length > 0 ? order[0].CustomerId : 0,
      RMA: "",
      User: order[0].User,
      items: finalData,
      totalValue: totalReturnValue,
    };

    insertReturn(formData, auth)
      .then((response) => {
        console.log("response", response);
        let msg = "Return Saved Successfully";
        toast.success(msg, {
          autoClose: true,
        });
        setOrderData([]);
        setTotalReturnValue(0);
        let orderReturnId = response.orderReturnId;
        history.push("/ret/success/" + orderReturnId);
      })
      .catch((e) => {
        toast.error("Unable to save Data, Try again", {
          autoClose: true,
        });
        // setOnline(false);
      });
  };

  return !orders || orders.length === 0 ? (
    <Redirect to="/returns" />
  ) : styles && styles.length === 0 ? (
    <Spinner />
  ) : (
    <div className="content order">
      <h1>
        {order && order.length > 0 && order[0].CustomerId
          ? "ORDER FOR " + order[0].CustomerId
          : null}{" "}
        <span>
          {order && order.length > 0 && order[0].Amended ? "(Amended)" : null}
        </span>
      </h1>
      {getStatus(order)}

      <CustomerDetails
        customer={getCustomer(order)}
        reOpenCustomerForm={false}
        customerFormOpen={false}
        confirm={"null"}
      />
      <div className="confirm-styles">
        <h3 className="pre-btn">Items</h3>
        {orderData && orderData.length > 0 ? (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>Sku</th>
                  <th>Style</th>
                  <th>Colour</th>
                  <th>Size</th>
                  <th>Qty</th>
                  <th>Value</th>
                  <th>Delivery Date</th>
                  <th>Return Qty</th>
                  <th>Return Value</th>
                  <th>Returned Qty</th>
                </tr>
              </thead>
              <tbody>
                {orderData.map((item, i) => {
                  console.log("item", item.currency);
                  return (
                    <tr key={"orderData" + i}>
                      <td>
                        {item.qty == 0 ? (
                          <span className="custom_badge"></span>
                        ) : null}
                        {item.sku}
                      </td>
                      <td>{item.style}</td>
                      <td>{item.colour}</td>
                      <td>
                        {item.currency == "$"
                          ? getUSSize(item.size)
                          : item.size}
                      </td>
                      <td>{item.qty}</td>
                      <td>
                        {item.currency}
                        {item.value}
                      </td>
                      <td>{item.deliveryDate}</td>
                      <td>
                        <input
                          onChange={(e) => handleInput(e, i)}
                          value={item.returnQty}
                          type="number"
                        />
                      </td>
                      <td>
                        {item.currency}
                        {item.returnValue}
                      </td>
                      <td>{item.alreadyReturnedQty}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={20}>
                  <span className="totalVal" style={{ float: "right" }}>
                    <b>Total Return Value :</b> {orderData[0].currency}
                    {totalReturnValue}
                  </span>
                </Col>
                <Col xs={4}>
                  {alreadyReturned.length &&
                  alreadyReturned[0].IsApproved == 0 ? (
                    <p>pending...</p>
                  ) : (
                    <button onClick={handleSubmit} className="btn btn-primary">
                      Submit Return
                    </button>
                  )}
                </Col>
              </Row>
            </Grid>
          </>
        ) : null}
      </div>
    </div>
  );
}

function getOrderBySlug(orders, slug) {
  let order =
    orders.filter((order) => {
      console.log("order asdasd", parseInt(order.Id));
      return parseInt(order.Id) === parseInt(slug);
    }) || null;

  return order;
}

function getOrderAmendsBySlug(orders, slug) {
  let amendedOrders =
    orders.filter((order) => {
      return parseInt(order.OriginalId) === parseInt(slug);
    }) || null;

  return amendedOrders;
}

function getMostRecentOrderAmendBySlug(orders, slug) {
  let amendedOrders =
    orders.filter((order) => {
      return parseInt(order.OriginalId) === parseInt(slug);
    }) || null;

  let amendedOrder =
    amendedOrders.filter((order) => {
      return (
        parseInt(order.Id) ===
        parseInt(amendedOrders[amendedOrders.length - 1].Id)
      );
    }) || null;

  return amendedOrder;
}

function mapStateToProps(state, ownProps) {
  const slug = ownProps.match.params.orderId;
  const order =
    slug && state.orders.length > 0
      ? getOrderBySlug(state.orders, slug)
      : false;
  console.log("order", order);
  console.log("slug", slug);
  console.log("state.orderAmends", state.orderAmends);

  const amendedOrder =
    slug && state.orderAmends && state.orderAmends.length > 0
      ? getOrderAmendsBySlug(state.orderAmends, slug)
      : false;

  const latestAmendedOrder =
    slug && state.orderAmends && state.orderAmends.length > 0
      ? getMostRecentOrderAmendBySlug(state.orderAmends, slug)
      : false;
  console.log("state.styles", state.styles);

  return {
    order,
    amendedOrder,
    latestAmendedOrder,
    orders: state.orders,
    styles: state.styles,
    auth: state.auth,
  };
}

const mapDispatchToProps = {
  loadStyles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
