import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function billingAddressSaveInState(response) {
  return { type: types.BILLING_ADDRESS_SAVE_IN_STATE, response };
}

export function billingAddressSaveInStateSuccess() {
  return { type: types.BILLING_ADDRESS_SAVE_IN_STATE_SUCCESS };
}

export function billingAddressSaveInitial(response) {
  return { type: types.BILLING_ADDRESS_SAVE_INITIAL, response };
}

export function billingAddressSaveInitialSuccess() {
  return { type: types.BILLING_ADDRESS_SAVE_INITIAL_SUCCESS };
}

export function billingAddressDelete(response) {
  return { type: types.BILLING_ADDRESS_DELETE, response };
}

export function billingAddressDeleteSuccess() {
  return { type: types.BILLING_ADDRESS_DELETE };
}

export function loadbillingAddressesSuccess() {
  return { type: types.LOAD_BILLING_ADDRESSES_SUCCESS };
}

export function loadBillingAddresses() {
  return { type: types.LOAD_BILLING_ADDRESSES };
}

export function saveBillingAddress(address) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(billingAddressSaveInState(address));
      dispatch(billingAddressSaveInStateSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}
export function setInitialBillingAddress(address) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(billingAddressSaveInitial(address));
      dispatch(billingAddressSaveInitialSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}

export function editBillingAddress(address) {
  return { type: types.ADDRESS_EDIT, address };
}

export function deleteBillingAddress(address) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(billingAddressDelete(address));
      dispatch(billingAddressDeleteSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}
