import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function shippingAddressSaveInState(response) {
  return { type: types.SHIPPING_ADDRESS_SAVE_IN_STATE, response };
}

export function shippingAddressSaveInStateSuccess() {
  return { type: types.SHIPPING_ADDRESS_SAVE_IN_STATE_SUCCESS };
}

export function shippingAddressSaveInitial(response) {
  return { type: types.SHIPPING_ADDRESS_SAVE_INITIAL, response };
}

export function shippingAddressSaveInitialSuccess() {
  return { type: types.SHIPPING_ADDRESS_SAVE_INITIAL_SUCCESS };
}

export function shippingAddressDelete(response) {
  return { type: types.SHIPPING_ADDRESS_DELETE, response };
}

export function shippingAddressDeleteSuccess() {
  return { type: types.SHIPPING_ADDRESS_DELETE };
}

export function loadShippingAddressesSuccess() {
  return { type: types.LOAD_SHIPPING_ADDRESSES_SUCCESS };
}

export function loadShippingAddresses() {
  return { type: types.LOAD_SHIPPING_ADDRESSES };
}

export function saveShippingAddress(address) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(shippingAddressSaveInState(address));
      dispatch(shippingAddressSaveInStateSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}
export function setInitialShippingAddress(address) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(shippingAddressSaveInitial(address));
      dispatch(shippingAddressSaveInitialSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}

export function editShippingAddress(address) {
  return { type: types.ADDRESS_EDIT, address };
}

export function deleteShippingAddress(address) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(shippingAddressDelete(address));
      dispatch(shippingAddressDeleteSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}
