import React, { useEffect, useState, useQuery } from "react";
import "./Index.css";
import { connect } from "react-redux";
import {
  loadOrders,
  cancelOrder,
  confirmOrder,
  loadOrderForEdit,
} from "../../redux/actions/orderActions";
import { loadOrderAmends } from "../../redux/actions/orderAmendActions";
import List from "./List";
import { loadSeasons } from "../../redux/actions/seasonActions";
import Search from "./Search";
import Spinner from "../shared/Spinner";
import Season from "./Season";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function Index({
  loadOrders,
  loadOrderAmends,
  cancelOrder,
  loadOrderForEdit,
  confirmOrder,
  orders,
  orderAmends,
  auth,
  user,
  history,
}) {
  const [search, setSearch] = useState("");
  const [orderList, setOrderList] = useState(orders);
  const [orderAmendList, setOrderAmendList] = useState(orderAmends);
  const [apiError, setApiError] = useState(false);
  const [seasons] = useState();
  const [season, setSeason] = useState();
  const [currency, setCurrency] = useState("GBP");
  const [isDraft, setIsDraft] = useState(0);
  const { is_draft } = useParams();

  useEffect(() => {
    if (is_draft == 1) {
      setIsDraft(1);
    } else {
      setIsDraft(0);
    }
    loadOrders(auth, user, is_draft)
      .then((result) => {
        filterLatestOrders(result);
      })
      .catch((error) => {
        setApiError(true);
        console.log("Loading orders failed" + error);
      });
    if (!orderAmends || orderAmends.length === 0) {
      loadOrderAmends(auth, user)
        .then((result) => {
          setOrderAmendList(result);
        })
        .catch((error) => {
          setApiError(true);
          console.log("Loading order amends failed" + error);
        });
    }
  }, []);

  const filterLatestOrders = (orders) => {
    if (orders && orders.length > 0) {
      let filteredResults = orders.filter((order) => {
        return order.Amended === null;
      });
      setOrderList(filteredResults);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.indexOf("search") !== -1) {
      setSearch(value);
      if (!value || value === "") {
        setOrderList(orders);
      } else {
        searchOrders();
      }
    } else if (name.indexOf("season") !== -1) {
      setSeason(value);
      searchOrders(value);
    } else if (name.indexOf("currency") !== -1) {
      setCurrency(value);
      showOrdersByCurrency(value);
    }
  };

  const searchOrders = (value = null) => {
    let result;
    if (value === "" || value === null) {
      if (search === "") {
        result = orders;
      }
      result = orders.filter((order) => {
        return (
          order.CustomerId.toLowerCase().includes(search.toLowerCase()) ||
          order.Id.toLowerCase().includes(search.toLowerCase()) ||
          order.Store.toLowerCase().includes(search.toLowerCase())
        );
      });
    } else {
      result = orders.filter((order) => {
        return order.Season[1] == value || order.Season[0] == value;
      });
    }
    if (result.length > 0) {
      setOrderList(result);
    } else {
      setOrderList(["empty"]);
    }
  };

  const showOrdersByCurrency = (value = null) => {
    let result;
    if (value) {
      result = orders.filter((order) => {
        return order.Currency.toLowerCase().includes(value.toLowerCase());
      });
    }
    if (result && result.length > 0) {
      setOrderList(result);
    } else {
      setOrderList(["empty"]);
    }
  };

  const handleCancel = (id) => {
    if (id.length > 0) id = id[0];
    cancelOrder(id, auth)
      .then(
        loadOrders(auth, user, isDraft).then((result) => {
          filterLatestOrders(result);
          toast.success("Order Cancelled", { autoClose: true });
        })
      )
      .catch((error) => {
        setApiError(true);
        console.log("Loading orders failed" + error);
      });
  };

  const handleEdit = (id) => {
    let order =
      orderAmends.filter((item) => {
        return parseInt(item.OriginalId) === parseInt(id);
      }) || null;

    if (!order || order.length === 0) {
      order =
        orders.filter((item) => {
          return parseInt(item.Id) === parseInt(id);
        }) || null;
    } else {
      order =
        order.filter((item) => {
          return (
            parseInt(item.Id[0]) === parseInt(order[order.length - 1].Id[0])
          );
        }) || null;
    }

    let result = loadOrderForEdit(order, id);

    if (result) {
      toast.success("Order Loaded for Editing", { autoClose: true });
      history.push("/customer");
    }
  };

  const handleConfirm = (id) => {
    if (id.length > 0) id = id[0];
    confirmOrder(id, auth).then(
      loadOrders(auth, user, isDraft).then((result) => {
        filterLatestOrders(result);
        toast.success("Order Confirmed", { autoClose: true });
      })
    );
  };

  const showOrders = () => {
    return apiError ? (
      <>
        <div className="content orders">
          <p class="network-banner">
            Cannot load orders at this time, there is no connection to the
            server
          </p>
        </div>
      </>
    ) : (
      <>
        <div className="content orders">
          <h1>My Orders</h1>
          <p>
            Here you can edit or confirm an order. Only confirm the order once
            no more edits are requried and this will indicate that it is ready
            to be processed.
          </p>
          <Season
            season={season}
            seasons={seasons}
            onChange={handleChange}
          ></Season>
          {/* <Currency
            currency={currency}
            onChange={handleChange}
          ></Currency> */}
          <Search search={search} onChange={handleChange}></Search>
          <List
            orders={orderList}
            cancel={handleCancel}
            confirm={handleConfirm}
            edit={handleEdit}
          ></List>
        </div>
      </>
    );
  };

  return orderList && orderList.length === 0 && !apiError ? (
    <Spinner />
  ) : (
    showOrders()
  );
}

function mapStateToProps(state) {
  return {
    orders: state.orders,
    seasons: state.seasons,
    auth: state.auth,
    user: state.user,
    orderAmends: state.orderAmends,
  };
}

const mapDispatchToProps = {
  loadOrders,
  loadOrderAmends,
  loadSeasons,
  cancelOrder,
  confirmOrder,
  loadOrderForEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
