import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { login } from "../../redux/actions/authActions";
import { toast } from "react-toastify";
import LoginForm from "./LoginForm";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

export function LoginPage({ login, adminAuth, auth }) {
  const [user, setUser] = useState({ username: null, password: null });
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [redirect, setRedirect] = useState(false);
  let history = useHistory();

  useEffect(() => {
    console.log("adminauth", adminAuth);
  }, []);
  const handleLogin = (event) => {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    login(user.username, user.password)
      .then(() => {
        toast.success("You have been logged in.", { autoClose: true });
        setRedirect(true);
      })
      .catch((error) => {
        setSaving(false);
        setRedirect(false);
        setErrors({ onSave: error.message });
      });
  };
  useEffect(() => {
    console.log("auth", auth);
    if (auth) {
      history.push("/");
    }
  }, []);
  function formIsValid() {
    const { username, password } = user;
    const errors = {};

    if (!username) errors.title = "Username is required.";
    if (!password) errors.author = "Password is required";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  }

  return redirect ? (
    <Redirect to="/" />
  ) : !adminAuth ? (
    <LoginForm
      user={user}
      errors={errors}
      onChange={handleChange}
      onSave={handleLogin}
      saving={saving}
    />
  ) : (
    <Redirect to="/admin" />
  );
}

function mapStateToProps(state) {
  return {
    loading: state.apiCallsInProgress > 0,
    adminAuth: state.adminAuth,
    auth: state.auth,
  };
}

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
