import React from "react";
import SelectInput from "../shared/SelectInput";

export default function ({ size, onChange }) {
  return (
    <div className="warehouse">
      <SelectInput
        name="size"
        label="Size"
        onChange={onChange}
        defaultOption={size}
        value={size}
        options={[
          {
            value: "3",
            text: "UK 3",
          },
          {
            value: "4",
            text: "UK 4",
          },
          {
            value: "4.5",
            text: "UK 4.5",
          },
          {
            value: "5",
            text: "UK 5",
          },
          {
            value: "5.5",
            text: "UK 5.5",
          },
          {
            value: "6",
            text: "UK 6",
          },
          {
            value: "6.5",
            text: "UK 6.5",
          },
          {
            value: "7",
            text: "UK 7",
          },
          {
            value: "8",
            text: "UK 8",
          },
          {
            value: "9",
            text: "UK 9",
          },
          {
            value: "10",
            text: "UK 10",
          },
          {
            value: "11",
            text: "UK 11",
          },
        ]}
      />
    </div>
  );
}
