import React from "react";
import { Link } from "react-router-dom";
import "./Version.css";

function getDate(date)
{
  if(date){
    return date.split("T")[0];
  }
  return null;
}

function getLink(order,isAmend)
{
  return !isAmend ? (
    <Link to={"/order/" + order}> View </Link>
  ) : null
}

function Version({ order, currency, orderAmends }) 
{
  return  orderAmends ? (
    <>
      <h2>Versions</h2>
      <div className="version-container">
        <table>
          <tr>
            <th>
              Type
            </th>
            <th>
              Date
            </th>
            <th>
              Pairs
            </th>
            <th>
              Total
            </th>
            <th>
            </th>
          </tr>
          <tr>
            <td>
              Original
            </td>
            <td>
              {getDate(order[0].DateCreated)}
            </td>
            <td>
              {order[0].Qty[0]}
            </td>
            <td>
              {currency + order[0].Price[0]}
            </td>
            <td class="action-btn">
              {getLink(order[0].Id[0],"amend")}
            </td>
          </tr>
          {
             orderAmends.map((item, i) => {
              return i > 0 && i < 2  ? (
                <tr>
                  <td>Amendment</td>
                  <td>{getDate(item.DateCreated[0])}</td>
                  <td>{item.Qty[0]}</td>
                  <td>{currency + item.Price[0]}</td>
                  <td class="action-btn">
                    {getLink(item.Id[0],"amend")}
                  </td>
                </tr>
              ) : (
                i > 1 && item.Id[0] != orderAmends[i - 1].Id[0] ? (
                <tr>
                  <td>Amendment</td>
                  <td>{getDate(item.DateCreated[0])}</td>
                  <td>{item.Qty[0]}</td>
                  <td>{currency + item.Price[0]}</td>
                  <td class="action-btn">
                    {getLink(item.Id[0],"amend")}
                  </td>
                </tr>
                ) : null
              )
            })
          }
        </table>
      </div>
    </>
  ) : null ;
}


export default Version;