import { handleResponse } from "./apiUtils";
const baseUrl = process.env.REACT_APP_AUTH_URL + "/network";

export function getNetwork() {
  return fetch(baseUrl)
    .then(handleResponse)
    .catch((e) => {
      throw e;
    });
}
