import * as types from "./actionTypes";
import { apiCallError, beginApiCall } from "./apiStatusActions";
import * as orderApi from "../../api/orderApi";

export function loadOrderSuccess(orders) {
  return { type: types.LOAD_ORDERS_AMEND_SUCCESS, orders };
}

export function loadOrderAmends(auth, user) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return orderApi
      .getOrdersAmends(auth, user)
      .then((orders) => {
        dispatch(loadOrderSuccess(orders));
        return orders;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
