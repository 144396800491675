import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function addressSaveInState(response) {
  return { type: types.ADDRESS_SAVE_IN_STATE, response };
}

export function addressSaveInStateSuccess() {
  return { type: types.ADDRESS_SAVE_IN_STATE_SUCCESS };
}

export function addressSaveInitial(response) {
  return { type: types.ADDRESS_SAVE_INITIAL, response };
}

export function addressSaveInitialSuccess() {
  return { type: types.ADDRESS_SAVE_INITIAL_SUCCESS };
}

export function addressDelete(response) {
  return { type: types.ADDRESS_DELETE, response };
}

export function addressDeleteSuccess() {
  return { type: types.ADDRESS_DELETE };
}

export function loadAddressesSuccess() {
  return { type: types.LOAD_ADDRESSES_SUCCESS };
}

export function loadAddresses() {
  return { type: types.LOAD_ADDRESSES };
}

export function saveAddress(address) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(addressSaveInState(address));
      dispatch(addressSaveInStateSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}

export function setInitialAddress(address) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(addressSaveInitial(address));
      dispatch(addressSaveInitialSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}

export function editAddress(address) {
  return { type: types.ADDRESS_EDIT, address };
}

export function deleteAddress(address) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(addressDelete(address));
      dispatch(addressDeleteSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}
