import React, { useState } from "react";
import "./Items.css";

export default function ({
  cart,
  currency,
  addresses,
  getStyle,
  removeItem,
  editItem,
  getCartForAddress,
}) {
  const [showDetail, setShowDetail] = useState(false);

  function setDisplayDetail() {
    showDetail ? setShowDetail(false) : setShowDetail(true);
  }

  function hasAddress2(address) {
    if (address.address2) {
      return address.address2 + ",";
    }
  }

  function displaySizeHeaders(type) {
    if (showDetail) {
      if (type === "shoe") {
        return (
          <>
            <th className="expandable">{currency !== "$" ? 2 : "35"}</th>
            <th className="expandable">{currency !== "$" ? 3 : "36"}</th>
            <th className="expandable">{currency !== "$" ? 4 : "37"}</th>
            <th className="expandable">{currency !== "$" ? 4.5 : "37.5"}</th>
            <th className="expandable">{currency !== "$" ? 5 : "38"}</th>
            <th className="expandable">{currency !== "$" ? 5.5 : "38.5"}</th>
            <th className="expandable">{currency !== "$" ? 6 : "39"}</th>
            <th className="expandable">{currency !== "$" ? 6.5 : "40"}</th>
            <th className="expandable">{currency !== "$" ? 7 : "41"}</th>
            <th className="expandable">{currency !== "$" ? 8 : "42"}</th>
            {/* <th className="expandable">{currency !== "$" ? 9 : "11"}</th>
            <th className="expandable">{currency !== "$" ? 10 : "12"}</th>
            <th className="expandable">{currency !== "$" ? 11 : "13"}</th> */}
          </>
        );
      } else if (type === "mensshoe") {
        return (
          <>
            {/* <th className="expandable">{currency !== "$" ? 3 : "5"}</th>
            <th className="expandable">{currency !== "$" ? 4 : "6"}</th>
            <th className="expandable">{currency !== "$" ? 4.5 : "6.5"}</th>
            <th className="expandable">{currency !== "$" ? 5 : "7"}</th>
            <th className="expandable">{currency !== "$" ? 5.5 : "7.5"}</th> */}
            <th className="expandable">{currency !== "$" ? 6 : "39"}</th>
            <th className="expandable">{currency !== "$" ? 6.5 : "40"}</th>
            <th className="expandable">{currency !== "$" ? 7 : "41"}</th>
            <th className="expandable">{currency !== "$" ? 8 : "42"}</th>
            <th className="expandable">{currency !== "$" ? 9 : "43"}</th>
            <th className="expandable">{currency !== "$" ? 10 : "44"}</th>
            <th className="expandable">{currency !== "$" ? 11 : "45"}</th>
          </>
        );
      } else if (type === "sandal") {
        return (
          <>
            {/* <th className="expandable">{currency !== "$" ? 3 : "5.5-6"}</th>
            <th className="expandable">{currency !== "$" ? 4 : "6.5-7"}</th>
            <th className="expandable">{currency !== "$" ? 5 : "7.5-8"}</th>
            <th className="expandable">{currency !== "$" ? 6 : "8.5-9"}</th>
            <th className="expandable">{currency !== "$" ? 7 : "9.5-10"}</th>
            <th className="expandable">{currency !== "$" ? 8 : "10.5-11"}</th>
            <th className="expandable">{currency !== "$" ? 9 : "11.5-12"}</th> */}
            <th className="expandable">{currency !== "$" ? 2 : "35"}</th>
            <th className="expandable">{currency !== "$" ? 3 : "36"}</th>
            <th className="expandable">{currency !== "$" ? 4 : "37"}</th>
            <th className="expandable">{currency !== "$" ? 4.5 : "37.5"}</th>
            <th className="expandable">{currency !== "$" ? 5 : "38"}</th>
            <th className="expandable">{currency !== "$" ? 5.5 : "38.5"}</th>
            <th className="expandable">{currency !== "$" ? 6 : "39"}</th>
            <th className="expandable">{currency !== "$" ? 6.5 : "40"}</th>
            <th className="expandable">{currency !== "$" ? 7 : "41"}</th>
            <th className="expandable">{currency !== "$" ? 8 : "42"}</th>
            <th className="expandable">{currency !== "$" ? 9 : "43"}</th>
          </>
        );
      } else if (type === "elevatedsandal") {
        return (
          <>
            {/* <th className="expandable">{currency !== "$" ? 3 : "5.5-6"}</th>
            <th className="expandable">{currency !== "$" ? 4 : "6.5-7"}</th>
            <th className="expandable">{currency !== "$" ? 5 : "7.5-8"}</th>
            <th className="expandable">{currency !== "$" ? 6 : "8.5-9"}</th>
            <th className="expandable">{currency !== "$" ? 7 : "9.5-10"}</th>
            <th className="expandable">{currency !== "$" ? 8 : "10.5-11"}</th>
            <th className="expandable">{currency !== "$" ? 9 : "11.5-12"}</th> */}
            <th className="expandable">{currency !== "$" ? 2 : "35"}</th>
            <th className="expandable">{currency !== "$" ? 3 : "36"}</th>
            <th className="expandable">{currency !== "$" ? 4 : "37"}</th>
            <th className="expandable">{currency !== "$" ? 5 : "38"}</th>
            <th className="expandable">{currency !== "$" ? 6 : "39"}</th>
            <th className="expandable">{currency !== "$" ? 6.5 : "40"}</th>
            <th className="expandable">{currency !== "$" ? 7 : "41"}</th>
            <th className="expandable">{currency !== "$" ? 8 : "42"}</th>
          </>
        );
      } else if (type === "slipper") {
        return (
          <>
            {/* <th className="expandable">{currency !== "$" ? 3 : "5.5-6"}</th>
            <th className="expandable">{currency !== "$" ? 4 : "6.5-7"}</th>
            <th className="expandable">{currency !== "$" ? 5 : "7.5-8"}</th>
            <th className="expandable">{currency !== "$" ? 6 : "8.5-9"}</th>
            <th className="expandable">{currency !== "$" ? 7 : "9.5-10"}</th>
            <th className="expandable">{currency !== "$" ? 8 : "10.5-11"}</th>
            <th className="expandable">{currency !== "$" ? 9 : "11.5-12"}</th>
            <th className="expandable">{currency !== "$" ? 10 : "12.5-13"}</th>
            <th className="expandable">{currency !== "$" ? 11 : "13.5-14"}</th> */}
            <th className="expandable">{currency !== "$" ? 2 : "35"}</th>
            <th className="expandable">{currency !== "$" ? 3 : "36"}</th>
            <th className="expandable">{currency !== "$" ? 4 : "37"}</th>
            <th className="expandable">{currency !== "$" ? 5 : "38"}</th>
            <th className="expandable">{currency !== "$" ? 6 : "39"}</th>
            <th className="expandable">{currency !== "$" ? 6.5 : "40"}</th>
            <th className="expandable">{currency !== "$" ? 7 : "41"}</th>
            <th className="expandable">{currency !== "$" ? 8 : "42"}</th>
            <th className="expandable">{currency !== "$" ? 9 : "43"}</th>
          </>
        );
      } else if (type === "mensslipper") {
        return (
          <>
            {/* <th className="expandable">{currency !== "$" ? 3 : "5.5-6"}</th> */}
            {/* <th className="expandable">{currency !== "$" ? 4 : "6.5-7"}</th> */}
            {/* <th className="expandable">{currency !== "$" ? 5 : "7.5-8"}</th> */}
            <th className="expandable">{currency !== "$" ? 6 : "39"}</th>
            <th className="expandable">{currency !== "$" ? 6.5 : "40"}</th>
            <th className="expandable">{currency !== "$" ? 7 : "41"}</th>
            <th className="expandable">{currency !== "$" ? 8 : "42"}</th>
            <th className="expandable">{currency !== "$" ? 9 : "43"}</th>
            <th className="expandable">{currency !== "$" ? 10 : "44"}</th>
            <th className="expandable">{currency !== "$" ? 11 : "45"}</th>
          </>
        );
      } else if (type === "pos") {
        return (
          <>
            <th className="expandable">Qty</th>
          </>
        );
      } else if (type === "insole") {
        return (
          <>
            <th className="expandable">{currency !== "$" ? "3-4" : "5.5-7"}</th>
            <th className="expandable">{currency !== "$" ? "5-6" : "7.5-9"}</th>
            <th className="expandable">
              {currency !== "$" ? "7-8" : "9.5-11"}
            </th>
            <th className="expandable">
              {currency !== "$" ? "9-10" : "11.5-13"}
            </th>
            <th className="expandable">
              {currency !== "$" ? "11-12" : "13.5-15"}
            </th>
          </>
        );
      }
    } else {
      return (
        <>
          {type !== "insole" ? <th>Colour</th> : null}
          <th>Date</th>
          {type == "insole" ? <th>Season</th> : null}
          <th>Pairs</th>
          <th>Value</th>
        </>
      );
    }
  }

  function displayEU(type) {
    if (showDetail) {
      if (type === "shoe") {
        return (
          <>
            <th className="expandable">{"35"}</th>
            <th className="expandable">{"36"}</th>
            <th className="expandable">{"37"}</th>
            <th className="expandable">{"37.5"}</th>
            <th className="expandable">{"38"}</th>
            <th className="expandable">{"38.5"}</th>
            <th className="expandable">{"39"}</th>
            <th className="expandable">{"40"}</th>
            <th className="expandable">{"41"}</th>
            <th className="expandable">{"42"}</th>
            <th className="expandable">{"43"}</th>
            <th className="expandable">{"44"}</th>
            <th className="expandable">{"45"}</th>
          </>
        );
      } else if (type === "mensshoe") {
        return (
          <>
            {/* <th className="expandable">{"36"}</th>
            <th className="expandable">{"37"}</th>
            <th className="expandable">{"37.5"}</th>
            <th className="expandable">{"38"}</th>
            <th className="expandable">{"38.5"}</th> */}
            <th className="expandable">{"39"}</th>
            <th className="expandable">{"40"}</th>
            <th className="expandable">{"41"}</th>
            <th className="expandable">{"42"}</th>
            <th className="expandable">{"43"}</th>
            <th className="expandable">{"44"}</th>
            <th className="expandable">{"45"}</th>
          </>
        );
      } else if (type === "sandal") {
        return (
          <>
            <th className="expandable">{"35"}</th>
            <th className="expandable">{"36"}</th>
            <th className="expandable">{"37"}</th>
            <th className="expandable">{"38"}</th>
            <th className="expandable">{"39"}</th>
            <th className="expandable">{"40"}</th>
            <th className="expandable">{"41"}</th>
            <th className="expandable">{"42"}</th>
            <th className="expandable">{"43"}</th>
          </>
        );
      } else if (type === "elevatedsandal") {
        return (
          <>
            <th className="expandable">{"35"}</th>
            <th className="expandable">{"36"}</th>
            <th className="expandable">{"37"}</th>
            <th className="expandable">{"38"}</th>
            <th className="expandable">{"39"}</th>
            <th className="expandable">{"40"}</th>
            <th className="expandable">{"41"}</th>
            <th className="expandable">{"42"}</th>
            {/* <th className="expandable">{"43"}</th> */}
          </>
        );
      } else if (type === "mensslipper") {
        return (
          <>
            <th className="expandable">{"39"}</th>
            <th className="expandable">{"40"}</th>
            <th className="expandable">{"41"}</th>
            <th className="expandable">{"42"}</th>
            <th className="expandable">{"43"}</th>
            <th className="expandable">{"44"}</th>
            <th className="expandable">{"45"}</th>
          </>
        );
      } else if (type === "slipper") {
        return (
          <>
            <th className="expandable">{"35"}</th>
            <th className="expandable">{"36"}</th>
            <th className="expandable">{"37"}</th>
            <th className="expandable">{"38"}</th>
            <th className="expandable">{"39"}</th>
            <th className="expandable">{"40"}</th>
            <th className="expandable">{"41"}</th>
            <th className="expandable">{"42"}</th>
            <th className="expandable">{"43"}</th>
            {/* <th className="expandable">{"44"}</th> */}
            {/* <th className="expandable">{"45"}</th> */}
          </>
        );
      } else if (type === "pos") {
        return (
          <>
            <th className="expandable">Qty</th>
          </>
        );
      }
    } else {
      return (
        <>
          {type !== "insole" ? <th>Colour</th> : null}
          <th>Date</th>
          {type == "insole" ? <th>Season</th> : null}
          <th>Pairs</th>
          <th>Value</th>
        </>
      );
    }
  }

  function displaySizeRowEu(item, type) {
    let selected = "expandable";
    let notSelected = "expandable not-selected";
    if (showDetail) {
      if (type === "shoe") {
        return (
          <>
            <td className={item.qty.UK2 != null ? selected : notSelected}>
              {item.qty.UK2 != null ? item.qty.UK2 : 0}
            </td>
            <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
            <td className={item.qty.UK4 != null ? selected : notSelected}>
              {item.qty.UK4 != null ? item.qty.UK4 : 0}
            </td>
            <td className={item.qty.UK4half != null ? selected : notSelected}>
              {item.qty.UK4half != null ? item.qty.UK4half : 0}
            </td>
            <td className={item.qty.UK5 != null ? selected : notSelected}>
              {item.qty.UK5 != null ? item.qty.UK5 : 0}
            </td>
            <td className={item.qty.UK5half != null ? selected : notSelected}>
              {item.qty.UK5half != null ? item.qty.UK5half : 0}
            </td>
            <td className={item.qty.UK6 != null ? selected : notSelected}>
              {item.qty.UK6 != null ? item.qty.UK6 : 0}
            </td>
            <td className={item.qty.UK6half != null ? selected : notSelected}>
              {item.qty.UK6half != null ? item.qty.UK6half : 0}
            </td>
            <td className={item.qty.UK7 != null ? selected : notSelected}>
              {item.qty.UK7 != null ? item.qty.UK7 : 0}
            </td>
            <td className={item.qty.UK8 != null ? selected : notSelected}>
              {item.qty.UK8 != null ? item.qty.UK8 : 0}
            </td>
            {/* <td className={item.qty.UK9 != null ? selected : notSelected}>
              {item.qty.UK9 != null ? item.qty.UK9 : 0}
            </td>
            <td className={item.qty.UK10 != null ? selected : notSelected}>
              {item.qty.UK10 != null ? item.qty.UK10 : 0}
            </td>
            <td className={item.qty.UK11 != null ? selected : notSelected}>
              {item.qty.UK11 != null ? item.qty.UK11 : 0}
            </td> */}
          </>
        );
      } else if (type === "mensshoe") {
        return (
          <>
            {/* <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
            <td className={item.qty.UK4 != null ? selected : notSelected}>
              {item.qty.UK4 != null ? item.qty.UK4 : 0}
            </td>
            <td className={item.qty.UK4half != null ? selected : notSelected}>
              {item.qty.UK4half != null ? item.qty.UK4half : 0}
            </td>
            <td className={item.qty.UK5 != null ? selected : notSelected}>
              {item.qty.UK5 != null ? item.qty.UK5 : 0}
            </td>
            <td className={item.qty.UK5half != null ? selected : notSelected}>
              {item.qty.UK5half != null ? item.qty.UK5half : 0}
            </td> */}
            <td className={item.qty.UK6 != null ? selected : notSelected}>
              {item.qty.UK6 != null ? item.qty.UK6 : 0}
            </td>
            <td className={item.qty.UK6half != null ? selected : notSelected}>
              {item.qty.UK6half != null ? item.qty.UK6half : 0}
            </td>
            <td className={item.qty.UK7 != null ? selected : notSelected}>
              {item.qty.UK7 != null ? item.qty.UK7 : 0}
            </td>
            <td className={item.qty.UK8 != null ? selected : notSelected}>
              {item.qty.UK8 != null ? item.qty.UK8 : 0}
            </td>
            <td className={item.qty.UK9 != null ? selected : notSelected}>
              {item.qty.UK9 != null ? item.qty.UK9 : 0}
            </td>
            <td className={item.qty.UK10 != null ? selected : notSelected}>
              {item.qty.UK10 != null ? item.qty.UK10 : 0}
            </td>
            <td className={item.qty.UK11 != null ? selected : notSelected}>
              {item.qty.UK11 != null ? item.qty.UK11 : 0}
            </td>
          </>
        );
      } else if (type === "sandal" || type === "elevatedsandal") {
        return (
          <>
            <td className={item.qty.UK2 != null ? selected : notSelected}>
              {item.qty.UK2 != null ? item.qty.UK2 : 0}
            </td>
            <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
            <td className={item.qty.UK4 != null ? selected : notSelected}>
              {item.qty.UK4 != null ? item.qty.UK4 : 0}
            </td>
            <td className={item.qty.UK5 != null ? selected : notSelected}>
              {item.qty.UK5 != null ? item.qty.UK5 : 0}
            </td>
            <td className={item.qty.UK6 != null ? selected : notSelected}>
              {item.qty.UK6 != null ? item.qty.UK6 : 0}
            </td>
            <td className={item.qty.UK6half != null ? selected : notSelected}>
              {item.qty.UK6half != null ? item.qty.UK6half : 0}
            </td>
            <td className={item.qty.UK7 != null ? selected : notSelected}>
              {item.qty.UK7 != null ? item.qty.UK7 : 0}
            </td>
            <td className={item.qty.UK8 != null ? selected : notSelected}>
              {item.qty.UK8 != null ? item.qty.UK8 : 0}
            </td>
            <td className={item.qty.UK9 != null ? selected : notSelected}>
              {item.qty.UK9 != null ? item.qty.UK9 : 0}
            </td>
            {/* <td className={item.qty.UK10 != null ? selected : notSelected}>
              {item.qty.UK10 != null ? item.qty.UK10 : 0}
            </td> */}
          </>
        );
      } else if (type === "slipper") {
        return (
          <>
            <td className={item.qty.UK2 != null ? selected : notSelected}>
              {item.qty.UK2 != null ? item.qty.UK2 : 0}
            </td>
            <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
            <td className={item.qty.UK4 != null ? selected : notSelected}>
              {item.qty.UK4 != null ? item.qty.UK4 : 0}
            </td>
            <td className={item.qty.UK5 != null ? selected : notSelected}>
              {item.qty.UK5 != null ? item.qty.UK5 : 0}
            </td>
            <td className={item.qty.UK6 != null ? selected : notSelected}>
              {item.qty.UK6 != null ? item.qty.UK6 : 0}
            </td>
            <td className={item.qty.UK6half != null ? selected : notSelected}>
              {item.qty.UK6half != null ? item.qty.UK6half : 0}
            </td>
            <td className={item.qty.UK7 != null ? selected : notSelected}>
              {item.qty.UK7 != null ? item.qty.UK7 : 0}
            </td>
            <td className={item.qty.UK8 != null ? selected : notSelected}>
              {item.qty.UK8 != null ? item.qty.UK8 : 0}
            </td>
            <td className={item.qty.UK9 != null ? selected : notSelected}>
              {item.qty.UK9 != null ? item.qty.UK9 : 0}
            </td>
            {/* <td className={item.qty.UK10 != null ? selected : notSelected}>
              {item.qty.UK10 != null ? item.qty.UK10 : 0}
            </td>
            <td className={item.qty.UK11 != null ? selected : notSelected}>
              {item.qty.UK11 != null ? item.qty.UK11 : 0}
            </td> */}
          </>
        );
      } else if (type === "mensslipper") {
        return (
          <>
            {/* <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
            <td className={item.qty.UK4 != null ? selected : notSelected}>
              {item.qty.UK4 != null ? item.qty.UK4 : 0}
            </td>
            <td className={item.qty.UK5 != null ? selected : notSelected}>
              {item.qty.UK5 != null ? item.qty.UK5 : 0}
            </td> */}
            <td className={item.qty.UK6 != null ? selected : notSelected}>
              {item.qty.UK6 != null ? item.qty.UK6 : 0}
            </td>
            <td className={item.qty.UK6half != null ? selected : notSelected}>
              {item.qty.UK6half != null ? item.qty.UK6half : 0}
            </td>
            <td className={item.qty.UK7 != null ? selected : notSelected}>
              {item.qty.UK7 != null ? item.qty.UK7 : 0}
            </td>
            <td className={item.qty.UK8 != null ? selected : notSelected}>
              {item.qty.UK8 != null ? item.qty.UK8 : 0}
            </td>
            <td className={item.qty.UK9 != null ? selected : notSelected}>
              {item.qty.UK9 != null ? item.qty.UK9 : 0}
            </td>
            <td className={item.qty.UK10 != null ? selected : notSelected}>
              {item.qty.UK10 != null ? item.qty.UK10 : 0}
            </td>
            <td className={item.qty.UK11 != null ? selected : notSelected}>
              {item.qty.UK11 != null ? item.qty.UK11 : 0}
            </td>
          </>
        );
      } else if (type === "insole") {
        return (
          <>
            <td className={item.qty.UK34 != null ? selected : notSelected}>
              {item.qty.UK34 != null ? item.qty.UK34 : 0}
            </td>
            <td className={item.qty.UK56 != null ? selected : notSelected}>
              {item.qty.UK56 != null ? item.qty.UK56 : 0}
            </td>
            <td className={item.qty.UK78 != null ? selected : notSelected}>
              {item.qty.UK78 != null ? item.qty.UK78 : 0}
            </td>
            <td className={item.qty.UK910 != null ? selected : notSelected}>
              {item.qty.UK910 != null ? item.qty.UK910 : 0}
            </td>
            <td className={item.qty.UK1112 != null ? selected : notSelected}>
              {item.qty.UK1112 != null ? item.qty.UK1112 : 0}
            </td>
          </>
        );
      } else if (type === "pos") {
        return (
          <>
            <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
          </>
        );
      }
    } else {
      return (
        <>
          {type !== "insole" ? <td>{item.colour}</td> : null}
          <td>{item.date}</td>
          {type == "insole" ? <td>{item.deliverySeason}</td> : null}
          <td>{item.total}</td>
          <td>{currency + item.price}</td>
        </>
      );
    }
  }

  function displaySizeRow(item, type) {
    let selected = "expandable";
    let notSelected = "expandable not-selected";
    if (showDetail) {
      if (type === "shoe") {
        return (
          <>
            <td className={item.qty.UK2 != null ? selected : notSelected}>
              {item.qty.UK2 != null ? item.qty.UK2 : 0}
            </td>
            <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
            <td className={item.qty.UK4 != null ? selected : notSelected}>
              {item.qty.UK4 != null ? item.qty.UK4 : 0}
            </td>
            <td className={item.qty.UK4half != null ? selected : notSelected}>
              {item.qty.UK4half != null ? item.qty.UK4half : 0}
            </td>
            <td className={item.qty.UK5 != null ? selected : notSelected}>
              {item.qty.UK5 != null ? item.qty.UK5 : 0}
            </td>
            <td className={item.qty.UK5half != null ? selected : notSelected}>
              {item.qty.UK5half != null ? item.qty.UK5half : 0}
            </td>
            <td className={item.qty.UK6 != null ? selected : notSelected}>
              {item.qty.UK6 != null ? item.qty.UK6 : 0}
            </td>
            <td className={item.qty.UK6half != null ? selected : notSelected}>
              {item.qty.UK6half != null ? item.qty.UK6half : 0}
            </td>
            <td className={item.qty.UK7 != null ? selected : notSelected}>
              {item.qty.UK7 != null ? item.qty.UK7 : 0}
            </td>
            <td className={item.qty.UK8 != null ? selected : notSelected}>
              {item.qty.UK8 != null ? item.qty.UK8 : 0}
            </td>
            {/* <td className={item.qty.UK9 != null ? selected : notSelected}>
              {item.qty.UK9 != null ? item.qty.UK9 : 0}
            </td>
            <td className={item.qty.UK10 != null ? selected : notSelected}>
              {item.qty.UK10 != null ? item.qty.UK10 : 0}
            </td>
            <td className={item.qty.UK11 != null ? selected : notSelected}>
              {item.qty.UK11 != null ? item.qty.UK11 : 0}
            </td> */}
          </>
        );
      } else if (type === "mensshoe") {
        return (
          <>
            {/* <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
            <td className={item.qty.UK4 != null ? selected : notSelected}>
              {item.qty.UK4 != null ? item.qty.UK4 : 0}
            </td>
            <td className={item.qty.UK4half != null ? selected : notSelected}>
              {item.qty.UK4half != null ? item.qty.UK4half : 0}
            </td>
            <td className={item.qty.UK5 != null ? selected : notSelected}>
              {item.qty.UK5 != null ? item.qty.UK5 : 0}
            </td>
            <td className={item.qty.UK5half != null ? selected : notSelected}>
              {item.qty.UK5half != null ? item.qty.UK5half : 0}
            </td> */}
            <td className={item.qty.UK6 != null ? selected : notSelected}>
              {item.qty.UK6 != null ? item.qty.UK6 : 0}
            </td>
            <td className={item.qty.UK6half != null ? selected : notSelected}>
              {item.qty.UK6half != null ? item.qty.UK6half : 0}
            </td>
            <td className={item.qty.UK7 != null ? selected : notSelected}>
              {item.qty.UK7 != null ? item.qty.UK7 : 0}
            </td>
            <td className={item.qty.UK8 != null ? selected : notSelected}>
              {item.qty.UK8 != null ? item.qty.UK8 : 0}
            </td>
            <td className={item.qty.UK9 != null ? selected : notSelected}>
              {item.qty.UK9 != null ? item.qty.UK9 : 0}
            </td>
            <td className={item.qty.UK10 != null ? selected : notSelected}>
              {item.qty.UK10 != null ? item.qty.UK10 : 0}
            </td>
            <td className={item.qty.UK11 != null ? selected : notSelected}>
              {item.qty.UK11 != null ? item.qty.UK11 : 0}
            </td>
          </>
        );
      } else if (type === "sandal" || type === "elevatedsandal") {
        console.log("latest sizes", item);
        if (currency === "$") {
          return (
            <>
              <td className={item.qty.UK2 != null ? selected : notSelected}>
                {item.qty.UK2 != null ? item.qty.UK2 : 0}
              </td>
              <td className={item.qty.UK3 != null ? selected : notSelected}>
                {item.qty.UK3 != null ? item.qty.UK3 : 0}
              </td>
              <td className={item.qty.UK4 != null ? selected : notSelected}>
                {item.qty.UK4 != null ? item.qty.UK4 : 0}
              </td>
              <td className={item.qty.UK4 != null ? selected : notSelected}>
                {item.qty.UK4half != null ? item.qty.UK4half : 0}
              </td>
              <td className={item.qty.UK5 != null ? selected : notSelected}>
                {item.qty.UK5 != null ? item.qty.UK5 : 0}
              </td>
              <td className={item.qty.UK5 != null ? selected : notSelected}>
                {item.qty.UK5half != null ? item.qty.UK5half : 0}
              </td>
              <td className={item.qty.UK6 != null ? selected : notSelected}>
                {item.qty.UK6 != null ? item.qty.UK6 : 0}
              </td>
              <td className={item.qty.UK6 != null ? selected : notSelected}>
                {item.qty.UK6half != null ? item.qty.UK6half : 0}
              </td>
              <td className={item.qty.UK7 != null ? selected : notSelected}>
                {item.qty.UK7 != null ? item.qty.UK7 : 0}
              </td>
              <td className={item.qty.UK8 != null ? selected : notSelected}>
                {item.qty.UK8 != null ? item.qty.UK8 : 0}
              </td>
              <td className={item.qty.UK9 != null ? selected : notSelected}>
                {item.qty.UK9 != null ? item.qty.UK9 : 0}
              </td>
            </>
          );
        } else {
          return (
            <>
              <td className={item.qty.UK2 != null ? selected : notSelected}>
                {item.qty.UK2 != null ? item.qty.UK2 : 0}
              </td>
              <td className={item.qty.UK3 != null ? selected : notSelected}>
                {item.qty.UK3 != null ? item.qty.UK3 : 0}
              </td>
              <td className={item.qty.UK4 != null ? selected : notSelected}>
                {item.qty.UK4 != null ? item.qty.UK4 : 0}
              </td>
              <td className={item.qty.UK5 != null ? selected : notSelected}>
                {item.qty.UK5 != null ? item.qty.UK5 : 0}
              </td>
              <td className={item.qty.UK6 != null ? selected : notSelected}>
                {item.qty.UK6 != null ? item.qty.UK6 : 0}
              </td>
              <td className={item.qty.UK7 != null ? selected : notSelected}>
                {item.qty.UK7 != null ? item.qty.UK7 : 0}
              </td>
              <td className={item.qty.UK8 != null ? selected : notSelected}>
                {item.qty.UK8 != null ? item.qty.UK8 : 0}
              </td>
              <td className={item.qty.UK9 != null ? selected : notSelected}>
                {item.qty.UK9 != null ? item.qty.UK9 : 0}
              </td>
            </>
          );
        }
      } else if (type === "slipper") {
        return (
          <>
            <td className={item.qty.UK2 != null ? selected : notSelected}>
              {item.qty.UK2 != null ? item.qty.UK2 : 0}
            </td>
            <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
            <td className={item.qty.UK4 != null ? selected : notSelected}>
              {item.qty.UK4 != null ? item.qty.UK4 : 0}
            </td>
            <td className={item.qty.UK5 != null ? selected : notSelected}>
              {item.qty.UK5 != null ? item.qty.UK5 : 0}
            </td>
            <td className={item.qty.UK6 != null ? selected : notSelected}>
              {item.qty.UK6 != null ? item.qty.UK6 : 0}
            </td>
            <td className={item.qty.UK6half != null ? selected : notSelected}>
              {item.qty.UK6half != null ? item.qty.UK6half : 0}
            </td>
            <td className={item.qty.UK7 != null ? selected : notSelected}>
              {item.qty.UK7 != null ? item.qty.UK7 : 0}
            </td>
            <td className={item.qty.UK8 != null ? selected : notSelected}>
              {item.qty.UK8 != null ? item.qty.UK8 : 0}
            </td>
            <td className={item.qty.UK9 != null ? selected : notSelected}>
              {item.qty.UK9 != null ? item.qty.UK9 : 0}
            </td>
            {/* <td className={item.qty.UK10 != null ? selected : notSelected}>
              {item.qty.UK10 != null ? item.qty.UK10 : 0}
            </td>
            <td className={item.qty.UK11 != null ? selected : notSelected}>
              {item.qty.UK11 != null ? item.qty.UK11 : 0}
            </td> */}
          </>
        );
      } else if (type === "mensslipper") {
        return (
          <>
            {/* <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
            <td className={item.qty.UK4 != null ? selected : notSelected}>
              {item.qty.UK4 != null ? item.qty.UK4 : 0}
            </td>
            <td className={item.qty.UK5 != null ? selected : notSelected}>
              {item.qty.UK5 != null ? item.qty.UK5 : 0}
            </td> */}
            <td className={item.qty.UK6 != null ? selected : notSelected}>
              {item.qty.UK6 != null ? item.qty.UK6 : 0}
            </td>
            <td className={item.qty.UK6 != null ? selected : notSelected}>
              {item.qty.UK6half != null ? item.qty.UK6half : 0}
            </td>
            <td className={item.qty.UK7 != null ? selected : notSelected}>
              {item.qty.UK7 != null ? item.qty.UK7 : 0}
            </td>
            <td className={item.qty.UK8 != null ? selected : notSelected}>
              {item.qty.UK8 != null ? item.qty.UK8 : 0}
            </td>
            <td className={item.qty.UK9 != null ? selected : notSelected}>
              {item.qty.UK9 != null ? item.qty.UK9 : 0}
            </td>
            <td className={item.qty.UK10 != null ? selected : notSelected}>
              {item.qty.UK10 != null ? item.qty.UK10 : 0}
            </td>
            <td className={item.qty.UK11 != null ? selected : notSelected}>
              {item.qty.UK11 != null ? item.qty.UK11 : 0}
            </td>
          </>
        );
      } else if (type === "insole") {
        return (
          <>
            <td className={item.qty.UK34 != null ? selected : notSelected}>
              {item.qty.UK34 != null ? item.qty.UK34 : 0}
            </td>
            <td className={item.qty.UK56 != null ? selected : notSelected}>
              {item.qty.UK56 != null ? item.qty.UK56 : 0}
            </td>
            <td className={item.qty.UK78 != null ? selected : notSelected}>
              {item.qty.UK78 != null ? item.qty.UK78 : 0}
            </td>
            <td className={item.qty.UK910 != null ? selected : notSelected}>
              {item.qty.UK910 != null ? item.qty.UK910 : 0}
            </td>
            <td className={item.qty.UK1112 != null ? selected : notSelected}>
              {item.qty.UK1112 != null ? item.qty.UK1112 : 0}
            </td>
          </>
        );
      } else if (type === "pos") {
        return (
          <>
            <td className={item.qty.UK3 != null ? selected : notSelected}>
              {item.qty.UK3 != null ? item.qty.UK3 : 0}
            </td>
          </>
        );
      }
    } else {
      return (
        <>
          {type !== "insole" ? <td>{item.colour}</td> : null}
          <td>{item.date}</td>
          {type == "insole" ? <td>{item.deliverySeason}</td> : null}
          <td>{item.total}</td>
          <td>{currency + item.price}</td>
        </>
      );
    }
  }

  function editBtns(editItem, removeItem, item) {
    return editItem && removeItem ? (
      <>
        <td>
          <button className="btn btn-remove" onClick={() => removeItem(item)}>
            x
          </button>
        </td>
      </>
    ) : null;
  }

  function cartContainsNewSizing(address, type) {
    let containsNewSizing = false;

    if (currency === "$") {
      getCartForAddress(address, type).map((item) => {
        if (parseInt(item.season) === 70 || parseInt(item.season) === 102) {
          containsNewSizing = true;
        }
      });
    }

    return containsNewSizing;
  }

  function getList(address, type) {
    let cart = getCartForAddress(address, type);

    return cart && cart.length ? (
      <>
        <strong class="cart-style-type">{type.toUpperCase()}</strong>
        <table>
          <thead>
            <tr>
              <th>Style</th>
              {cartContainsNewSizing(address, type)
                ? displayEU(type)
                : displaySizeHeaders(type)}
            </tr>
          </thead>
          <tbody>
            {getCartForAddress(address, type).map((item, i) => (
              <tr key={item + i} onClick={setDisplayDetail}>
                <td>{item.style}</td>
                {cartContainsNewSizing(address, type)
                  ? displaySizeRowEu(item, type)
                  : displaySizeRow(item, type)}
                {editBtns(editItem, removeItem, item)}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    ) : null;
  }

  return cart && cart.length > 0 && addresses && addresses.length > 0
    ? addresses.map((address) =>
        getCartForAddress(address).length ? (
          <div class="address-delivery">
            <p class="cart-delivery-address">
              <strong>Delivery Address</strong>
              {address.address1},{hasAddress2(address)}
              {address.town},{address.county},{address.postcode}
            </p>
            {getList(address, "shoe")}
            {getList(address, "mensshoe")}
            {getList(address, "slipper")}
            {getList(address, "mensslipper")}
            {getList(address, "sandal")}
            {getList(address, "elevatedsandal")}
            {getList(address, "insole")}
            {getList(address, "pos")}
          </div>
        ) : null
      )
    : null;
}

// function getInsoleList(address) {
//   let type = "insole";
//   let cart = getCartForAddress(address, type);

//   return cart && cart.length ? (
//     <>
//       <strong class="cart-style-type">Insoles</strong>
//       <table>
//         <thead>
//           <tr>
//             <th>Style</th>
//             {cartContainsNewSizing(address, type)
//               ? displayEU(type)
//               : displaySizeHeaders(type)}
//           </tr>
//         </thead>
//         <tbody>
//           {getCartForAddress(address, type).map((item, i) => (
//             <tr key={item + i} onClick={setDisplayDetail}>
//               <td>{item.style}</td>
//               {displaySizeRow(item, type)}
//               {editBtns(editItem, removeItem, item)}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </>
//   ) : null;
// }

// function getSlipperList(address) {
//   let type = "slipper";
//   let cart = getCartForAddress(address, type);

//   return cart && cart.length ? (
//     <>
//       <strong class="cart-style-type">Slippers</strong>
//       <table>
//         <thead>
//           <tr>
//             <th>Style</th>
//             {cartContainsNewSizing(address, type)
//               ? displayEU(type)
//               : displaySizeHeaders(type)}
//           </tr>
//         </thead>
//         <tbody>
//           {getCartForAddress(address, type).map((item, i) => (
//             <tr key={item + i} onClick={setDisplayDetail}>
//               <td>{item.style}</td>
//               {displaySizeRow(item, type)}
//               {editBtns(editItem, removeItem, item)}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </>
//   ) : null;
// }

// function getShoeList(address) {
//   let cart = getCartForAddress(address, "shoe");

//   return cart && cart.length ? (
//     <>
//       <strong class="cart-style-type">Shoes/Boots</strong>
//       <table>
//         <thead>
//           <tr>
//             <th>Style</th>
//             {cartContainsNewSizing(address, "shoe")
//               ? displayEU("shoe")
//               : displaySizeHeaders("shoe")}
//           </tr>
//         </thead>
//         <tbody>
//           {getCartForAddress(address, "shoe").map((item, i) => (
//             <tr key={item + i} onClick={setDisplayDetail}>
//               <td>{item.style}</td>
//               {displaySizeRow(item, "shoe")}
//               {editBtns(editItem, removeItem, item)}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </>
//   ) : null;
// }
