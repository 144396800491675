import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function currencyReducer(state = initialState.currency, action) {
  switch (action.type) {
    case types.CURRENCY_SAVE_IN_STATE:
      return (state = action.response);
    case types.CANCEL_ORDER:
      return (state = null);
    // case types.SUBMIT_ORDER_SUCCESS:
    //   return (state = null);
    case types.CURRENCY_SAVE_IN_STATE_SUCCESS:
      return action.response;
    case types.AUTH_SESSION_CLEAR:
      return (state = null);
    case types.LOAD_OFFLINE_ORDER:
      return (state = action.order.currency);
    case types.LOAD_ORDER_AMEND:
      switch(action.order[0].Currency){
        case "GBP":
          return (state = "£");
        case "USD":
          return (state = "$");
        case "EUR":
          return (state = "€");
        default:
          return (state = "£");
      }
    default:
      return state;
  }
}
