import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/getfiles";

export function getFiles(auth, order) {
  return fetch(baseUrl + "/" + order[0].OrderId, {
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    method: "GET",
  })
    .then(handleResponse)
    .then((response) => {
      return response.json();
    })
    .catch(handleError);
}
