import React from "react";
import { NavLink } from "react-router-dom";

export default function ({ cart, handleClick, adminAuth }) {
  return cart && adminAuth && cart.length > 0 ? (
    <NavLink
      class="btn btn-primary btn-checkout"
      onClick={handleClick}
      to="/admins/checkout"
    >
      Checkout
    </NavLink>
  ) : cart && cart.length > 0 ? (
    <NavLink
      class="btn btn-primary btn-checkout"
      onClick={handleClick}
      to="/checkout"
    >
      Checkout
    </NavLink>
  ) : null;
}
