import React from "react";
import TextInput from "../../shared/TextInput";

export default function Po({ po, onChange }) {
  return (
    <div className="confirm-declaration">
      <h3>Purchase Order Number</h3>
      <TextInput
        name="po"
        label="Enter PO number for this order"
        value={po}
        placeholder="Enter PO Number"
        onChange={onChange}
      />
    </div>
  );
}
