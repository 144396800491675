import React from "react";
import { Offline, Online } from "react-detect-offline";

export default function ({ user , handleDelete, handleSubmit, handleEdit, orders }) {
  const showAccountNumber = (customer) => {
    return customer.account ? customer.account : "NEW";
  }

  return orders && orders.length > 0 ? (
    <table>
      <thead>
        <th>Season</th>
        <th>Account</th>
        <th>Store</th>
        <th>Pairs</th>
        <th>Price</th>
        <th>Actions</th>
        <th></th>
        <th></th>
        <th></th>
      </thead>
      <tbody>
        {orders.map((order, i) => {
          return order.user === user ? (
            <tr>
              <td>{order.seasonLabel}</td>
              <td>{showAccountNumber(order)}</td>
              <td>{order.customer.storeName}</td>
              <td>{order.totals.qty}</td>
              <td>{order.currency + order.totals.price}</td>
             <Online>
              <td class="action-btn">
                  <a className="btn" onClick={() => handleEdit(order.id)}>
                    Edit
                  </a>
              </td>
             </Online>
             <Offline>
               <td>
                 <p>Your are offline and cannot submit this yet</p>
               </td>
             </Offline>
             <td className="action-btn">
                <a className="btn" onClick={() => handleDelete(order.id)}>
                  Delete
                </a>
             </td> 
            </tr>
          ) : null
        })}
      </tbody>
    </table>
  ) : null;
}
