import React from "react";
import SelectInput from "../shared/SelectInput";

const Addresses = ({ addresses, onChange, address, errors, cart }) => {

  function getAddressOptions() {
    let addressOptions = [];
    addresses.map((address, index) => {
      let addressLabel =
        address.address1 +
        ", " +
        address.address2 +
        ", " +
        address.town +
        ", " +
        address.county +
        ", " +
        address.postcode +
        ", " +
        address.country;
      addressOptions.push({
        value: index,
        text: addressLabel,
      });
    });
    return addressOptions;
  }
  function getFormattedAddress(address) {
    let addressLabel =
      address.address1 +
      ", " +
      address.address2 +
      ", " +
      address.town +
      ", " +
      address.county +
      ", " +
      address.postcode +
      ", " +
      address.country;
    return addressLabel;
  }

  return (
    <div className="select-address">
      <h3>Address</h3>
      {cart.length > 0 && cart[0].address ? (
        <p>{getFormattedAddress(cart[0].address)}</p>
      ) : <SelectInput
        name="address"
        label="address"
        onChange={onChange}
        defaultOption={"Select Address"}
        value={address.address1}
        options={getAddressOptions()}
        error={errors.address}
      />}
    </div>
  );
};

export default Addresses;
