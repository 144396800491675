import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function accountsReducer(state = initialState.accounts, action) {
  switch (action.type) {
    case types.LOAD_ACCOUNTS_SUCCESS:
      return action.accounts;
    case types.REQUEST_UPDATE:
      console.log("REQUEST_UPDATE");
      if (action.value !== "currency") {
        return [];
      }
    default:
      return state;
  }
}
