import React from "react";
import PropTypes from "prop-types";

const AddAnotherAddressButton = ({ reOpenAddressForm, addressFormOpen }) => {
  return !addressFormOpen ? (
    <button className="btn btn-primary" onClick={reOpenAddressForm}>
      Add New Address
    </button>
  ) : null;
};

AddAnotherAddressButton.propTypes = {
  reOpenAddressForm: PropTypes.bool,
  addressFormOpen: PropTypes.bool,
};

export default AddAnotherAddressButton;
