import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import Modal from "../modal/Modal";

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  handleCheckout,
  handleDraft,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [show, setShow] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal show={modalVisible} onClose={closeModal}>
        <div className="modal-content-custom">
          <h2>Confirm</h2>
          <div className="text">
            <p>You need to Checkout or Save order as Draft</p>
            <a
              onClick={handleCheckout}
              className="btn  btn-primary btn-link-custom"
            >
              Checkout
            </a>
            <a
              onClick={handleDraft}
              className="btn  btn-primary btn-link-custom mg-left"
            >
              Save as Draft
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default RouteLeavingGuard;
