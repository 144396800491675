import React, { useEffect, useState } from "react";
import "./Index.css";
import { connect } from "react-redux";
import Spinner from "../shared/Spinner";
import { loadReport } from "../../redux/actions/reportActions";
import List from "./List";
import { CSVLink } from "react-csv";
import Season from "../orders/Season";
import NameSelector from "./NameSelector";

function Index({ report, loadReport }) {
  const [styles] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    if (!report || report.length === 0) {
      loadReport()
        .then((report) => {
          collateStyles(report);
        })
        .catch((error) => {
          setApiError(true);
          console.log("Loading report failed" + error);
        });
    } else {
      if (!styles || styles.length === 0) collateStyles(report);
    }
  }, []);

  const searchStyles = (season = null) => {
    let result;

    if (season === null) {
      result = styles.filter((style) => {
        return style.sku.includes(search) || style.sku.includes(search);
      });
    } else {
      result = styles.filter((style) => {
        return style.Season === season;
      });
    }
    if (result.length > 0) {
      setSearchResults(result);
    } else {
      setSearchResults(["empty"]);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.indexOf("search") !== -1) {
      setSearch(value);
      if (!value || value === "") {
        setSearchResults(report);
      } else {
        searchStyles();
      }
    } else if (name.indexOf("season") !== -1) {
      searchStyles(value);
    }
  };

  const collateStyles = (report) => {
    let result = [];
    report.map((style, index) => {
      if (result.length === 0) {
        result.push(style);
      } else {
        let i = result.findIndex((item) => {
          return item.Sku === report[index].Sku;
        });
        if (i !== -1) {
          result[i].UK3 = result[i].UK3 + report[index].UK3;
          result[i].UK4 = result[i].UK4 + report[index].UK4;
          result[i].UK4half = result[i].UK4half + report[index].UK4half;
          result[i].UK5 = result[i].UK5 + report[index].UK5;
          result[i].UK5half = result[i].UK5half + report[index].UK5half;
          result[i].UK6 = result[i].UK6 + report[index].UK6;
          result[i].UK6half = result[i].UK6half + report[index].UK6half;
          result[i].UK7 = result[i].UK7 + report[index].UK7;
          result[i].UK8 = result[i].UK8 + report[index].UK8;
          result[i].UK9 = result[i].UK9 + report[index].UK9;
          result[i].UK10 = result[i].UK10 + report[index].UK10;
          result[i].UK11 = result[i].UK11 + report[index].UK11;
          result[i].UK12 = result[i].UK12 + report[index].UK12;
        } else {
          result.push(style);
        }
      }
    });
    setSearchResults(result);
  };

  const showResults = () => {
    return apiError ? (
      <div className="content orders">
        <p class="network-banner">
          Cannot load orders at this time, there is no connection to the server
        </p>
      </div>
    ) : (
      <div className="content report">
        <h1>Styles Ordered</h1>
        <Season onChange={handleChange}></Season>
        <NameSelector onChange={handleChange} styles={styles}></NameSelector>
        <List styles={searchResults} />
        <CSVLink className="btn btn-secondary" data={searchResults}>
          Export All
        </CSVLink>
      </div>
    );
  };

  return searchResults || searchResults.length === 0 || !apiError ? (
    <Spinner />
  ) : (
    showResults()
  );
}

function mapStateToProps(state) {
  return {
    report: state.report,
  };
}

const mapDispatchToProps = {
  loadReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
