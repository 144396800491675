import React, { useEffect, useState } from "react";
import "../../order/Index.css";
import { connect } from "react-redux";
import { Panel, Grid, Row, Col } from "rsuite";
import { useParams, useHistory } from "react-router-dom";
import { deleteReturns, getReturnById } from "../../../api/orderApi";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "./index.css";

const categoryId = process.env.REACT_APP_MAIN_CATEGORY;

function Index({ auth, adminAuth }) {
  const [orderData, setOrderData] = useState([]);
  const [willCanceled, setWillCancelled] = useState(true);
  const { Id, orderId } = useParams();
  let history = useHistory();

  useEffect(() => {
    console.log("init call", adminAuth);
    if (adminAuth) {
      auth = adminAuth;
    }
    if (orderData.length == 0) {
      getReturnById(Id, auth).then((response) => {
        console.log("response", response);
        setOrderData(response);
        response.map((item) => {
          console.log(item.Status[1]);
          if (item.Status[1] !== "New") {
            setWillCancelled(false);
          }
        });
      });
    }
  });
  const handleClick = () => {
    history.replace("/admins/cancelreturns");
  };

  function deleteReturn(returnId, orderId) {
    if (willCanceled) {
      confirmAlert({
        title: "Return Cancellation",
        message:
          "Are you sure to cancel the return? This will permanently remove return from system",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              if (adminAuth) {
                auth = adminAuth;
              }
              let formData = {
                returnId: returnId,
                orderId: orderId,
                user: orderData[0].User[0],
                rma: orderData[0].RMA,
              };
              console.log(formData);
              deleteReturns(formData, auth)
                .then((response) => {
                  console.log("deleteReturns", response);
                  toast.success("Return Has been Cancelled Successfully", {
                    autoClose: true,
                  });
                  history.push("/admins/cancelreturns");
                })
                .catch((e) => {
                  console.log("catch", e);
                });
            },
          },
          {
            label: "No",
            onClick: () => console.log("no"),
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Return Cancellation",
        message:
          "You can't cancel this return Because one or more item's from this return has been processed.",
        buttons: [
          {
            label: "OK",
          },
        ],
      });
    }
  }
  return (
    <>
      <Grid fluid>
        <Row gutter={16}>
          <Col xs={24}>
            <Panel
              bordered
              style={{ width: "80%", textAlign: "center", margin: "0 auto" }}
            >
              {/* <h1 style={{ color: "green" }}>Return Generated</h1>
              <h2 style={{ color: "gray", marginBottom: "20px" }}>
                {orderData.length > 0 && orderData[0].RMA}
              </h2>
              <p>
                Provide this RMA number to the retailer. <br></br>
                The RMA number should be printed and placed inside the shipped
                package
              </p> */}
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <a
                  className="btn btn-primary btn-checkout"
                  onClick={handleClick}
                  style={{ marginTop: "10px", textAlign: "left" }}
                >
                  Back to returns
                </a>
              </div>

              {orderData.length > 0 && (
                <>
                  <h4>
                    RMA :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {orderData[0].RMA}
                    </span>
                  </h4>

                  <h4>
                    User :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {orderData[0].User[0]}
                    </span>
                  </h4>

                  <h4
                    style={{
                      textAlign: "left",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Return Items
                  </h4>
                  <table className="table ret_table">
                    <thead>
                      <tr>
                        <th>Sku</th>
                        <th>Style</th>
                        <th>Colour</th>
                        <th>Size</th>
                        <th>Qty</th>
                        <th>Value</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData.map((item, i) => {
                        return (
                          <tr key={"orderData" + i}>
                            <td>{item.Sku}</td>
                            <td>{item.Style}</td>
                            <td>{item.Colour}</td>
                            <td>{item.Size}</td>
                            <td>{item.ReturnQty}</td>
                            <td>
                              {item.Currency[0]}
                              {item.ReturnValue}
                            </td>
                            <td>{item.Status[1]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 10,
                      alignItems: "center",
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <span style={{ fontSize: "20px" }}>Return Value :</span>
                    <span
                      style={{
                        fontSize: "30px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {orderData[0].Currency[0]}
                      {orderData[0].TotalValue}
                    </span>
                  </div>

                  <div className="flex20" style={{ gap: "20px" }}>
                    <button
                      className="confirm2"
                      onClick={() => deleteReturn(Id, orderId)}
                      style={{ width: "160px" }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </Panel>
          </Col>
        </Row>
      </Grid>
    </>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
