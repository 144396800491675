//STYLES
export const LOAD_STYLES = "LOAD_STYLES";
export const LOAD_STYLES_SUCCESS = "LOAD_STYLES_SUCCESS";
//AUTH
export const AUTH_LOGIN_API_SUCCESS = "AUTH_LOGIN_API_SUCCESS";
export const AUTH_LOGIN_AUTHENTICATED = "AUTH_LOGIN_AUTHENTICATED";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";
export const AUTH_SESSION_CLEAR = "AUTH_SESSION_CLEAR";

//ADMIN AUTH
export const ADMIN_AUTH_LOGIN_API_SUCCESS = "ADMIN_AUTH_LOGIN_API_SUCCESS";
export const ADMIN_AUTH_LOGIN_AUTHENTICATED = "ADMIN_AUTH_LOGIN_AUTHENTICATED";
export const ADMIN_AUTH_LOGIN_ERROR = "ADMIN_AUTH_LOGIN_ERROR";
export const ADMIN_AUTH_SESSION_CLEAR = "ADMIN_AUTH_SESSION_CLEAR";

//CUSTOMER
export const CUSTOMER_SAVE_IN_STATE_SUCCESS = "CUSTOMER_SAVE_IN_STATE_SUCCESS";
export const CUSTOMER_SAVE_IN_STATE = "CUSTOMER_SAVE_IN_STATE";
export const LOAD_CUSTOMER = "LOAD_CUSTOMER";
export const LOAD_CUSTOMER_SUCCESS = "LOAD_CUSTOMER_SUCCESS";

//ADDRESS
export const ADDRESS_SAVE_IN_STATE_SUCCESS = "ADDRESS_SAVE_IN_STATE_SUCCESS";
export const ADDRESS_SAVE_IN_STATE = "ADDRESS_SAVE_IN_STATE";
export const ADDRESS_DELETE = "ADDRESS_DELETE";
export const ADDRESS_DELETE_SUCCESS = "ADDRESS_DELETE_SUCCESS";
export const ADDRESS_EDIT = "ADDRESS_EDIT";
export const LOAD_ADDRESSES = "LOAD_ADDRESS";
export const LOAD_ADDRESSES_SUCCESS = "LOAD_ADDRESS_SUCCESS";

//BILLING ADDRESS
export const BILLING_ADDRESS_SAVE_IN_STATE_SUCCESS = "BILLING_ADDRESS_SAVE_IN_STATE_SUCCESS";
export const BILLING_ADDRESS_SAVE_IN_STATE = "BILLING_ADDRESS_SAVE_IN_STATE";
export const BILLING_ADDRESS_DELETE = "BILLING_ADDRESS_DELETE";
export const BILLING_ADDRESS_DELETE_SUCCESS = "BILLING_ADDRESS_DELETE_SUCCESS";
export const BILLING_ADDRESS_EDIT = "BILLING_ADDRESS_EDIT";
export const LOAD_BILLING_ADDRESSES = "LOAD_BILLING__ADDRESSES";
export const LOAD_BILLING_ADDRESSES_SUCCESS = "LOAD_BILLING_ADDRESSES_SUCCESS";
export const BILLING_ADDRESS_SAVE_INITIAL = "BILLING_ADDRESS_SAVE_INITIAL";
export const BILLING_ADDRESS_SAVE_INITIAL_SUCCESS = "BILLING_ADDRESS_SAVE_INITIAL_SUCCESS";

//SHIPPING ADDRESS
export const SHIPPING_ADDRESS_SAVE_IN_STATE_SUCCESS = "SHIPPING_ADDRESS_SAVE_IN_STATE_SUCCESS";
export const SHIPPING_ADDRESS_SAVE_IN_STATE = "SHIPPING_ADDRESS_SAVE_IN_STATE";
export const SHIPPING_ADDRESS_DELETE = "SHIPPING_ADDRESS_DELETE";
export const SHIPPING_ADDRESS_DELETE_SUCCESS = "SHIPPING_ADDRESS_DELETE_SUCCESS";
export const SHIPPING_ADDRESS_EDIT = "SHIPPING_ADDRESS_EDIT";
export const LOAD_SHIPPING_ADDRESSES = "LOAD_SHIPPING_ADDRESSES";
export const LOAD_SHIPPING_ADDRESSES_SUCCESS = "LOAD_SHIPPING_ADDRESSES_SUCCESS";
export const SHIPPING_ADDRESS_SAVE_INITIAL = "SHIPPING_ADDRESS_SAVE_INITIAL";
export const SHIPPING_ADDRESS_SAVE_INITIAL_SUCCESS = "SHIPPING_ADDRESS_SAVE_INITIAL_SUCCESS";

//CART
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const EMPTY_CART = "EMPTY_CART";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const EDIT_ITEM = "EDIT_ITEM";
export const CANCEL_ORDER = "CANCEL_ORDER";

//ORDER
export const SUBMIT_ORDER = "SUBMIT_ORDER";
export const SUBMIT_ORDER_SUCCESS = "SUBMIT_ORDER_SUCCESS";
export const LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS";
export const LOAD_ORDERS = "LOAD_ORDERS";
export const LOAD_ORDER_AMEND = "LOAD_ORDER_AMEND";
export const LOAD_ORDERS_AMEND_SUCCESS = "LOAD_ORDERS_AMEND_SUCCESS";
export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const LOAD_OFFLINE_ORDER = "LOAD_OFFLINE_ORDER";

//SEASONS
export const LOAD_SEASONS_SUCCESS = "LOAD_SEASONS_SUCCESS";
export const LOAD_SEASONS = "LOAD_SEASONS";

export const SET_CURRENT_SEASON = "SET_CURRENT_SEASON";

//UPDATE
export const REQUEST_UPDATE = "REQUEST_UPDATE";

//REPORT
export const LOAD_REPORT_SUCCESS = "LOAD_REPORT_SUCCESS";
export const LOAD_REPORT = "LOAD_REPORT";

//ACCOUNTS
export const LOAD_ACCOUNTS_SUCCESS = "LOAD_ACCOUNTS_SUCCESS";
export const LOAD_ACCOUNTS = "LOAD_ACCOUNTS";

//STOCK
export const LOAD_STOCK_SUCCESS = "LOAD_STOCK_SUCCESS";
export const LOAD_STOCK = "LOAD_STOCK";

//TOTALS
export const UPDATE_TOTALS = "UPDATE_TOTALS";

//POS
export const ADD_POS = "ADD_POS";

//CURRENCY
export const CURRENCY_SAVE_IN_STATE_SUCCESS = "CURRENCY_SAVE_IN_STATE_SUCCESS";
export const CURRENCY_SAVE_IN_STATE = "CURRENCY_SAVE_IN_STATE";

//OPTIONS
export const LOAD_OPTIONS_SUCCESS = "LOAD_OPTIONS_SUCCESS";

//ADDRESS
export const ADDRESS_SAVE_INITIAL = "ADDRESS_SAVE_INITIAL";
export const ADDRESS_SAVE_INITIAL_SUCCESS = "ADDRESS_SAVE_INITIAL_SUCCESS";

//API
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

//USER DATA
export const API_CALL_SUCCESS = "API_CALL_SUCCESS";

// By convention, actions that end in "_SUCCESS" are assumed to have been the result of a completed
// API call. But since we're doing an optimistic delete, we're hiding loading state.
// So this action name deliberately omits the "_SUCCESS" suffix.
// If it had one, our apiCallsInProgress counter would be decremented below zero
// because we're not incrementing the number of apiCallInProgress when the delete request begins.
// export const DELETE_COURSE_OPTIMISTIC = "DELETE_COURSE_OPTIMISTIC";
