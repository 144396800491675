import React from "react";
import { connect } from "react-redux";
import { adminLogout } from "../../redux/actions/authActions";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function AdminLogout({ adminLogout, adminAuth, ...props }) {
  let history = useHistory();

  const handleLogout = () => {
    try {
      adminLogout();
      toast.success("You are now logged Out.", { autoClose: true });
      history.push("/admin");
    } catch (error) {
      toast.error("Sorry and error occured. " + error, { autoClose: true });
    }
  };

  return adminAuth ? (
    <>
      <button className="btn btn-primary" onClick={handleLogout}>
        Sign Out
      </button>
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {
  adminLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogout);
