import React from "react";

export default function ({ styles, total }) {
  return (
    <table>
      <thead>
        <th>Season</th>
        <th>SKU</th>
        <th>Style</th>
        <th>Colour</th>
        <th>UK3</th>
        <th>UK4</th>
        <th>UK4.5</th>
        <th>UK5</th>
        <th>UK5.5</th>
        <th>UK6</th>
        <th>UK6.5</th>
        <th>UK7</th>
        <th>UK8</th>
        <th>UK9</th>
        <th>UK10</th>
        <th>UK11</th>
        <th>UK12</th>
      </thead>
      <tbody>
        {styles.map((style, i) => {
          return style ? (
            <tr>
              <td>{style.Season}</td>
              <td>{style.Sku}</td>
              <td>{style.Style}</td>
              <td>{style.Colour}</td>
              <td>{style.UK3}</td>
              <td>{style.UK4}</td>
              <td>{style.UK4half}</td>
              <td>{style.UK5}</td>
              <td>{style.UK5half}</td>
              <td>{style.UK6}</td>
              <td>{style.UK6half}</td>
              <td>{style.UK7}</td>
              <td>{style.UK8}</td>
              <td>{style.UK9}</td>
              <td>{style.UK10}</td>
              <td>{style.UK11}</td>
              <td>{style.UK12}</td>
            </tr>
          ) : null;
        })}
      </tbody>
    </table>
  );
}
