import React from "react";
import { NavLink } from "react-router-dom";
import AdminLogout from "../auth/AdminLogout";

import "./Index.css";

export default function ({ adminAuth }) {
  return adminAuth ? (
    <nav className="main-nav">
      <NavLink to="/admins/home">Home</NavLink>
      <NavLink to="/admins/buildreport">Report Builder</NavLink>
      <NavLink to="/admins/reports">Saved Reports</NavLink>
      <NavLink to="/admins/orderdetail">Order Status</NavLink>
      <NavLink to="/admins/orders">Delete/Edit Orders</NavLink>
      <NavLink to="/admins/orderplanner">Order Planner</NavLink>
      <NavLink to="/admins/returns">Pending Approvals</NavLink>
      <NavLink to="/admins/cancelreturns">Cancel Returns</NavLink>
      <AdminLogout />
    </nav>
  ) : null;
}
