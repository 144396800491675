import React from "react";
import "./App.css";
import Header from "./header/Header.js";
import Content from "./route/Routes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Network from "./network/Index";
import { ErrorBoundary } from "react-error-boundary";
import Notice from "./notice/Index";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

function App() {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div>
        <Network />
        <Header />
        <Notice />
        <div className="container ">
          <div className="inner-container">
            <Content />
          </div>
        </div>
        <ToastContainer autoClose={3000} hideProgressBar />
      </div>
    </ErrorBoundary>
  );
}

export default App;
