import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadAccounts } from "../../../redux/actions/accountsReducer";
import { saveCurrency } from "../../../redux/actions/currencyActions";
import { requestUpdate } from "../../../redux/actions/updateActions";
import { loadOrders } from "../../../redux/actions/orderActions";
import AccountsSearch from "./CustomerAccounts";
import { Grid, Row, Col } from "rsuite";
import SelectInput from "../../shared/SelectInput";
import "./Index.css";
import {
  getReturns,
  getReturnss,
  updateReturns,
  getReturnById,
  denyReturns,
} from "../../../api/orderApi";

function AdminReturns({
  history,
  accounts,
  requestUpdate,
  loadAccounts,
  auth,
  adminAuth,
  amend,
  user,
  orders,
  loadOrders,
  ...props
}) {
  const [customer, setCustomer] = useState(props.customer);
  const [currency, setCurrency] = useState();
  const [errors, setErrors] = useState({});
  const [account, setAccount] = useState();
  const [accountObject, setAccountObject] = useState();

  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [apiError, setApiError] = useState(false);
  const [orderList, setOrderList] = useState(orders);
  const [orderListDefault, setOrderListDefault] = useState(orders);

  const [accountSearch, setAccountSearch] = useState("");
  const [defaultAccount, setDefaultAccount] = useState();
  const [returnsData, setReturnsData] = useState([]);
  const [returnsIds, setReturnsIds] = useState([]);
  const [orderData, setOrderData] = useState([]);
  function getFinalReturn() {
    console.log("init call", adminAuth);
    if (adminAuth) {
      auth = adminAuth;
    }
    getReturnss(auth)
      .then((response) => {
        console.log("getReturns", response);
        let data = [];
        response.map((item) => {
          data.push(parseInt(item.Order_id));
        });
        setReturnsData(response);
        setReturnsIds(data);

        const is_draft = 0;

        loadOrders(auth, user, is_draft)
          .then((result) => {
            filterLatestOrders(result);
          })
          .catch((error) => {
            setApiError(true);
            console.log("Loading orders failed" + error);
          });
      })
      .catch((e) => {
        console.log("catch", e);
        setReturnsData([]);
      });
  }
  useEffect(() => {
    if (!returnsData || returnsData.length === 0) {
      getFinalReturn();
    }
  }, []);

  useEffect(() => {
    // if (accounts.length === 0 || accounts[0].update) {
    if (adminAuth) {
      auth = adminAuth;
    }
    loadAccounts(auth)
      .then(() => {
        filterAccountsByCurrency(currency);
      })
      .catch((error) => {
        console.log("connection not available to get accounts");
      });
    // } else {
    //   filterAccountsByCurrency(currency);
    // }
  }, []);
  // useEffect(() => {
  //   const is_draft = 0;
  //   loadOrders(auth, user, is_draft)
  //     .then((result) => {
  //       filterLatestOrders(result);
  //     })
  //     .catch((error) => {
  //       setApiError(true);
  //       console.log("Loading orders failed" + error);
  //     });
  // }, []);

  useEffect(() => {
    filterAccountsByCurrency(currency);
  }, [currency]);

  useEffect(() => {
    // console.log("account", account);
    if (accountObject && accountObject.hasOwnProperty("account")) {
      filterOrdersByAccount();
    }
  }, [accountObject]);

  const filterLatestOrders = (orders) => {
    if (orders && orders.length > 0) {
      let filteredResults = orders.filter((order) => {
        return order.Amended === null;
      });
      console.log("orders", filteredResults);
      setOrderList(filteredResults);
      let defaultOrders = JSON.parse(JSON.stringify(filteredResults));
      setOrderListDefault(defaultOrders);
    }
  };

  const getAccount = (selectedAccount) => {
    if (selectedAccount && selectedAccount.value) {
      let accountNumber = selectedAccount.value.split("/")[0].trim();
      let foundAccount = accounts.find((account) => {
        return account.account === accountNumber;
      });
      return foundAccount;
    }
  };

  const filterAccountsByCurrency = (value) => {
    console.log("curr", value);
    let transCurrency = "";
    let filter = [];

    switch (value) {
      case "$":
        transCurrency = "U";
        break;
      case "£":
        transCurrency = "";
        break;
      case "€":
        transCurrency = "EUR";
        break;
      default:
        transCurrency = "";
        break;
    }

    accounts
      .filter((account) => account.currency === transCurrency)
      .map((filterAccount) => {
        filter.push(filterAccount);
      });

    if (filter && filter.length > 0) {
      setFilteredAccounts(filter);
    } else {
      setFilteredAccounts(accounts);
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    let selectedAccount = getAccount(value);
    console.log("selectedAccount", selectedAccount);
    setAccount(value);
    setAccountObject(selectedAccount);
  };
  const clearFilters = () => {
    setAccount("-");
    setAccountObject({});
    setCurrency({});
    setDefaultAccount("");
    setAccountSearch("");
    let list = JSON.parse(JSON.stringify(orderListDefault));
    console.log("orderList", list);
    setOrderList(list);
  };
  const filterOrdersByAccount = () => {
    if (orderList && orderList.length > 0) {
      let accountNo = "";
      let curr = "";

      if (accountObject) {
        accountNo = accountObject.account;
      }
      if (currency) {
        curr = currency;
      }
      let filteredResults = orderList.filter((order) => {
        // if(curr && )
        return order.AccNo == accountNo;
      });

      setOrderList(filteredResults);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setCurrency(value);
    filterAccountsByCurrency(value);
    // requestUpdate("currency");
  };
  function handleReturn(Id) {
    console.log("id", Id);
    history.replace("/return/" + Id);
  }
  const getDefaultAccount = () => {
    if (account && account !== "-") {
      let filteredAccounts = accounts.filter((accountData) => {
        return account.value
          ? accountData.account.toLowerCase().trim() ===
              account.value.toLowerCase().trim()
          : accountData.account.toLowerCase().trim() ===
              account.toLowerCase().trim();
      });
      if (filteredAccounts && filteredAccounts.length > 0) {
        setDefaultAccount(
          filteredAccounts[0].account + " / " + filteredAccounts[0].name
        );
        setAccountSearch(
          filteredAccounts[0].account + " / " + filteredAccounts[0].name
        );
      }
    }
  };

  useEffect(() => {
    console.log("orderList", orderList);
  }, [orderList]);
  function updateTable(returnId, orderId) {
    console.log("updateReturns");
    if (adminAuth) {
      auth = adminAuth;
    }
    let formData = {
      returnId: returnId,
      orderId: orderId,
    };
    updateReturns(formData, auth)
      .then((response) => {
        console.log("updateReturns", response);
        getFinalReturn();
      })
      .catch((e) => {
        console.log("catch", e);
      });
    // getReturnById(returnId, auth).then((response) => {
    //   console.log("response return", response);
    //   // setOrderData(response);
    // });
  }
  function denyTable(returnId, orderId) {
    console.log("denyReturns");
    if (adminAuth) {
      auth = adminAuth;
    }
    let formData = {
      returnId: returnId,
      orderId: orderId,
    };
    denyReturns(formData, auth)
      .then((response) => {
        console.log("denyReturns", response);
        getFinalReturn();
      })
      .catch((e) => {
        console.log("catch", e);
      });
    // getReturnById(returnId, auth).then((response) => {
    //   console.log("response return", response);
    //   // setOrderData(response);
    // });
  }
  function viewItems(returnId, orderId) {
    console.log("viewReturns");
    if (adminAuth) {
      auth = adminAuth;
    }
    // getReturnById(returnId, auth).then((response) => {
    //   console.log("response", response);
    //   setOrderData(response);
    // });
    history.push("/admins/viewItems/" + returnId + "/returns/" + orderId);
    // getReturnById(returnId, auth).then((response) => {
    //   console.log("response return", response);
    //   // setOrderData(response);
    // });
  }
  return (
    <div className="content">
      <div className="customer-details">
        <h3>Customer Details</h3>
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={12}>
              <SelectInput
                name="currency"
                onChange={handleChange}
                defaultOption="Select currency"
                value={currency}
                label="Currency"
                disabled={false}
                options={[
                  { value: "-", text: "Select currency" },
                  { value: "£", text: "GBP" },
                  { value: "€", text: "EUR" },
                  { value: "$", text: "USD" },
                ]}
              />
              <a className="btn btn-primary" onClick={clearFilters}>
                Clear Filters
              </a>
            </Col>
            <Col xs={12}>
              <div className="account-number-group">
                <AccountsSearch
                  customer={customer}
                  accounts={filteredAccounts}
                  account={account}
                  onChange={handleInputChange}
                  errors={errors}
                  accountSearch={accountSearch}
                  defaultAccount={defaultAccount}
                  getDefaultAccount={getDefaultAccount}
                  setAccountSearch={setAccountSearch}
                />
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
      {orderList ? (
        <div className="orders_data">
          <Grid fluid>
            <Row gutter={16}>
              <Col xs={24}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Return #</th>
                      <th>Currency</th>
                      <th>Customer id</th>
                      <th>Date created</th>
                      <th>Order id</th>
                      <th>Total Value</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {returnsData.map((eachreturn, i) => {
                      console.log("ordersReturn", eachreturn);
                      return (
                        // i === 0 || (
                        // !returnsIds.includes(parseInt(order.Id)) ? (
                        <tr className="tb_row">
                          <td>
                            {eachreturn.Id.length > 0
                              ? "#" + eachreturn.Id[0]
                              : "#" + eachreturn.Id}
                          </td>
                          <td>{eachreturn.Currency}</td>
                          <td>
                            {eachreturn.Customer_id.length > 0
                              ? "#" + eachreturn.Customer_id
                              : "#" + eachreturn.Customer_id}
                          </td>

                          <td>{eachreturn.DateCreated}</td>
                          <td>
                            {eachreturn.Order_id.length > 0
                              ? "#" + eachreturn.Order_id
                              : "#" + eachreturn.Order_id}
                          </td>
                          <td>{eachreturn.TotalValue}</td>
                          <td className="setPad">
                            <div>
                              <button
                                className="viewitems1"
                                onClick={() =>
                                  viewItems(eachreturn.Id, eachreturn.Order_id)
                                }
                              >
                                View items
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                      // );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Grid>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  console.log("state.orders", state.orders);
  return {
    customer: state.customer,
    currency: state.currency,
    accounts: state.accounts,
    orders: state.orders,
    adminAuth: state.adminAuth,
    auth: state.auth,
    amend: state.amend,
    user: state.user,
  };
}

const mapDispatchToProps = {
  saveCurrency,
  loadAccounts,
  requestUpdate,
  loadOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminReturns);
