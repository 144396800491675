import React, { useState } from "react";
import "./Index.css";
import { connect } from "react-redux";
import { cancelOrder } from "../../redux/actions/cartActions";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function Index({ amend, cancelOrder, adminAuth }) {
  const [setErrors] = useState({});
  let history = useHistory();

  const handleClearCart = (event) => {
    event.preventDefault();
    try {
      cancelOrder(adminAuth);
      toast.success("Exited from order edit mode", { autoClose: true });
      history.push("/admins/orders");
    } catch (error) {
      setErrors({ onSave: error.message });
    }
  };

  return amend ? (
    <div className="editing-mode">
      <p>
        You are in editing mode.
        {adminAuth ? (
          <a className="c_exit_text" onClick={handleClearCart}>
            exit mode
          </a>
        ) : null}
      </p>
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    amend: state.amend,
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {
  cancelOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
