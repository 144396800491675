import React from "react";
import StyleForm from "./Form";

const Style = ({
  dates,
  setDates,
  style,
  colours,
  date,
  currency,
  addresses,
  address,
  onSave,
  getOptionLabel,
  onChange,
  errors,
  saving,
  sizes,
  deliverySeason,
  season,
  currentSeason,
  setColour,
  handleCheckout,
  cart,
  seasons,
}) => {
  console.log("style my", style);
  return (
    <div>
      <h1>
        {style.name + " "}
        <span className="price">
          {currency +
            parseFloat(
              style.custom_attributes.find((obj) => {
                return obj.attribute_code === "group_price";
              }).value
            )}
        </span>
      </h1>
      {/* <p>
        {style.custom_attributes
          .find((obj) => {
            return obj.attribute_code === "description";
          })
          .value.replace(/<\/?[^>]+(>|$)/g, "")}
      </p> */}
      <StyleForm
        dates={dates}
        setDates={setDates}
        onSave={onSave}
        addresses={addresses}
        onChange={onChange}
        errors={errors}
        colours={colours}
        style={style}
        date={date}
        address={address}
        getOptionLabel={getOptionLabel}
        currency={currency}
        saving={saving}
        sizes={sizes}
        deliverySeason={deliverySeason}
        season={season}
        currentSeason={currentSeason}
        setColour={setColour}
        cart={cart}
        handleCheckout={handleCheckout}
        seasons={seasons}
      />
    </div>
  );
};

export default Style;
