import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function optionReducer(state = initialState.options, action) {
  switch (action.type) {
    case types.LOAD_OPTIONS_SUCCESS:
      if(action.result.type === "colour"){
        return {...state, colour: action.result.options};
      }
      if(action.result.type === "season"){
        return {...state, season: action.result.options};
      }
      return state;
    case types.REQUEST_UPDATE:
      return [];
    default:
      return state;
  }
}
