import { handleResponse, handleError } from "./apiUtils";

const baseUrl = process.env.REACT_APP_BASE_URL;

function extractData(res) {
  return res.json().then((response) => {
    if (response && response.length > 0) {
      return response;
    } else {
      return null;
    }
  });
}

export function getOptionsByAttributeId(id, auth) {
  return fetch(baseUrl + "/optionAttributes?id=" + id, {
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
    method: "GET",
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
