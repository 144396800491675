import React from "react";
import SelectInput from "../shared/SelectInput";

export default function ({ available, onChange }) {
  return (
    <div className="warehouse">
      <SelectInput
        name="available"
        label="Available Now"
        onChange={onChange}
        defaultOption={available}
        value={available}
        options={[
          {
            value: true,
            text: "Yes",
          },
          {
            value: false,
            text: "No",
          },
        ]}
      />
    </div>
  );
}
