import React, { useState, useEffect } from "react";
import { getSavedQueries, deleteQueryById } from "../../../api/orderApi";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function SavedReports({ adminAuth }) {
  const [queryData, setQueryData] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if (!queryData || queryData.length === 0) {
      getSavedQueries(adminAuth)
        .then((response) => {
          console.log("getSavedQueries", response);
          setQueryData(response);
        })
        .catch((e) => {
          console.log("catch", e);
          setQueryData([]);
        });
    }
  }, []);

  const handleDelete = (Id) => {
    deleteQueryById(Id, adminAuth)
      .then((response) => {
        toast.success("Query Deleted", { autoClose: true });
        history.push("/admins/reports");
      })
      .catch((error) => {
        toast.error(
          "Sorry an error occured. cant Delete Query at this time " + error,
          { autoClose: true }
        );
      });
  };
  function handleConfirm(Id) {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(Id),
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  }
  function displayActionBtns(data) {
    let url = "/admins/report/" + data.Id;
    return (
      <>
        <td class="action-btn">
          {/* <a className="btn" onClick={() => handleEdit(data.Id)}>
            Edit
          </a> */}

          <NavLink class="btn btn-secondary" to={url}>
            Edit
          </NavLink>
          <a className="btn red" onClick={() => handleConfirm(data.Id)}>
            Delete
          </a>
        </td>
      </>
    );
  }
  return (
    <div>
      <h1>Saved Reports</h1>
      <div>
        {queryData && queryData.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Query Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {queryData.map((data, i) => {
                return (
                  <tr>
                    <td>{data.QueryName}</td>
                    {displayActionBtns(data)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SavedReports);
