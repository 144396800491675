import * as types from "./actionTypes";
import * as optionApi from "../../api/optionApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadOptionsSuccess(result) {
  return { type: types.LOAD_OPTIONS_SUCCESS, result };
}

export function loadOptions(id, auth, type) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return optionApi
      .getOptionsByAttributeId(id, auth)
      .then((options) => {
        dispatch(loadOptionsSuccess({ options: options, type: type }));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
