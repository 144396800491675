import React, { useState, useEffect } from "react";
import { getPlanners, deletePlannerById } from "../../../api/orderApi";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FlexboxGrid, Button, Loader } from "rsuite";

function SavedReports({ adminAuth }) {
  const [plannerData, setPlannerData] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if (!plannerData || plannerData.length === 0) {
      console.log("init call");
      getPlanners(adminAuth)
        .then((response) => {
          // console.log("getSavedQueries", response);
          setPlannerData(response);
        })
        .catch((e) => {
          console.log("catch", e);
          setPlannerData([]);
        });
    }
  }, []);

  const handleDelete = (Id) => {
    deletePlannerById(Id, adminAuth)
      .then((response) => {
        toast.success("Plan Deleted", { autoClose: true });
        history.push("/admins/orderplanner");
      })
      .catch((error) => {
        toast.error(
          "Sorry an error occured. cant Delete Planner at this time " + error,
          { autoClose: true }
        );
      });
  };
  function handleConfirm(Id) {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(Id),
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  }
  function displayActionBtns(data) {
    let editUrl = "/admins/plan/edit/" + data.Id;
    let viewUrl = "/admins/planner/" + data.Id;

    return (
      <>
        <td className="action-btn">
          {/* <a className="btn" onClick={() => handleEdit(data.Id)}>
            Edit
          </a> */}
          <NavLink className="btn btn-secondary" to={editUrl}>
            Edit
          </NavLink>
          <NavLink className="btn btn-secondary" to={viewUrl}>
            View
          </NavLink>
          <a className="btn red" onClick={() => handleConfirm(data.Id)}>
            Delete
          </a>
        </td>
      </>
    );
  }
  return (
    <div>
      <h1>All Planners</h1>
      <FlexboxGrid style={{ marginBottom: "10px" }}>
        <FlexboxGrid.Item colspan={12}>
          <NavLink to={"/admins/createplanner"}>
            <Button color="green" appearance="primary">
              Create Planner
            </Button>
          </NavLink>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <div>
        {plannerData && plannerData.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Planner Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {plannerData.map((data, i) => {
                return (
                  <tr key={"plannner_" + i}>
                    <td>{data.PlannerName}</td>
                    {displayActionBtns(data)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={24}>
              <Loader size="md" center content="loading" />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        )}
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SavedReports);
