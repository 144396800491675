import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function cartReducer(state = initialState.cart, action) {
  switch (action.type) {
    case types.ADD_TO_CART:
      return [...state, action.response];
    case types.EMPTY_CART:
      return (state = []);
    case types.EDIT_ITEM:
      return state;
    case types.CANCEL_ORDER:
      return (state = []);
    // case types.SUBMIT_ORDER_SUCCESS:
    //   return (state = []);
    case types.REMOVE_ITEM:
      const items = state.filter((item) => item !== action.item);
      return (state = items);
    case types.AUTH_SESSION_CLEAR:
      return (state = []);
    case types.LOAD_OFFLINE_ORDER:
      return (state = action.order.cart);
    case types.LOAD_ORDER_AMEND:
      let cart = [];
      action.order.map((order, i) => {
        let qty = {
          UK2: order.UK2,
          UK3: order.UK3,
          UK4: order.UK4,
          UK4half: order.UK4half,
          UK5: order.UK5,
          UK5half: order.UK5half,
          UK6: order.UK6,
          UK6half: order.UK6half,
          UK7: order.UK7,
          UK7half: order.UK7half,
          UK8: order.UK8,
          UK9: order.UK9,
          UK10: order.UK10,
          UK11: order.UK11,
          UK12: order.UK12,
          UK34: order.UK34,
          UK56: order.UK56,
          UK78: order.UK78,
          UK910: order.UK910,
          UK1112: order.UK1112,
        };
        let item = {
          address: {
            address1: order.Street1,
            address2: order.Street2,
            town: order.Town,
            county: order.County,
            postcode: order.Postcode,
            country: order.Country,
          },
          colour: order.Colour,
          date: order.RequiredDate,
          price: order.Price.length > 2 ? order.Price[2] : order.Price[1],
          qty: qty,
          season: order.Season,
          sku: order.Sku,
          style: order.Style,
          total: order.Qty.length > 2 ? order.Qty[2] : order.Qty[1],
          type: order.Type,
        };
        cart.push(item);
      });

      return (state = cart);
    default:
      return state;
  }
}
