import React from "react";
import "./Total.css";

export default function ({ totals, currency }) {
  return totals ? (
    <div className="cart-totals">
      <p>TOTALS</p>
      <p className="total-value">
        Value <span>{currency + totals.price}</span>
      </p>
      <p className="total-value">
        Pairs <span>{totals.qty}</span>
      </p>
    </div>
  ) : null;
}
