import React, { useState, useEffect } from "react";
import "./AddressDetails.css";

const AddressDetails = ({
  addresses,
  customer,
  handleDelete,
  handleEdit,
  currency,
}) => {
  const isNewCustomerHeader = () => {
    return customer && customer.account === "NEW" ? <th>Is Billing?</th> : null;
  };

  const isNewCustomerRow = (address) => {
    return customer && customer.account === "NEW" ? (
      <td>{address.billing ? "YES" : ""}</td>
    ) : null;
  };

  return addresses.length > 0 ? (
    <div className="address-details">
      <table>
        <thead>
          <th>Street</th>
          <th>Town/City</th>
          <th>{currency === "$" ? "State" : "County"}</th>
          <th>{currency === "$" ? "Zip" : "Postcode"}</th>
          <th>Country</th>
          {/* {isNewCustomerHeader()} */}
        </thead>
        <tbody>
          {addresses.map(function (address, i) {
            return (
              <tr key={i}>
                <td>{address.address1}</td>
                <td>{address.town}</td>
                <td>{address.county}</td>
                <td>{address.postcode}</td>
                <td>{address.country}</td>
                {/* {isNewCustomerRow(address)} */}
                {editBtns(handleEdit, handleDelete, address)}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : null;
};

function editBtns(handleEdit, handleDelete, address) {
  return handleEdit && handleDelete ? (
    <>
      {/* <td>
        <button className="edit-btn" onClick={(e) => handleEdit(e, address)}>
          Edit
        </button>
      </td> */}
      <td>
        <button className="edit-btn" onClick={(e) => handleDelete(e, address)}>
          -
        </button>
      </td>
    </>
  ) : null;
}

export default AddressDetails;
