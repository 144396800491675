import React from "react";

const ChangeCustomerButton = ({ reOpenCustomerForm, customerFormOpen }) => {
  return !customerFormOpen ? (
    <button className="btn btn-primary" onClick={reOpenCustomerForm}>
      Change Customer
    </button>
  ) : null;
};

export default ChangeCustomerButton;
