import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function posReducer(state = initialState.pos, action) {
  switch (action.type) {
    case types.ADD_POS:
      return (state = action.response);
    case types.EMPTY_CART:
      return (state = []);
    case types.CANCEL_ORDER:
      return (state = []);
    // case types.SUBMIT_ORDER_SUCCESS:
    //   return (state = []);
    default:
      return state;
  }
}
