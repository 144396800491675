import React from "react";
import SelectInput from "../shared/SelectInput";

const DeliverySeason = ({ onChange, errors, deliverySeason, season }) => {
  //Insole season code is 5776
  return season == 5776 ? (
    <div className="select-delivery">
      <h3>Delivery Season</h3>
      <SelectInput
        name="deliverySeason"
        onChange={onChange}
        label={"delivery"}
        defaultOption="Select Delivery Season"
        value={deliverySeason}
        error={errors.deliverySeason}
        options={[
          { value: "Autumn/Winter", text: "Autumn/Winter" },
          { value: "Spring/Summer", text: "Spring/Summer" },
        ]}
      />
    </div>
  ) : null;
};

export default DeliverySeason;
