import React from "react";
import TextInput from "../../shared/TextInput";

export default function ShippingInstructions({ shipping, onChange }) {
  return (
    <div className="confirm-declaration">
      <h3>Shipping Instructions</h3>
      <TextInput
        value={shipping}
        name="shipping"
        label="Enter special shipping instructions for this order"
        placeholder="Enter Instructions"
        onChange={onChange}
      />
    </div>
  );
}
