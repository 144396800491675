import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_BASE_URL;

function extractData(res) {
  return res.json().then((response) => {
    if (response) {
      return response;
    } else {
      return null;
    }
  });
}

export function getStock(auth,warehouse) {
  //prettier-ignore
  return fetch(
    baseUrl+"/stock?warehouse=" + warehouse,
    {
      headers: {
        "authorization": "Bearer " + auth,
        "Content-Type": "application/json"
      },
      method: 'GET'
    }
  )
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}

