import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function orderReducer(state = initialState.orderAmends, action) {
  switch (action.type) {
    case types.LOAD_ORDER_AMEND:
      let confirm = {
        signature: action.order[0].Signature,
        comments: action.order[0].Comments,
        shipping: action.order[0].Shipping,
        discount: action.order[0].Discount,
        promo: action.order[0].Promo,
        po: action.order[0].Po,
      };
      return (state = confirm);
    case types.AUTH_SESSION_CLEAR:
      return (state = null);
    default:
      return state;
  }
}
