import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL;

let header = new Headers();

header.append("Content-Type", "application/json");

function extractData(response) {
  if (response && response.length > 0) {
    return response;
  } else {
    return null;
  }
}

export function getStylesOrdered() {
  return fetch(baseUrl + "report", {
    headers: header,
    method: "GET",
    cache: "no-cache",
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
