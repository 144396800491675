import React, { useEffect, useState } from "react";
import SelectInput from "../shared/SelectInput";
import { DatePicker } from "rsuite";

const Dates = ({
  dates,
  setDates,
  onChange,
  errors,
  date,
  style,
  season,
  seasons,
}) => {
  function getDateOptions2() {
    const options = [
      { value: "At once", text: "At once" },
      { value: dates ? dates : "Choose a date", text: "Choose a date" },
    ];
    return options;
  }
  const getDateOptions = () => {
    const defaultOptions = [
      { value: "At Once", text: "At Once" },
      { value: "JANUARY", text: "JANUARY" },
      { value: "FEBRUARY", text: "FEBRUARY" },
      { value: "MARCH", text: "MARCH" },
      { value: "APRIL", text: "APRIL" },
      { value: "MAY", text: "MAY" },
      { value: "JUNE", text: "JUNE" },
      { value: "JULY", text: "JULY" },
      { value: "AUGUST", text: "AUGUST" },
      { value: "SEPTEMBER", text: "SEPTEMBER" },
      { value: "OCTOBER", text: "OCTOBER" },
      { value: "NOVEMBER", text: "NOVEMBER" },
      { value: "DECEMBER", text: "DECEMBER" },
    ];
    console.log("season", season);
    console.log("all season", seasons);

    let available_dates = style.custom_attributes.find((obj) => {
      return obj.attribute_code === "available_dates";
    });
    console.log("available_dates", available_dates);
    // let temp = 'SS24:JANUARY,FEBRUARY,MARCH,APRIL,MAY,JUNE,JULY,AUGUST | SS23:JANUARY,FEBRUARY,MARCH,APRIL,MAY,JUNE,JULY,AUGUST';
    if (available_dates) {
      let seasonWithDates = available_dates.value.split("|");
      let dates = [];
      seasonWithDates.map((item) => {
        let tempArr = item.split(":");
        const currseason = tempArr[0];
        const currdates = tempArr[1];
        console.log("currseason", currseason);
        const seasonObject = seasons.find(
          (seas) => seas.name.toLowerCase() === currseason.toLowerCase()
        );
        console.log("seasonObject", seasonObject);
        if (seasonObject && parseInt(seasonObject.id) == season) {
          let tempDateArr = currdates.split(",");
          console.log("tempDateArr", tempDateArr);
          if (tempDateArr[0] != "All") {
            tempDateArr.map((dateItem) => {
              let date = { value: dateItem, text: dateItem };
              dates.push(date);
            });
          } else {
            dates = defaultOptions;
          }
        }
        // console.log('seasonObject',seasonObject);
      });
      console.log("dates", dates);
      return dates;
    }

    return defaultOptions;
  };
  function months(month) {
    // eslint-disable-next-line default-case
    switch (month) {
      case 1:
        return "january";
      case 2:
        return "february";
      case 3:
        return "march";
      case 4:
        return "april";
      case 5:
        return "may";
      case 6:
        return "june";
      case 7:
        return "july";
      case 8:
        return "august";
      case 9:
        return "september";
      case 10:
        return "october";
      case 11:
        return "november";
      case 12:
        return "december";
    }
  }
  return (
    <div className="select-delivery">
      <h3>Date</h3>
      <SelectInput
        name="date"
        onChange={onChange}
        label={"date"}
        defaultOption="Select Date"
        value={date}
        error={errors.date}
        options={getDateOptions2()}
      />
      {dates && (
        <DatePicker
          // disabledDate={(date) => {
          //   let getOptions = getDateOptions();
          //   // getOptions.map((eachoption)=>{
          //   //   eachoption.value
          //   // })
          //   let month = months(parseInt(JSON.stringify(date).split("-")[1]));
          //   var found = getOptions.find(function (element) {
          //     let val = element.value;
          //     return val.toLowerCase() === month.toLowerCase();
          //   });
          //   return !found;
          // }}
          onChange={(value) => {
            console.log(JSON.stringify(value).split('"')[1]);
            let val = {
              target: {
                value: JSON.stringify(value).split('"')[1],
                name: "date",
              },
            };
            setDates(val.target.value);
            onChange(val);
          }}
        />
        // <input
        // name="date"
        // type="date"
        // onChange={(e) => {
        //   setDates(e.target.value);
        //   onChange(e);
        // }}
        //   value={startDate}
        // />
      )}
    </div>
  );
};

export default Dates;
