import React from "react";
import { Link } from "react-router-dom";
import stylesheet from "./List.css";

function getImage(item){
 return item.media_gallery_entries && item.media_gallery_entries[0] ? (
   <>
      <img
      width="300px"
      alt={"Product image of " + item.name}
      src={
        "https://strivefootwear.com/pub/media/catalog/product/" +
        item.media_gallery_entries[0].file
      }
      className={stylesheet.mainStyleImage}
      />
  </>
 ) : (
  <>
    <img
      width="300px"
      alt={"Product Image Coming Soon"}
      src={
        "http://strivefootwear.com/pub/media/soon.jpg"
      }
      className={stylesheet.mainStyleImage}
      />
  </>
 )
}

const List = ({ styles, currency, getColour }) => {
  return (
  <div class="container style-list-page">
    <div>
      <ul class="style-list">
        {styles.map((item, i) => {
          return item ? (
            <li key={i} class="style-item">
              <Link to={"/style/" + item.name}>
                {getImage(item)}
              </Link>
              <p className="title">{item.name}</p>
              <p className="price">
                <span>
                  {currency +
                    parseFloat(
                      item.custom_attributes.find((obj) => {
                        return obj.attribute_code === "group_price";
                      }).value
                    )}
                </span>
              </p>
              {/* <p>SKU {item.sku}</p> */}
            </li>
          ) : null;
        })}
      </ul>
    </div>
  </div>
)};

export default List;
