import React from "react";
import PropTypes from "prop-types";
import TextInput from "../shared/TextInput";
import PasswordInput from "../shared/PasswordInput";
import "./LoginForm.css";

const LoginForm = ({ user, onSave, onChange, saving = false, errors = {} }) => {
  return (
    <div className="container login">
      <div className="inner-container">
        <form onSubmit={onSave} className="centeredForm">
          <h2>Login</h2>
          {errors.onSave && (
            <div className="alert alert-danger" role="alert">
              {errors.onSave}
            </div>
          )}
          <TextInput
            name="username"
            label="Username"
            value={user.username}
            placeholder="Username"
            onChange={onChange}
            error={errors.username}
          />

          <PasswordInput
            name="password"
            label="Password"
            placeholder="Password"
            value={user.password}
            onChange={onChange}
            error={errors.password}
          />

          <button type="submit" disabled={saving} className="btn btn-primary">
            {saving ? "Submitting..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  errors: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  saving: PropTypes.bool,
};

export default LoginForm;
