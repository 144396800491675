export async function handleResponse(response) {
  if (response.ok) {
    return response;
  }
  if (response.status === 401) return { status: response.status };
  if (response.status === 400) {
    const error = await response.text();
    throw new Error(error);
  }
  if (response.status === 429) {
    throw new Error("Too many login attempts, try again in 30 minutes");
  }
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}
