import * as types from "./actionTypes";
import * as stockApi from "../../api/stockApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadStockSuccess(stock) {
  return { type: types.LOAD_STOCK_SUCCESS, stock };
}

export function loadStock(auth,warehouse) {
    return function (dispatch) {
      dispatch(beginApiCall());
      return stockApi
        .getStock(auth,warehouse)
        .then((stock) => {
          dispatch(loadStockSuccess(stock))
          return stock;
        })
        .catch((error) => {
          dispatch(apiCallError(error));
          throw error;
        });
  }
}