import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function totalReducer(state = initialState.totals, action) {
  let qty;
  let price;
  let newState;

  switch (action.type) {
    case types.UPDATE_TOTALS:
      qty = state.qty + action.item.qty;
      price = state.price + action.item.price;

      newState = {
        qty: qty,
        price: Math.round((price + Number.EPSILON) * 100) / 100,
      };

      return newState;
    case types.REMOVE_ITEM:
      qty = parseInt(state.qty) - parseInt(action.item.total);
      price = state.price - action.item.price;

      newState = {
        qty: qty,
        price: Math.round((price + Number.EPSILON) * 100) / 100,
      };
      return newState;
    case types.EMPTY_CART:
      return { qty: 0, price: 0 };
    case types.CANCEL_ORDER:
      return (state = { qty: 0, price: 0 });
    // case types.SUBMIT_ORDER_SUCCESS:
    //   return { qty: 0, price: 0 };
    case types.AUTH_SESSION_CLEAR:
      return (state = { qty: 0, price: 0 });
    case types.LOAD_ORDER_AMEND:
      return (state = { qty: action.order[0].Qty[0], price: action.order[0].Price[0] });
    case types.LOAD_OFFLINE_ORDER:
      return (state = action.order.totals);
    default:
      return state;
  }
}
