import React from "react";
import "./Index.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

function Edit({ order, orders }) {
  return !orders || orders.length === 0 ? (
    <Redirect to="/orders" />
  ) : (
    <div className="content order">
      <h1>{order.CustomerId}</h1>
    </div>
  );
}

function getOrderBySlug(orders, slug) {
  return (
    orders.find((order) => {
      return parseInt(order.Id) === parseInt(slug);
    }) || null
  );
}

function mapStateToProps(state, ownProps) {
  const slug = ownProps.match.params.slug;
  const order =
    slug && state.orders.length > 0
      ? getOrderBySlug(state.orders, slug)
      : false;

  return {
    order,
    orders: state.orders,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
