import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function customerReducer(state = initialState.amend, action) {
  switch (action.type) {
    case types.LOAD_ORDER_AMEND:
      let id = 0;
      if (action.id.length > 0) {
        id = action.id[0];
      } else {
        id = action.id;
        console.log(id);
      }
      return (state = id);
    case types.CANCEL_ORDER:
      return (state = false);
    case types.AUTH_SESSION_CLEAR:
      return (state = false);
    default:
      return state;
  }
}
