import * as types from "./actionTypes";
import * as accountApi from "../../api/accountApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadAccountsSuccess(accounts) {
  return { type: types.LOAD_ACCOUNTS_SUCCESS, accounts };
}

export function loadAccounts(auth) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return accountApi
      .getAccounts(auth)
      .then((accounts) => {
        dispatch(loadAccountsSuccess(accounts));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
