import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ({ data }) {
  return (
    <ExcelFile>
      <ExcelSheet data={data} name="Stock">
        <ExcelColumn label="Sku" value="sku" />
        <ExcelColumn label="Description" value="description" />
        <ExcelColumn label="On Order" value="onorder" />
        <ExcelColumn label="Sales Order" value="saleorder" />
        <ExcelColumn label="In Stock" value="instock" />
      </ExcelSheet>
    </ExcelFile>
  );
}
