import React from "react";
import SelectInput from "../shared/SelectInput";

export default function ({ seasons, season, onChange }) {
  return (
    <div className="season">
      <SelectInput
        name="season"
        label="Season"
        onChange={onChange}
        defaultOption={seasons && seasons.length > 0 ? seasons[1] : "not found"}
        value={season}
        options={[
          {
            value: "AW2021",
            text: "AW20",
          },
          {
            value: "SS2021",
            text: "SS21",
          },
          {
            value: "AW2021",
            text: "AW21",
          },
          {
            value: "SS2022",
            text: "SS22",
          },
        ]}
      />
    </div>
  );
}
