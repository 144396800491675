import React, { useEffect, useState } from "react";
import TextInput from "../shared/TextInput";
import { AutoComplete } from "rsuite";

const CustomerAccounts = ({
  customer,
  accounts,
  account,
  onChange,
  errors = {},
}) => {
  const [accountSearch, setAccountSearch] = useState("");
  const [accountsToShow, setAccountsToShow] = useState(accounts);
  const [accountData, setAccountData] = useState([]);
  const [defaultAccount, setDefaultAccount] = useState();

  useEffect(() => {
    createAccountData();
  }, []);

  useEffect(() => {
    createAccountData();
  }, [accounts]);

  useEffect(() => {
    filterAccounts();
  }, [accountSearch]);

  useEffect(() => {
    getDefaultAccount();
  }, [account, accounts]);

  const createAccountData = () => {
    setAccountData(
      accounts.map((account) => account.account + " / " + account.name)
    );
  };

  const filterAccounts = () => {
    let filteredAccounts = accounts.filter((accountData) => {
      return (
        accountData.name.toLowerCase().trim().includes(accountSearch) ||
        accountData.account.toLowerCase().trim().includes(accountSearch)
      );
    });
    setAccountsToShow(filteredAccounts);
  };

  const getDefaultAccount = () => {
    if (account && account !== "-") {
      let filteredAccounts = accounts.filter((accountData) => {
        return account.value
          ? accountData.account.toLowerCase().trim() ===
              account.value.toLowerCase().trim()
          : accountData.account.toLowerCase().trim() ===
              account.toLowerCase().trim();
      });
      if (filteredAccounts && filteredAccounts.length > 0) {
        setDefaultAccount(
          filteredAccounts[0].account + " / " + filteredAccounts[0].name
        );
        setAccountSearch(
          filteredAccounts[0].account + " / " + filteredAccounts[0].name
        );
      }
    }
  };

  return accounts && accounts.length > 0 ? (
    <>
      <label>Find Account</label>
      <AutoComplete
        value={accountSearch}
        defaultValue={defaultAccount}
        onChange={(value) => setAccountSearch(value)}
        onSelect={(value) =>
          onChange({ target: { name: "account", value: value } })
        }
        data={accountData}
      />
    </>
  ) : (
    <>
      <p>Select currency to select account</p>
      <TextInput
        name="accountNumber"
        label="Account Number"
        value={customer.accountNumber}
        placeholder="Account Number"
        onChange={onChange}
        error={errors.accountNumber}
        disabled={false}
      />
    </>
  );
};

export default CustomerAccounts;
