import React, { useState } from "react";
import HamburgerMenu from "react-hamburger-menu";
import Content from "../Items";
import Pos from "../Pos";
import Count from "./Count";
import Total from "../Total";
import Clear from "./ClearBtn";
import Checkout from "./CheckoutBtn";
import "./Index.css";

export default function Index({
                 cart,
                 pos,
                 totals,
                 currency,
                 addresses,
                 emptyCart,
                 getStyle,
                 removeItem,
                 editItem,
                 getCartForAddress,
                 adminAuth,
               }) {
                 const [open, setOpen] = useState(false);

                 function closeCart() {
                   setOpen(false);
                 }

                 function showPos() {
                   return pos && pos.length > 0 ? (
                     <>
                       <Pos pos={pos}></Pos>
                     </>
                   ) : null;
                 }

                 function getCart() {
                   return open ? (
                     <nav class="minicart">
                       <Content
                         cart={cart}
                         addresses={addresses}
                         // addresses={shippingAddresses}
                         currency={currency}
                         getStyle={getStyle}
                         removeItem={removeItem}
                         editItem={editItem}
                         getCartForAddress={getCartForAddress}
                       />
                       {showPos()}
                       <Total currency={currency} totals={totals} />
                       <Clear
                         cart={cart}
                         emptyCart={emptyCart}
                         label={"Empty Cart"}
                       />
                       <Checkout
                         cart={cart}
                         handleClick={closeCart}
                         adminAuth={adminAuth}
                       />
                     </nav>
                   ) : null;
                 }

                 return (
                   <>
                     <div className="basket">
                       <Count totals={totals} />
                       <HamburgerMenu
                         isOpen={open}
                         menuClicked={() =>
                           open ? setOpen(false) : setOpen(true)
                         }
                         width={18}
                         height={15}
                         strokeWidth={1}
                         rotate={0}
                         color="black"
                         borderRadius={5}
                         animationDuration={0.5}
                       />
                     </div>
                     <div className="nav">{getCart()}</div>
                   </>
                 );
               }
