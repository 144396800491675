import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function AdminProtectedRoute({ children, adminAuth, location, ...props }) {
  return adminAuth != null ? (
    <Route {...props} render={({ location }) => children} />
  ) : (
    <Redirect
      to={{
        pathname: "/admin",
        state: { from: location },
      }}
    />
  );
}

function mapStateToProps(state) {
  return {
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProtectedRoute);
