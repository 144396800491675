import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function styleReducer(state = initialState.report, action) {
  switch (action.type) {
    case types.LOAD_REPORT_SUCCESS:
      return action.report;
    case types.REQUEST_UPDATE:
      return [];
    default:
      return state;
  }
}
