import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_AUTH_URL;

let header = new Headers();

header.append("Content-Type", "application/json");

function extractData(res) {
  return res.json().then((response) => {
    if (response) {
      return response;
    } else {
      return null;
    }
  });
}

export function getCustomerToken(username, password) {
  let data = {
    username: username,
    password: password,
  };
  return fetch(baseUrl + "/auth", {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
export function getAdminToken(username, password) {
  let data = {
    username: username,
    password: password,
  };
  return fetch(baseUrl + "/adminauth", {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
