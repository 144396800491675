import React, { useEffect, useState } from "react";
import "../../../order/Index.css";
import { connect } from "react-redux";
import { Panel, Grid, Row, Col } from "rsuite";
import { useParams, useHistory } from "react-router-dom";
import {
  updateReturns,
  denyReturns,
  getReturnById,
} from "../../../../api/orderApi";
import "../Index.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";

const categoryId = process.env.REACT_APP_MAIN_CATEGORY;

function Index({ auth, adminAuth }) {
  const [orderData, setOrderData] = useState([]);
  const { Id, orderId } = useParams();
  let history = useHistory();

  useEffect(() => {
    console.log("init call", adminAuth);
    if (adminAuth) {
      auth = adminAuth;
    }
    if (orderData.length == 0) {
      getReturnById(Id, auth).then((response) => {
        console.log("response", response);
        setOrderData(response);
      });
    }
  });
  const handleClick = () => {
    history.replace("/admins/returns");
  };
  function updateTable(returnId, orderId) {
    confirmAlert({
      title: "Return approval",
      message: "Are you sure to approve the return",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (adminAuth) {
              auth = adminAuth;
            }
            let formData = {
              returnId: returnId,
              orderId: orderId,
              user: orderData[0].User[0],
            };
            updateReturns(formData, auth)
              .then((response) => {
                console.log("updateReturns", response);
                toast.success("Return Has been Approved Successfully", {
                  autoClose: true,
                });
                history.push("/admins/returns");
              })
              .catch((e) => {
                console.log("catch", e);
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("no"),
        },
      ],
    });
    // console.log("updateReturns");

    // getReturnById(returnId, auth).then((response) => {
    //   console.log("response return", response);
    //   // setOrderData(response);
    // });
  }
  function denyTable(returnId, orderId) {
    confirmAlert({
      title: "Return rejection",
      message: "Are you sure to reject the return",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (adminAuth) {
              auth = adminAuth;
            }
            let formData = {
              returnId: returnId,
              orderId: orderId,
              user: orderData[0].User[0],
            };
            denyReturns(formData, auth)
              .then((response) => {
                console.log("denyReturns", response);
                history.push("/admins/returns");
              })
              .catch((e) => {
                console.log("catch", e);
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("no"),
        },
      ],
    });

    // getReturnById(returnId, auth).then((response) => {
    //   console.log("response return", response);
    //   // setOrderData(response);
    // });
  }
  return (
    <>
      <Grid fluid>
        <Row gutter={16}>
          <Col xs={24}>
            <Panel
              bordered
              style={{ width: "80%", textAlign: "center", margin: "0 auto" }}
            >
              {/* <h1 style={{ color: "green" }}>Return Generated</h1>
              <h2 style={{ color: "gray", marginBottom: "20px" }}>
                {orderData.length > 0 && orderData[0].RMA}
              </h2>
              <p>
                Provide this RMA number to the retailer. <br></br>
                The RMA number should be printed and placed inside the shipped
                package
              </p> */}
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <a
                  className="btn btn-primary btn-checkout"
                  onClick={handleClick}
                  style={{ marginTop: "10px", textAlign: "left" }}
                >
                  Back to returns
                </a>
              </div>
              <h4 style={{ textAlign: "left" }}>Return Items</h4>
              {orderData.length > 0 && (
                <>
                  <table className="table ret_table">
                    <thead>
                      <tr>
                        <th>Sku</th>
                        <th>Style</th>
                        <th>Colour</th>
                        <th>Size</th>
                        <th>Qty</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData.map((item, i) => {
                        return (
                          <tr key={"orderData" + i}>
                            <td>{item.Sku}</td>
                            <td>{item.Style}</td>
                            <td>{item.Colour}</td>
                            <td>{item.Size}</td>
                            <td>{item.ReturnQty}</td>
                            <td>
                              {item.Currency[0]}
                              {item.ReturnValue}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 10,
                      alignItems: "center",
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <span style={{ fontSize: "20px" }}>Return Value :</span>
                    <span
                      style={{
                        fontSize: "30px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {orderData[0].Currency[0]}
                      {orderData[0].TotalValue}
                    </span>
                  </div>

                  <div className="flex20" style={{ gap: "20px" }}>
                    <button
                      className="confirm1"
                      onClick={() => updateTable(Id, orderId)}
                      style={{ width: "160px" }}
                    >
                      Confirm
                    </button>
                    <button
                      className="deny1"
                      onClick={() => denyTable(Id, orderId)}
                      style={{ width: "160px" }}
                    >
                      Deny
                    </button>
                  </div>
                </>
              )}
            </Panel>
          </Col>
        </Row>
      </Grid>
    </>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
