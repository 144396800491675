import * as types from "./actionTypes";
import { apiCallError, beginApiCall } from "./apiStatusActions";
import * as orderApi from "../../api/orderApi";

export function submitOrderSuccess() {
  return { type: types.SUBMIT_ORDER_SUCCESS };
}

export function changeOrderStatus(id) {
  return { type: types.CHANGE_ORDER_STATUS, id };
}

export function loadOrderSuccess(orders) {
  return { type: types.LOAD_ORDERS_SUCCESS, orders };
}

export function cancelOrder(id, auth) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return orderApi
      .cancelOrder(id, auth)
      .then((result) => {
        if (result.status !== 200) {
          dispatch(apiCallError(result));
        } else {
          dispatch(changeOrderStatus(id));
        }
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function confirmOrder(id, auth) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return orderApi
      .confirmOrder(id, auth)
      .then((result) => {
        if (result.status !== 200) {
          dispatch(apiCallError(result));
        } else {
          dispatch(changeOrderStatus(id));
        }
        return result;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadOrders(auth, user, is_draft = 0) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return orderApi
      .getOrders(auth, user, is_draft)
      .then((orders) => {
        dispatch(loadOrderSuccess(orders));
        return orders;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function orderSuccess() {
  return function (dispatch) {
    dispatch(submitOrderSuccess());
  };
}

export function loadOrderForEdit(order, id) {
  return { type: types.LOAD_ORDER_AMEND, order, id };
}

export function loadOfflineOrder(order) {
  return { type: types.LOAD_OFFLINE_ORDER, order };
}

export function submitOrder(
  cart,
  customer,
  totals,
  addresses,
  currency,
  pos,
  comments,
  po,
  shipping,
  signature,
  season,
  auth,
  user,
  currentSeason,
  atOnce,
  discount,
  images,
  isDraft,
  promo
) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return orderApi
      .submitOrder(
        customer,
        cart,
        totals,
        addresses,
        currency,
        pos,
        comments,
        po,
        shipping,
        signature,
        season,
        auth,
        user,
        currentSeason,
        atOnce,
        discount,
        images,
        isDraft,
        promo
      )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function submitOrderChanges(
         cart,
         customer,
         totals,
         currency,
         comments,
         po,
         shipping,
         auth,
         user,
         season,
         discount,
         images,
         originalOrderId,
         addresses,
         is_draft,
         atOnce,
         promo
       ) {
         return function (dispatch) {
           dispatch(beginApiCall());
           return orderApi
             .submitChanges(
               cart,
               customer,
               totals,
               currency,
               comments,
               po,
               shipping,
               auth,
               user,
               season,
               discount,
               images,
               originalOrderId,
               addresses,
               is_draft,
               atOnce,
               promo
             )
             .then((result) => {
               return result;
             })
             .catch((error) => {
               dispatch(apiCallError(error));
               throw error;
             });
         };
       }
