import React, { useEffect, useState } from "react";
import "./Colours.css";

const Colours = ({ colours, onChange, getOptionLabel, errors, setColour }) => {
  const [isInsole, setIsInsole] = useState(false);

  useEffect(() => {
    checkIfInsole();
  }, [colours]);

  const getImage = (colour, formattedLabel) => {
    return colour.media_gallery_entries && colour.media_gallery_entries[0] ? (
      <>
        <img
          width="300px"
          alt={"Product image of " + colour.name}
          src={
            "https://strivefootwear.com/pub/media/catalog/product/" +
            colour.media_gallery_entries[0].file
          }
        />
      </>
    ) : (
      <>
        <img
          width="300px"
          alt={"Product image of " + colour.name + " " + formattedLabel}
          src={"http://strivefootwear.com/pub/media/soon.jpg"}
        />
      </>
    );
  };

  function getProductType(colour) {
    if (colour && colour.custom_attributes) {
      let optionValue = colour.custom_attributes.find((obj) => {
        return obj.attribute_code === "footwear_type";
      });

      if (optionValue && optionValue.value) {
        return optionValue.value;
      }
    }
  }

  const checkIfInsole = () => {
    if (colours && colours.length === 1) {
      let productType = getProductType(colours[0]);
      if (productType && productType.toLowerCase().trim() === "insole") {
        setColour(colours[0].sku);
        setIsInsole(true);
      }
      setIsInsole(false);
    }
  };

  const getFormattedLabel = (colour) => {
    let formattedLabel;
    let optionValue = colour.custom_attributes.find((obj) => {
      return obj.attribute_code === "group_colour";
    });
    if (optionValue && optionValue.value) {
      let optionLabel = getOptionLabel("group_colour", optionValue.value);

      formattedLabel = optionLabel.toString().split(",")[0];
      return formattedLabel;
    }
  };

  return (
    <div className="colours">
      {colours.map((colour, index) => {
        return (
          <>
            <label key={index}>
              <input
                type="radio"
                name="colour"
                label={"colour-" + colour.sku}
                key={index}
                value={colour.sku}
                className="form-check-input"
                onChange={onChange}
                error={errors.colour}
                selected={isInsole}
              />
              {getImage(colour, getFormattedLabel(colour))}
              <span>{getFormattedLabel(colour)}</span>
            </label>
          </>
        );
      })}
      {errors && errors.colour ? (
        <div className="alert alert-danger">{errors.colour}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Colours;
