import React from "react";
import { Link } from "react-router-dom";
import "./ConfirmCustomerButton.css";

const ConfirmCustomerButton = ({ customer, addresses, onClick }) => {
  return customer.account !== "-" && addresses.length > 0 ? (
    <button className="btn  confirm-customer-btn" onClick={onClick}>
      Confirm Customer
    </button>
  ) : null;
};

export default ConfirmCustomerButton;
