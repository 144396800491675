import React, { useState } from "react";
import Nav from "../navigation/Index.js";
import AdminNav from "../navigation/AdminLinks.js";
import MiniCart from "../checkout/miniCart/Index.js";
import {
  emptyCart,
  editItem,
  removeItem,
} from "../../redux/actions/cartActions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "./Header.css";

function Header({
  auth,
  customer,
  cart,
  pos,
  currency,
  addresses,
  billingAddresses,
  shippingAddresses,
  totals,
  styles,
  emptyCart,
  removeItem,
  adminAuth,
}) {
  const [errors, setErrors] = useState({});

  const handleClearCart = (event) => {
    event.preventDefault();
    // if (!formIsValid()) return;
    try {
      emptyCart();
      toast.success("Cart Emptied", { autoClose: true });
    } catch (error) {
      setErrors({ onSave: error.message });
    }
  };

  const getCartForAddress = (address, type) => {
    let cartList = cart.filter(function (item) {
      return item.address.address1 === address.address1;
    });

    if (!type) {
      return cartList;
    }

    let items = cartList.filter(function (item) {
      if (item.type) {
        return item.type.toLowerCase() === type;
      }
      return false;
    });

    return items;
  };

  const handleGetStyle = (id) => {
    // if (!formIsValid()) return;
    for (let style in styles) {
      if (styles.hasOwnProperty(style)) {
        if (styles[style].id === id) {
          return styles[style];
        }
      }
    }
  };

  const handleRemoveItem = (id) => {
    removeItem(id);
  };

  const handleEditItem = (id) => {
    editItem(id);
  };

  const showCart = () => {
    return auth || adminAuth ? (
      <MiniCart
        cart={cart}
        pos={pos}
        totals={totals}
        currency={currency}
        // addresses={addresses}
        addresses={shippingAddresses}
        emptyCart={handleClearCart}
        getStyle={handleGetStyle}
        removeItem={handleRemoveItem}
        editItem={handleEditItem}
        getCartForAddress={getCartForAddress}
        adminAuth={adminAuth}
      />
    ) : null;
  };

  return (
    <>
      <div className="header">
        <img
          src="https://strivefootwear.com/media/theme_options/default/Strive-Logo-Black_Orange_1.png"
          height="40"
        />
        <h1 className="app-title">Retailer </h1>
        {showCart()}
      </div>
      <Nav customer={customer} auth={auth} />
      <AdminNav adminAuth={adminAuth} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    auth: state.auth,
    adminAuth: state.adminAuth,
    styles: state.styles,
    customer: state.customer,
    currency: state.currency,
    totals: state.totals,
    addresses: state.addresses,
    billingAddresses: state.billingAddresses,
    shippingAddresses: state.shippingAddresses,
    pos: state.pos,
  };
}

const mapDispatchToProps = {
  emptyCart,
  removeItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
