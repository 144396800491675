import * as types from "./actionTypes";
import * as authApi from "../../api/authApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function authApiSuccess(response) {
  return { type: types.AUTH_LOGIN_API_SUCCESS, response };
}
export function authLoginSuccess(response) {
  return { type: types.API_CALL_SUCCESS, response };
}
export function adminAuthApiSuccess(response) {
  return { type: types.ADMIN_AUTH_LOGIN_API_SUCCESS, response };
}

export function authLoginAuthenticated(response) {
  return { type: types.AUTH_LOGIN_AUTHENTICATED, response };
}
export function adminAuthLoginAuthenticated(response) {
  return { type: types.ADMIN_AUTH_LOGIN_AUTHENTICATED, response };
}
export function authSessionClear() {
  return { type: types.AUTH_SESSION_CLEAR };
}
export function adminAuthSessionClear() {
  return { type: types.ADMIN_AUTH_SESSION_CLEAR };
}

export function login(username, password) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .getCustomerToken(username, password)
      .then((response) => {
        dispatch(authApiSuccess(response));
        if (!response.success) {
          throw new Error("Invalid credentials supplied, please try again");
        } else {
          dispatch(authLoginAuthenticated(response));
          dispatch(authLoginSuccess(response));
        }
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
export function adminLogin(username, password) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .getAdminToken(username, password)
      .then((response) => {
        dispatch(adminAuthApiSuccess(response));
        if (!response.success) {
          throw new Error("Invalid credentials supplied, please try again");
        } else {
          console.log("adminAuthLoginAuthenticated");
          dispatch(adminAuthLoginAuthenticated(response));
        }
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function logout() {
  return function (dispatch) {
    dispatch(authSessionClear());
  };
}
export function adminLogout() {
  return function (dispatch) {
    dispatch(adminAuthSessionClear());
  };
}
