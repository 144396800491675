import React from "react";
import { Link } from "react-router-dom";

export default function ({ orders, cancel, confirm, edit }) {
  function showAccountNumber(customer) {
    return customer.AccNo ? customer.AccNo : "New";
  }

  function displayCancel(order, cancel) {
    if (
      !order.Cancelled &&
      !order.Confirmed &&
      !order.Imported &&
      order.is_draft
    ) {
      return (
        <td className="action-btn">
          <a className="btn" onClick={() => cancel(order.Id)}>
            Cancel
          </a>
        </td>
      );
    } else if (!order.Confirmed && !order.Imported && order.is_draft) {
      return <td className="cancelled-label">CANCELLED</td>;
    } else {
      return <td></td>;
    }
  }

  function displayInfo(order) {
    if (!order.Cancelled) {
      return (
        <>
          <td>
            {order.Amended && order.Amended != 0 ? "#" + order.Amended : "NO"}
          </td>
          <td>{order.Imported && order.Imported != 0 ? "YES" : "NO"}</td>
        </>
      );
    } else {
      return (
        <>
          <td></td>
          <td></td>
          <td></td>
        </>
      );
    }
  }

  function displayEdit(order) {
    return !order.Cancelled &&
      !order.Confirmed &&
      !order.Imported &&
      order.is_draft ? (
      <td class="action-btn">
        <a className="btn" onClick={() => edit(order.Id)}>
          Edit
        </a>
      </td>
    ) : (
      <>
        <td></td>
      </>
    );
  }

  function displayConfirm(order, confirm) {
    if (
      !order.Cancelled &&
      !order.Confirmed &&
      !order.Imported &&
      order.is_draft
    ) {
      return (
        <td className="action-btn">
          <a className="btn" onClick={() => confirm(order.Id)}>
            Confirm
          </a>
        </td>
      );
    } else if (!order.Cancelled && !order.Imported && order.is_draft) {
      return <td className="confirmed-label">CONFIRMED</td>;
    } else {
      return <td></td>;
    }
  }

  // function getPrice(price) {
  //   return price && price.length > 1 ? price[0] : price;
  // }

  function getSeason(order) {
    if (order && order.Season) {
      if (order.Season[1] != null) {
        return order.Season[1];
      }
      return order.Season[0];
    }
  }

  function getStyling(order) {
    let styling = "";
    if (order.Cancelled) {
      styling = "cancelled";
    }
    if (order.Confirmed) {
      styling = "confirmed";
    }
    if (order.Imported) {
      styling = "imported";
    }
    return styling;
  }

  return orders && orders.length > 0 ? (
    <table>
      <thead>
        <th>Order</th>
        <th>Season</th>
        <th>Account</th>
        <th>Store</th>
        {/* <th>Pairs</th> */}
        <th>Actions</th>
        <th></th>
        {/* <th></th> */}
        <th></th>
        <th>Amended</th>
        <th>Imported</th>
      </thead>
      <tbody>
        {orders.map((order, i) => {
          return i === 0 || (i > 0 && order.Id[0] != orders[i - 1].Id[0]) ? (
            <tr className={getStyling(order)}>
              <td>
                {order.Id.length > 0 ? "#" + order.Id[0] : "#" + order.Id}
              </td>
              <td>{getSeason(order)}</td>
              <td>{showAccountNumber(order)}</td>
              <td>{order.Store}</td>
              {/* <td>{order.Qty.length > 1 ? order.Qty[0] : order.Qty}</td> */}
              <td class="action-btn">
                <Link to={"/order/" + order.Id[0]}> View </Link>
              </td>
              {displayEdit(order)}
              {/* {displayConfirm(order, confirm)}
               */}
              {displayCancel(order, cancel)}
              {displayInfo(order)}
            </tr>
          ) : null;
        })}
      </tbody>
    </table>
  ) : null;
}
