import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function shippingAddressReducer(
  state = initialState.shippingAddresses,
  action
) {
  switch (action.type) {
    case types.LOAD_SHIPPING_ADDRESSES:
      return state;
    case types.SHIPPING_ADDRESS_SAVE_IN_STATE:
      if (state.length > 0) {
        return [...state, action.response];
      }
      return (state = [action.response]);
    case types.SHIPPING_ADDRESS_SAVE_INITIAL:
      return (state = [action.response]);
    case types.SHIPPING_ADDRESS_DELETE:
      return state.filter((val) => val !== action.response);
    case types.CANCEL_ORDER:
      return (state = []);
    // case types.SUBMIT_ORDER_SUCCESS:
    //   return (state = []);
    case types.SHIPPING_ADDRESS_EDIT:
      return state.map((item) => {
        if (item.street !== action.address.street) {
          return item;
        }
        return {
          ...item,
          ...action.address,
        };
      });
    case types.LOAD_ORDER_AMEND:
      let addresses = [
        {
          address1: action.order[0].Street1,
          address2: action.order[0].Street2,
          town: action.order[0].Town,
          county: action.order[0].County,
          postcode: action.order[0].Postcode,
          country: action.order[0].Country,
        },
      ];
      return (state = addresses);
    case types.LOAD_OFFLINE_ORDER:
      return (state = action.order.addresses);
    case types.AUTH_SESSION_CLEAR:
      return (state = []);
    default:
      return state;
  }
}
