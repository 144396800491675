import React, { useState, useEffect } from "react";
import AddressForm from "./AddressForm";
import { connect } from "react-redux";
import {
  saveAddress,
  deleteAddress,
  editAddress,
} from "../../redux/actions/addressActions";
import {
  saveBillingAddress,
  deleteBillingAddress,
  editBillingAddress,
} from "../../redux/actions/billingAddressActions";
import {
  saveShippingAddress,
  deleteShippingAddress,
  editShippingAddress,
} from "../../redux/actions/shippingAddressActions";
import { toast } from "react-toastify";
import AddressDetails from "./AddressDetails";
import AddAnotherAddressButton from "./AddAnotherAddressButton";
import "./AddressBook.css";

const AddressBook = ({
  customer,
  currency,
  addresses,
  billingAddresses,
  shippingAddresses,
  saveAddress,
  deleteAddress,
  loadAddresses,
  editAddress,
  saveBillingAddress,
  deleteBillingAddress,
  editBillingAddress,
  saveShippingAddress,
  deleteShippingAddress,
  editShippingAddress,
}) => {
  const [address, setAddress] = useState({ country: "£" });
  const [saving, setSaving] = useState(false);
  const [billingAddressSaving, setBillingAddressSaving] = useState(false);
  const [shippingAddressSaving, setShippingAddressSaving] = useState(false);

  const [errors, setErrors] = useState({});
  const [shippingErrors, setShippingErrors] = useState({});

  const [addressFormOpen, setAddressFormOpen] = useState(true);
  const [billingaddressFormOpen, setBillingAddressFormOpen] = useState(true);
  const [shippingaddressFormOpen, setShippingAddressFormOpen] = useState(true);

  const [addressFormEdit, setAddressFormEdit] = useState(false);
  const [billingAddress, setBillingAddress] = useState({ country: "£" });
  const [shippingAddress, setShippingAddress] = useState({ country: "£" });

  const [hideBillingDivAfterSubmit, setHideBillingDivAfterSubmit] = useState(
    true
  );
  const [hideShippingDivAfterSubmit, setHideshippingDivAfterSubmit] = useState(
    true
  );

  useEffect(() => {
    if (customer.length === 0) {
      loadAddresses().catch((error) => {
        alert("Loading addresses failed" + error);
      });
    } else {
      setAddressFormOpen(false);
    }
    switch (currency) {
      case "£":
        setAddress({ country: "United Kingdom" });
        break;
      case "€":
        setAddress({ country: "Ireland" });
        break;
      case "$":
        setAddress({ country: "United States" });
        break;
      default:
        setAddress({ country: "United Kingdom" });
    }
  }, []);

  function formIsValid() {
    const { country, address1, town, county, postcode } = address;
    const errors = {};

    if (!country || country === null) errors.country = "Country is required.";
    if (!address1 || address1 === null)
      errors.address1 = "Address 1 is required";
    if (!town || town === null) errors.town = "Town is required";
    if (!county || county === null) errors.county = "County is required";
    if (!postcode || postcode === null)
      errors.postcode = "Postcode is required";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function billingFormIsValid() {
    const { country, address1, town, county, postcode } = billingAddress;
    const errors = {};

    if (!country || country === null) errors.country = "Country is required.";
    if (!address1 || address1 === null)
      errors.address1 = "Address 1 is required";
    if (!town || town === null) errors.town = "Town is required";
    if (!county || county === null) errors.county = "County is required";
    if (!postcode || postcode === null)
      errors.postcode = "Postcode is required";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function shippingFormIsValid() {
    const { country, address1, town, county, postcode } = shippingAddress;
    const errors = {};

    if (!country || country === null) errors.country = "Country is required.";
    if (!address1 || address1 === null)
      errors.address1 = "Address 1 is required";
    if (!town || town === null) errors.town = "Town is required";
    if (!county || county === null) errors.county = "County is required";
    if (!postcode || postcode === null)
      errors.postcode = "Postcode is required";

    setShippingErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleChange(event) {
    const { name, value, checked } = event.target;
    // console.log(name, "-------");
    // if (name === "billing") {
    //   setShippingAddressFormOpen(!shippingaddressFormOpen);
    //   setAddress((prev) => ({
    //     ...prev,
    //     [name]: checked,
    //   }));
    // } else {
    setAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
    // }
  }

  const handleSave = (event, isEdit = false) => {
    event.preventDefault();
    if (!formIsValid()) return;
    if (isEdit) {
      edit();
    } else {
      create();
    }
  };

  const create = () => {
    setSaving(true);
    try {
      saveAddress(address);
      toast.success("Address has been added to address book", {
        autoClose: true,
      });
      setAddressFormOpen(false);
      setSaving(false);
      setAddress({});
    } catch (error) {
      setSaving(false);
      setErrors({ onSave: error.message });
    }
  };

  const edit = () => {
    setSaving(true);
    try {
      editAddress(address);
      toast.success("Address has edited", {
        autoClose: true,
      });
      setAddressFormOpen(false);
      setSaving(false);
      setAddress({});
    } catch (error) {
      setSaving(false);
      setErrors({ onSave: error.message });
    }
  };

  const handleDelete = (event, address) => {
    event.preventDefault();
    setSaving(true);
    try {
      deleteAddress(address);
      toast.success("Address has deleted", {
        autoClose: true,
      });
      setSaving(false);
    } catch (error) {
      setSaving(false);
      setErrors({ onSave: error.message });
    }
  };

  //billing and shipping addresses functions

  function handleBillingChange(event) {
    const { name, value, checked } = event.target;

    setBillingAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  function handleShippingChange(event) {
    const { name, value, checked } = event.target;
    // console.log(name, "-------");
    if (name === "billing") {
      // if (checked) {
      //   setShippingAddressFormOpen(true);
      // } else {
      //   setShippingAddressFormOpen(false);
      // }
      setShippingAddressFormOpen(!shippingaddressFormOpen);
      if (!shippingaddressFormOpen) {
        setShippingAddress({
          country: " ",
          address1: " ",
          address2: " ",
          country: " ",
          county: " ",
          town: " ",
        });
      } else {
        setShippingAddress(billingAddress);
      }
      setShippingAddress((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setShippingAddress((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }

  const handleShippingSave = (event, isEdit = false) => {
    event.preventDefault();
    if (hideBillingDivAfterSubmit) {
      if (!billingFormIsValid()) return;
    }

    // if(!hideShippingDivAfterSubmit){
    if (!shippingFormIsValid()) return;
    // }
    if (isEdit) {
      edit();
    } else {
      createBillingAddress();
    }
  };

  const createBillingAddress = () => {

    setBillingAddressSaving(true);
    try {
      saveBillingAddress(billingAddress);
      saveShippingAddress(shippingAddress);

      toast.success("Address has been added to address book", {
        autoClose: true,
      });
      // setBillingAddressFormOpen(false);
      setBillingAddressSaving(false);
      setBillingAddress({});

      // setShippingAddressFormOpen(false);
      setShippingAddressSaving(false);
      setShippingAddress({});
      setHideBillingDivAfterSubmit(false);
      setHideshippingDivAfterSubmit(false);
    } catch (error) {
      setBillingAddressSaving(false);
      setShippingAddressSaving(false);

      setErrors({ onSave: error.message });
    }
  };
  const handleBillingDelete = (event, address) => {
    event.preventDefault();
    setBillingAddressSaving(true);
    try {
      deleteBillingAddress(address);
      toast.success("Address has deleted", {
        autoClose: true,
      });
      setBillingAddressSaving(false);
    } catch (error) {
      setBillingAddressSaving(false);
      setErrors({ onSave: error.message });
    }
  };
  const handleShippingDelete = (event, address) => {
    event.preventDefault();
    setShippingAddressSaving(true);
    try {
      deleteShippingAddress(address);
      toast.success("Shipping Address has deleted", {
        autoClose: true,
      });
      setShippingAddressSaving(false);
    } catch (error) {
      setShippingAddressSaving(false);
      setErrors({ onSave: error.message });
    }
  };

  //end

  const closeAddressForm = () => {
    setShippingAddressFormOpen(false);
    setHideshippingDivAfterSubmit(false);
  };

  const reOpenAddressForm = (event, addressToEdit = false) => {
    event.preventDefault();
    // setAddressFormOpen(true);
    setShippingAddressFormOpen(true);
    setHideshippingDivAfterSubmit(true);
    if (addressToEdit) {
      preFillAddressForm(addressToEdit);
    } else {
      setAddressFormEdit(false);
    }
  };

  const preFillAddressForm = (address) => {
    setAddress(address);
    setAddressFormEdit(true);
  };

  return currency && customer && customer.account !== "-" ? (
    <>
      {hideBillingDivAfterSubmit && (
        <div className="address-book">
          <h3>Billing Address</h3>
          <AddressForm
            onChange={handleBillingChange}
            onSave={handleSave}
            customer={customer}
            errors={errors}
            saving={setBillingAddressSaving}
            address={billingAddress}
            addressFormOpen={billingaddressFormOpen}
            addressFormEdit={addressFormEdit}
            closeAddressForm={closeAddressForm}
            buttonToShow={false}
          />
        </div>
      )}
      {hideShippingDivAfterSubmit && (
        <div className="address-book">
          <h3>Shipping Address</h3>
          <AddressForm
            onChange={handleShippingChange}
            onSave={handleShippingSave}
            customer={customer}
            errors={shippingErrors}
            saving={shippingAddressSaving}
            address={shippingAddress}
            addressFormOpen={shippingaddressFormOpen}
            addressFormEdit={addressFormEdit}
            closeAddressForm={closeAddressForm}
            buttonToShow={true}
          />
        </div>
      )}
      <div className="address-book">
        {billingAddresses.length != 0 && <h3>Billing Address</h3>}
        <AddressDetails
          addresses={billingAddresses}
          handleDelete={handleBillingDelete}
          handleEdit={reOpenAddressForm}
          customer={customer}
          currency={currency}
        />
        {shippingAddresses.length != 0 && <h3>Shipping Address</h3>}
        <AddressDetails
          addresses={shippingAddresses}
          handleDelete={handleShippingDelete}
          handleEdit={reOpenAddressForm}
          customer={customer}
          currency={currency}
        />
        <AddAnotherAddressButton
          className="btn btn-primary"
          addressFormOpen={addressFormOpen}
          reOpenAddressForm={reOpenAddressForm}
        />
      </div>
      {/* <div className="address-book">
        <h3>Address Book</h3>
        <AddressForm
          onChange={handleChange}
          onSave={handleSave}
          customer={customer}
          errors={errors}
          saving={saving}
          address={address}
          addressFormOpen={addressFormOpen}
          addressFormEdit={addressFormEdit}
          closeAddressForm={closeAddressForm}
          buttonToShow={false}
        />
        <AddressDetails
          addresses={addresses}
          handleDelete={handleDelete}
          handleEdit={reOpenAddressForm}
          customer={customer}
          currency={currency}
        />
        <AddAnotherAddressButton
          className="btn btn-primary"
          addressFormOpen={addressFormOpen}
          reOpenAddressForm={reOpenAddressForm}
        />
      </div> */}
    </>
  ) : null;
};

function mapStateToProps(state) {
  // console.log("state", state);
  return {
    addresses: state.addresses,
    billingAddresses: state.billingAddresses,
    shippingAddresses: state.shippingAddresses,
  };
}

const mapDispatchToProps = {
  saveAddress,
  deleteAddress,
  editAddress,
  saveBillingAddress,
  deleteBillingAddress,
  editBillingAddress,
  saveShippingAddress,
  deleteShippingAddress,
  editShippingAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressBook);
