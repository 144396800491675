import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function styleReducer(state = initialState.styles, action) {
  switch (action.type) {
    case types.LOAD_STYLES_SUCCESS:
      return action.styles;
    case types.REQUEST_UPDATE:
      return [];
    default:
      return state;
  }
}
