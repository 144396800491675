import React from "react";
import TextInput from "../shared/TextInput";

export default function ({ search, onChange }) {
  return (
    <form class="order-search-form">
      <TextInput
        name="search"
        label="Search"
        value={search}
        placeholder="Enter term"
        onChange={onChange}
        className="checkbox"
      />
    </form>
  );
}
