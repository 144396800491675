import * as types from "./actionTypes";

export function authApiSuccess(response) {
  return { type: types.AUTH_LOGIN_API_SUCCESS, response };
}

export function authLoginAuthenticated(response) {
  return { type: types.AUTH_LOGIN_AUTHENTICATED, response };
}

export function authSessionClear() {
  return { type: types.AUTH_SESSION_CLEAR };
}

export function addToCart(response) {
  return { type: types.ADD_TO_CART, response };
}

export function emptyCart() {
  return { type: types.EMPTY_CART };
}

export function removeItem(item) {
  return { type: types.REMOVE_ITEM, item };
}

export function editItem() {
  return { type: types.EDIT_ITEM };
}

export function cancelOrder() {
  return { type: types.CANCEL_ORDER };
}

export function logout() {
  return function (dispatch) {
    dispatch(authSessionClear());
  };
}
