import React, { useState } from "react";
import { connect } from "react-redux";
import { adminLogin } from "../../../redux/actions/authActions";
import { toast } from "react-toastify";
import LoginForm from "../../auth/LoginForm";
import { Redirect } from "react-router-dom";

export default function () {
  return (
    <div>
      <h1>Home Page</h1>
      <h3>You are Logged In as Admin</h3>
    </div>
  );
}
