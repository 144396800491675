import React from "react";

const Checkbox = ({ name, label, onChange, placeholder, value, error, disabled }) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " " + "has-error ";
  }

  return (
    <div className={wrapperClass + " " + name.replace(" ", "-").toLowerCase()}>
      <label htmlFor={name}>{label}
        <div className="field">
          <input
            type="checkbox"
            name={name}
            className="form-control"
            checked={value}
            onChange={onChange}
            disabled={disabled}
          />
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
