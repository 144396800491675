import React, { useState } from "react";
import "./index.css";
import { getOrderBySearch } from "../../../api/orderApi";
import { deleteOrderById } from "../../../api/orderApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  loadOrders,
  cancelOrder,
  confirmOrder,
  loadOrderForEdit,
} from "../../../redux/actions/orderActions";
import { loadOrderAmends } from "../../../redux/actions/orderAmendActions";
import { loadSeasons } from "../../../redux/actions/seasonActions";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";

function Orders({ adminAuth, loadOrderForEdit, loadOrderAmends, loadSeasons }) {
  const [orderId, setOrderId] = useState();
  const [searchField, setSearchField] = useState();

  const [order, setOrder] = useState();
  const [orderWithStyles, setOrderWithStyles] = useState();

  let history = useHistory();

  function handleChange(event) {
    const { name, value } = event.target;
    console.log(value);
    setSearchField(value);
  }
  const isNumeric = (num) => /^-?[0-9]+(?:\.[0-9]+)?$/.test(num + "");
  function handleSearch() {
    // if (!isNumeric(orderId)) {
    //   toast.error("Please Enter Valid Order Number", { autoClose: true });
    //   return;
    // }
    getOrderBySearch(searchField, adminAuth)
      .then((response) => {
        console.log('response',response)
        if (response.length == 0) {
          // setOrder([]);
          // setOrderWithStyles([]);
          toast.warn("Order Doesn't Exist", { autoClose: true });
          return;
        }
        if (response.length > 0) {
          if (response[0].Amended) {
            toast.error(
              "Order Doesn't Exist because its amended and new id is " +
                response[0].Amended,
              { autoClose: true }
            );
            return;
          }
        }
        setOrder(response[0]);
        const id = response[0].Id.length > 0 ? response[0].Id[0] :  response[0].Id;
        setOrderId(id);
        setOrderWithStyles(response);
      })
      .catch((e) => {
        console.log("catch", e);
        toast.error("An error Occured, Try Again", { autoClose: true });
        setOrder([]);
        setOrderWithStyles([]);

        // setOnline(false);
      });
  }
  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteOrder(orderId),
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };
  function showAccountNumber(customer) {
    return customer.AccNo ? customer.AccNo : "New";
  }
  function getSeason(order) {
    if (order && order.Season) {
      if (order.Season[1] != null) {
        return order.Season[1];
      }
      return order.Season[0];
    }
  }
  const handleEdit = (id) => {
    let result = loadOrderForEdit(orderWithStyles, id);
    if (result) {
      toast.success("Order Loaded for Editing", { autoClose: true });
      history.push("/admins/customer");
    }
  };
  function deleteOrder(id) {
    deleteOrderById(id, adminAuth)
      .then((response) => {
        toast.success("Order Deleted", { autoClose: true });
        history.push("/admins/orders");
      })
      .catch((error) => {
        toast.error(
          "Sorry an error occured. cant Delete Order at this time " + error,
          { autoClose: true }
        );
      });
  }

  function getOrderStatus() {
    if (order.Cancelled) {
      return "Cancelled";
    } else if (order.is_draft) {
      return "Draft";
    } else {
      return "Confirmed";
    }
  }
  function displayActionBtns(order) {
    return !order.Cancelled &&
      !order.Confirmed &&
      !order.Imported &&
      order.is_draft ? (
      <>
        <td className="action-btn">
          <Link to={"/admins/order/" + orderId}> View </Link>
          <a className="btn" onClick={() => handleEdit(order.Id)}>
            Edit
          </a>
          <a className="btn red" onClick={() => handleDelete(orderId)}>
            Delete
          </a>
        </td>
      </>
    ) : (
      <>
        <td className="action-btn">
          <Link to={"/admins/order/" + orderId}> View </Link>
          <a className="btn red" onClick={() => handleDelete(orderId)}>
            Delete
          </a>
        </td>
      </>
    );
  }
  return (
    <div>
      <h1>Orders Page</h1>
      <div className="form-group">
        <label htmlFor="order_no">Search Order #</label>
        <div className="field">
          <input
            type="text"
            name="order_no"
            className="form-control"
            placeholder="Order Number"
            value={searchField}
            onChange={handleChange}
          />
        </div>
        <a
          onClick={handleSearch}
          //   disabled={saving}
          className="btn btn-primary btn-next btn-link-custom_"
        >
          Search
        </a>
      </div>
      <div>
        {order ? (
          <table className="table">
            <thead>
              <tr>
                <th>Order</th>
                <th>Season</th>
                <th>Account</th>
                <th>Store</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {order.Id.length > 0 ? "#" + order.Id[0] : "#" + order.Id}
                </td>
                <td>{getSeason(order)}</td>
                <td>{showAccountNumber(order)}</td>
                <td>{order.Store}</td>
                <td>{getOrderStatus()}</td>

                {displayActionBtns(order)}
              </tr>
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    orders: state.orders,
    seasons: state.seasons,
    auth: state.auth,
    user: state.user,
    orderAmends: state.orderAmends,
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {
  loadOrders,
  loadOrderAmends,
  loadSeasons,
  cancelOrder,
  confirmOrder,
  loadOrderForEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
