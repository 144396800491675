import styleReducer from "./stylesReducer";
import apiStatusReducer from "./apiStatusReducer";
import authReducer from "./authReducer";
import cartReducer from "./cartReducer";
import customerReducer from "./customerReducer";
import addressReducer from "./addressReducer";
import shippingAddressReducer from "./shippingAddressReducer";
import billingAddressReducer from "./billingAddressReducer";
import totalReducer from "./totalReducer";
import currencyReducer from "./currencyReducer";
import optionReducer from "./optionReducer";
import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import seasonReducer from "./seasonReducer";
import orderReducer from "./orderReducer";
import accountsReducer from "./accountsReducer";
import posReducer from "./posReducer";
import reportReducer from "./reportReducer";
import userReducer from "./userReducer";
import stockReducer from "./stockReducer";
import amendReducer from "./amendReducer";
import orderAmendReducer from "./orderAmendReducer";
import currentSeasonReducer from "./currentSeasonReducer";
import confirmReducer from "./confirmReducer";
import adminAuthReducer from "./adminAuthReducer";
import userDataReducer from "./userDataReducer";

const persistConfig = {
  key: "root",
  storage: storage,
};

const rootReducer = persistCombineReducers(persistConfig, {
  styles: styleReducer,
  api: apiStatusReducer,
  auth: authReducer,
  userData: userDataReducer,
  adminAuth: adminAuthReducer,
  cart: cartReducer,
  customer: customerReducer,
  addresses: addressReducer,
  shippingAddresses: shippingAddressReducer,
  billingAddresses: billingAddressReducer,
  totals: totalReducer,
  currency: currencyReducer,
  options: optionReducer,
  seasons: seasonReducer,
  orders: orderReducer,
  accounts: accountsReducer,
  pos: posReducer,
  report: reportReducer,
  user: userReducer,
  stock: stockReducer,
  amend: amendReducer,
  orderAmends: orderAmendReducer,
  currentSeason: currentSeasonReducer,
  confirm: confirmReducer,
});

export default rootReducer;
