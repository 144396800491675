import React, { useEffect, useState, Fragment, useRef } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import "./index.css";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { FlexboxGrid, Loader } from "rsuite";
import { insertPlanner, getPlannerById } from "../../../api/orderApi";
import ReactExport from "react-export-excel";
import {
  FaCaretLeft,
  FaCaretRight,
  FaExclamationTriangle,
} from "react-icons/fa";
import useStickyHeader from "./useStickyHeader.js";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ViewPlanner({ adminAuth }) {
  const [planner, setPlanner] = useState();
  const [plannerData, setPlannerData] = useState([]);
  const [orignalSheet, setOrignalSheet] = useState(false);
  const [allocate, setAllocate] = useState([false, false]);
  const [columns, setColumns] = useState([]);
  const [excelColumns, setExcelColumns] = useState();
  const [excelData, setExcelData] = useState();
  const [stockCheck, setStockCheck] = useState([]);
  const [errors, setErrors] = useState([]);
  const [currentErrorIndex, setcurrentErrorIndex] = useState(-1);
  const itemsRef = useRef([]);
  const [tableWidth, setTableWidth] = useState(0);
  const { tableRef, isSticky } = useStickyHeader();
  const { Id } = useParams();

  useEffect(() => {
    console.log("tableWidth 1", tableWidth);
    if (plannerData.length > 0) {
      setTableWidth(tableRef.current.offsetWidth);
    }
    console.log("errors", errors);
  }, [plannerData]);
  useEffect(() => {
    if (columns.length === 0) {
      console.log("initiate call");
      getPlannerById(Id, adminAuth)
        .then((response) => {
          let data = response[0];
          let clms = JSON.parse(data.Columns);
          let excelCol = [];
          JSON.parse(data.Columns).map((col, key) => {
            if (key > 8) {
              excelCol.push(col);
            }
          });
          let tempPlannerData = JSON.parse(data.PlannerData);

          let tempData = JSON.parse(
            JSON.stringify(tempPlannerData.plannerData)
          );
          let tempExcelData = [];
          tempData.map((row, key) => {
            let tempData1 = row;
            let count = 0;
            tempData1["allocate"] = tempPlannerData.allocate[key];
            tempData1["due_date"] = [];
            excelCol.map((c) => {
              if (tempPlannerData.stockCheck[key][c]) {
                tempData1["due_date"][count] = c;
                count++;
              }
            });
            tempData1["due_date"] = tempData1["due_date"].toString();
            tempExcelData.push(tempData1);
          });
          setColumns(clms);
          setExcelData(tempExcelData);
          setPlanner(data.PlannerName);
          setExcelColumns(excelCol);
          setAllocate(tempPlannerData.allocate);
          setStockCheck(tempPlannerData.stockCheck);
          setPlannerData(tempPlannerData.plannerData);
          setOrignalSheet(tempPlannerData.orignal_sheet);
          updateErrors(tempPlannerData.plannerData);
        })
        .catch((e) => {
          console.log("catch", e);
          setPlannerData([]);
          setAllocate([]);
          setStockCheck([]);
        });
    } else {
      console.log("here i am");
    }
  }, []);

  const getFileName = () => {
    let string = planner.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
    let fileName = string.replaceAll(" ", "_").toLowerCase();
    if (!fileName) {
      fileName = "Download";
    }
    return fileName;
  };
  const styledMultiDataSet = [
    {
      columns: [
        {
          value: "Headings",
          widthPx: 160,
          style: { font: { sz: "24", bold: true } },
        },
        {
          value: "Text Style",
          widthPx: 180,
          style: { font: { sz: "24", bold: true } },
        },
        {
          value: "Colors",
          style: { font: { sz: "24", bold: true } }, // if no width set, default excel column width will be used ( 64px )
        },
      ],
      data: [
        [
          { value: "H1", style: { font: { sz: "24", bold: true } } },
          { value: "Bold", style: { font: { bold: true } } },
          {
            value: "Red",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
        ],
        [
          { value: "H2", style: { font: { sz: "18", bold: true } } },
          { value: "underline", style: { font: { underline: true } } },
          {
            value: "Blue",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FF0000FF" } },
            },
          },
        ],
        [
          { value: "H3", style: { font: { sz: "14", bold: true } } },
          { value: "italic", style: { font: { italic: true } } },
          {
            value: "Green",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FF00FF00" } },
            },
          },
        ],
        [
          { value: "H4", style: { font: { sz: "12", bold: true } } },
          { value: "strike", style: { font: { strike: true } } },
          {
            value: "Orange",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFF86B00" } },
            },
          },
        ],
        [
          { value: "H5", style: { font: { sz: "10.5", bold: true } } },
          { value: "outline", style: { font: { outline: true } } },
          {
            value: "Yellow",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } },
            },
          },
        ],
        [
          { value: "H6", style: { font: { sz: "7.5", bold: true } } },
          { value: "shadow", style: { font: { shadow: true } } },
          {
            value: "Light Blue",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
            },
          },
        ],
      ],
    },
  ];
  function updateErrors(newPlannerData) {
    let newErrors = [];
    newPlannerData.map((row, i) => {
      if (newPlannerData[i]["class"].indexOf("bg-red") >= 0) {
        newErrors.push(i);
      }
    });
    newErrors.sort((a, b) => (a > b ? 1 : -1));
    setErrors(newErrors);
  }
  const goToError = (dir) => {
    if (errors.length == 0) {
      toast.error("There are no Errors on sheet", {
        autoClose: true,
      });
      return;
    }
    errors.map((ele, index) => {
      itemsRef.current[errors[index]].classList.remove("current_error");
    });
    let newCurrentErrorIndex = currentErrorIndex;
    let errorCount = errors.length - 1;
    if (dir == 0) {
      if (newCurrentErrorIndex == -1 || newCurrentErrorIndex == 0) {
        newCurrentErrorIndex = errorCount;
      } else {
        newCurrentErrorIndex -= 1;
      }
    } else {
      if (newCurrentErrorIndex == errorCount) {
        newCurrentErrorIndex = 0;
      } else {
        newCurrentErrorIndex += 1;
      }
    }
    console.log("error index", newCurrentErrorIndex);
    itemsRef.current[errors[newCurrentErrorIndex]].classList.add(
      "current_error"
    );
    itemsRef.current[errors[newCurrentErrorIndex]].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    setcurrentErrorIndex(newCurrentErrorIndex);
  };
  return (
    <>
      <h2 style={{ textAlign: "center" }}>{planner}</h2>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          {plannerData && plannerData.length > 0 ? (
            <div className="download_btn" style={{ marginBottom: "10px" }}>
              <ExcelFile
                filename={getFileName()}
                element={<button>Export Data</button>}
              >
                <ExcelSheet data={excelData} name="Order">
                  <ExcelColumn label="Account" value="account" />
                  <ExcelColumn label="SO Number" value="so_number" />
                  <ExcelColumn label="Order Ref" value="order_ref" />
                  <ExcelColumn label="SKU" value="sku" />
                  <ExcelColumn label="Description" value="desc" />
                  <ExcelColumn label="Partial Order" value="partial_order" />
                  <ExcelColumn label="Priority" value="priority" />
                  <ExcelColumn label="Req Ship" value="req_ship" />
                  <ExcelColumn label="Qty" value="qty" />
                  <ExcelColumn label="Due Date" value="due_date" />
                  {excelColumns.map((clm, key) => (
                    <ExcelColumn key={"excel_" + key} label={clm} value={clm} />
                  ))}
                  <ExcelColumn label="Allocate" value="allocate" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {plannerData ? (
        <FlexboxGrid
          justify="end"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <FlexboxGrid.Item colspan={6}>
            <FaExclamationTriangle
              style={{ marginRight: "3px" }}
              className="custom_icon"
            />
            <span className="error_text">{errors.length}</span>
            <FaCaretLeft className="custom_icon" onClick={() => goToError(0)} />
            <span className="error_text" style={{ color: "black" }}>
              {currentErrorIndex + 1}
            </span>
            <FaCaretRight
              className="custom_icon"
              onClick={() => goToError(1)}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      ) : null}
      {plannerData && plannerData.length > 0 ? (
        <>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={24}>
              {isSticky && (
                <>
                  <div
                    className="custom_div sticky"
                    style={{
                      width: tableWidth,
                    }}
                  >
                    <FaExclamationTriangle
                      style={{ marginRight: "3px" }}
                      className="custom_icon"
                    />
                    <span className="error_text">{errors.length}</span>
                    <FaCaretLeft
                      className="custom_icon"
                      onClick={() => goToError(0)}
                    />
                    <span className="error_text" style={{ color: "black" }}>
                      {currentErrorIndex + 1}
                    </span>
                    <FaCaretRight
                      className="custom_icon"
                      onClick={() => goToError(1)}
                    />
                  </div>
                  <table
                    className="sticky table custom_table"
                    style={{
                      width: tableWidth,
                    }}
                  >
                    <thead>
                      <tr>
                        {columns.map((column, index) => (
                          <th key={"header_" + index}>{column}</th>
                        ))}
                        <th>Allocate</th>
                      </tr>
                    </thead>
                  </table>
                </>
              )}
              <table ref={tableRef} className="table custom_table">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={"header_" + index}>{column}</th>
                    ))}
                    <th>Allocate</th>
                  </tr>
                </thead>
                <tbody>
                  {plannerData.map((row, index) => {
                    return (
                      <Fragment key={"fragment_" + index}>
                        <tr
                          key={"plannerData_" + index}
                          className={row["class"]}
                          ref={(el) => (itemsRef.current[index] = el)}
                        >
                          <td>{row["account"]}</td>
                          <td>{row["so_number"]}</td>
                          <td>{row["order_ref"]}</td>
                          <td>{row["sku"]}</td>
                          <td>{row["desc"]}</td>
                          <td style={{ textAlign: "center" }}>
                            {row["partial_order"]}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {row["priority"]}
                          </td>
                          <td>{row["req_ship"]}</td>
                          <td style={{ textAlign: "center" }}>
                            <b>{row["qty"]}</b>
                          </td>
                          {columns.map((column, key) => {
                            return key > 8 ? (
                              <td key={"dynamic_" + key}>
                                <input
                                  type="checkbox"
                                  checked={stockCheck[index][column]}
                                  disabled
                                />{" "}
                                <b
                                  className={
                                    stockCheck[index][column]
                                      ? "green-color"
                                      : null
                                  }
                                >
                                  {row[column]}
                                </b>
                              </td>
                            ) : null;
                          })}
                          <td>
                            <input
                              type="checkbox"
                              checked={allocate[index]}
                              disabled
                            />
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </>
      ) : (
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={24}>
            <Loader size="md" center content="loading" />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPlanner);
