import React, { useEffect, useRef } from "react";
import { FaWindowClose } from "react-icons/fa";
import styles from "./modal.module.css";
import "./Modal.css";

const Modal = ({ modalStyle, children, show, onClose, backdropStyle }) => {
  const modalRef = useRef(null);
  useEffect(() => {
    if (show) {
      modalRef.current.classList.add(styles.visible);
    } else {
      modalRef.current.classList.remove(styles.visible);
    }
  }, [show]);
  return (
    <>
      <div
        ref={modalRef}
        style={backdropStyle}
        className={`${styles.modal__wrap}`}
      >
        <div style={modalStyle} className={styles.modal}>
          {/* <CloseIcon
            height="20px"
            width="20px"
            className={styles.close__icon}
          /> */}
          <FaWindowClose onClick={onClose} className={styles.close__btn} />
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
