import React, { useEffect, useState } from "react";
import "./Index.css";
import { connect } from "react-redux";
import List from "./List";
import { loadStock } from "../../redux/actions/stockActions";
import Spinner from "../shared/Spinner";
import Search from "../orders/Search";
import Warehouse from "./Warehouse";
import Size from "./Size";
import Download from "./Download";
import Available from "./Available";

function Index({ loadStock, stock, auth }) {
  const [apiError, setApiError] = useState(false);
  const [stockList, setStockList] = useState();
  const [search, setSearch] = useState("");
  const [warehouse, setWarehouse] = useState("MAIN");
  const [available, setAvailable] = useState(false);
  const [size, setSize] = useState(3);
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    if (!stock || stock.length === 0) {
      loadStock(auth, "MAIN")
        .then((result) => {
          setStockList(result);
        })
        .catch((error) => {
          setApiError(true);
          console.log("Loading stock failed" + error);
        });
    } else {
      setStockList(stock);
    }
    document.body.className = "stock";
    return () => {
      document.body.className = "";
    };
  }, []);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name.indexOf("search") !== -1) {
      if (!value || value === "") {
        setStockList(stock);
        setSearch("");
      } else {
        setSearch(value);
        searchStock(value);
      }
    } else if (name.indexOf("warehouse") !== -1) {
      setWarehouse(value);
      loadStock(auth, value)
        .then((result) => {
          setSearch("");
          setStockList(result);
        })
        .catch((error) => {
          setApiError(true);
          console.log("Loading stock failed" + error);
        });
    } else if (name.indexOf("size") !== -1) {
      setSize(value);
      searchForSize(value);
    } else if (name.indexOf("available") !== -1) {
      setAvailable(value);
      searchForAvailable(value);
    }
  };

  const searchStock = (value) => {
    let result;
    if (value == null || value == undefined || value == "") {
      result = stock;
    } else {
      result = stock.filter((item) => {
        return (
          item.sku.toLowerCase().includes(value.toLowerCase()) ||
          item.description.toLowerCase().includes(value.toLowerCase())
        );
      });
    }
    if (
      result &&
      result !== undefined &&
      result !== null &&
      result.length > 0
    ) {
      setStockList(result);
    } else {
      setStockList(["empty"]);
    }
  };

  const searchForSize = (value) => {
    let result;
    if (value == null || value == undefined || value == "") {
      result = stock;
    } else {
      result = stock.filter((item) => {
        let splitSku = item.sku.split("-");
        let sku = splitSku[1];
        return parseFloat(sku) === parseFloat(value);
      });
    }
    if (result && result.length > 0) {
      setStockList(result);
    } else {
      setStockList(["empty"]);
    }
  };

  const searchForAvailable = (value) => {
    let result;
    if (value === "false") {
      result = stock;
    } else {
      if (value == null || value == undefined || value == "") {
        result = stock;
      } else {
        result = stock.filter((item) => {
          let available = item.instock <= 0 ? 0 : item.instock - item.saleorder;
          return available > 0;
        });
      }
    }
    if (result && result.length > 0) {
      setStockList(result);
    } else {
      setStockList(["empty"]);
    }
  };

  const handleClick = (event) => {
    if (showDetail) {
      setShowDetail(false);
    } else {
      setShowDetail(true);
    }
  };

  const showStock = () => {
    return apiError ? (
      <div className="content stock">
        <p class="network-banner">
          Cannot load stock at this time, there is no connection to the server
        </p>
      </div>
    ) : (
      <div className="content stock">
        <h1>{warehouse === "MAIN" ? "UK" : "US"} Warehouse Stock</h1>
        <div className="download">
          <Download data={stockList}></Download>
        </div>
        <Warehouse warehouse={warehouse} onChange={handleChange}></Warehouse>
        <Size size={size} onChange={handleChange}></Size>
        <Available available={available} onChange={handleChange}></Available>
        <Search search={search} onChange={handleChange}></Search>
        <List
          stockList={stockList}
          onClick={handleClick}
          showDetail={showDetail}
        ></List>
      </div>
    );
  };

  return !stockList || stockList.length === 0 || apiError ? (
    <Spinner />
  ) : (
    showStock()
  );
}

function mapStateToProps(state) {
  return {
    stock: state.stock,
    auth: state.auth,
  };
}

const mapDispatchToProps = {
  loadStock,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
