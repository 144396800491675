import React, { Fragment, useEffect, useState, useRef } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import "./index.css";
import { connect } from "react-redux";
import {
  FaCaretLeft,
  FaCaretRight,
  FaExclamationTriangle,
} from "react-icons/fa";
// import { Button, Form, Input, Jumbotron } from "reactstrap";
// import { submitOrder } from "../../api/orderApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { FlexboxGrid, Input, Divider, Button, Loader } from "rsuite";
import { insertPlanner } from "../../../api/orderApi";
import useStickyHeader from "./useStickyHeader.js";

function OrderPlanner({ adminAuth }) {
  const [planner, setPlanner] = useState();
  const [orders, setOrders] = useState([]);
  const [plannerData, setPlannerData] = useState([]);
  const [orignalPlannerData, setOrignalPlannerData] = useState();

  const [allocate, setAllocate] = useState([false, false]);
  const [deliveries, setDeliveries] = useState([]);
  const [columns, setColumns] = useState();
  const [stockCheck, setStockCheck] = useState([]);
  const [orignalSheet, setOrignalSheet] = useState(false);
  const checkboxes = useRef({});
  const [errors, setErrors] = useState([]);
  const [currentErrorIndex, setcurrentErrorIndex] = useState(-1);
  const { tableRef, isSticky } = useStickyHeader();
  const [tableWidth, setTableWidth] = useState(0);

  const itemsRef = useRef([]);
  let history = useHistory();

  useEffect(() => {
    console.log("tableWidth 1", tableWidth);
    if (plannerData.length > 0) {
      setTableWidth(tableRef.current.offsetWidth);
    }
    console.log("errors", errors);
  }, [plannerData]);
  useEffect(() => {
    console.log("currentErrorIndex", currentErrorIndex);
  }, [currentErrorIndex]);
  const deliveryFileHandler = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        console.log("resp", resp);
        const results = resp.rows.filter((element) => {
          // console.log("element", element);
          if (Object.keys(element).length !== 0) {
            return true;
          }

          return false;
        });
        setDeliveries(results);
      }
    });
  };
  const orderFileHandler = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      console.log("render");
      if (err) {
        console.log("error");
        console.log(err);
      } else {
        // console.log("resp", resp);
        setOrders(resp.rows);
      }
    });
  };
  const ExcelDateToJSDate = (date) => {
    if (date == "Totals") {
      return date;
    }
    // console.log('date', date);
    let converted_date = new Date(Math.round((date - 25569) * 864e5));
    converted_date = String(converted_date).slice(4, 15);
    date = converted_date.split(" ");
    let day = date[1];
    let month = date[0];
    month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1;
    if (month.toString().length <= 1) month = "0" + month;
    let year = date[2];
    return String(day + "/" + month + "/" + year.slice(2, 4));
  };

  function createPlanner() {
    console.time("Request");
    if (deliveries.length === 0 || orders.length === 0) {
      toast.error("No files are added. Please add files first", {
        autoClose: true,
      });
      return;
    }
    let data = [];
    let dataSo = [];
    let columns = [
      "Account",
      "SO Number",
      "Order Ref",
      "SKU",
      "Description",
      "Partial Order",
      "Priority",
      "Req Ship",
      "QTY",
    ];
    let count = 0;
    let tempCol = [];
    deliveries.map((del, i) => {
      if (i > 1) {
        // columns.push(ExcelDateToJSDate(del[4]));
        tempCol.push(del[4]);
      }
    });
    let tempCols = [...new Set(tempCol)];

    // let cols = [...new Set(columns)];
    // cols.sort((a,b)=>)
    tempCols.sort((a, b) => {
      return new Date(b) - new Date(a);
    });
    tempCols.sort((a, b) => (new Date(a) > new Date(b) ? 1 : -1));
    tempCols.map((ele, i) => {
      columns.push(ExcelDateToJSDate(ele));
    });
    let cols = columns;
    deliveries.map((del, i) => {
      if (i > 1) {
        let delSku = del[0] + "-" + del[3];
        // console.log("delivery sku", delSku);
        orders.map((row, key) => {
          if (key > 1) {
            if (row[15] == delSku) {
              // console.log("order sku", row[15]);
              let tempData = data.find((ele, index) => {
                if (ele["sku"] == delSku && ele["so_number"] == row[1]) {
                  return true;
                }
              });
              if (tempData) {
                let updatedData = data.map((ele, index) => {
                  if (ele["sku"] == delSku && ele["so_number"] == row[1]) {
                    let temp = ele;
                    cols.map((col, key) => {
                      if (key > 8) {
                        if (col == ExcelDateToJSDate(del[4])) {
                          temp[col] = del[2];
                        }
                      }
                    });
                    return temp;
                  }
                  return ele;
                });
                data = updatedData;
              } else {
                tempData = {
                  so_number: row[1],
                  account: row[2],
                  order_ref: row[20],
                  sku: row[15],
                  desc: row[16],
                  req_ship: ExcelDateToJSDate(row[0]),
                  qty: row[18],
                  partial_order: row[22],
                  priority: row[21],
                  class: "",
                  req_ship_original: row[0],
                };
                cols.map((col, key) => {
                  if (key > 8) {
                    if (col == ExcelDateToJSDate(del[4])) {
                      tempData[col] = del[2];
                    } else {
                      tempData[col] = 0;
                    }
                  }
                });
                if (dataSo[row[1]] == undefined) {
                  dataSo[row[1]] = [];
                }
                dataSo[row[1]].push(tempData);
                data.push(tempData);
              }
            }
          }
        });
      }
    });

    //** Snippet to add missing orders sku from delivery to sheet */
    orders.map((element, i) => {
      if (i > 0) {
        if (element[0] == "Totals") {
          return;
        }
        let res = data.find(
          (temp) => element[15] == temp.sku && temp.so_number == element[1]
        );
        if (res === undefined) {
          console.log("so_num with no delivery", element[1]);
          let tempData = {
            so_number: element[1],
            account: element[2],
            order_ref: element[20],
            sku: element[15],
            desc: element[16],
            req_ship: ExcelDateToJSDate(element[0]),
            qty: element[18],
            partial_order: element[22],
            priority: element[21],
            class: "missing_orders",
            req_ship_original: element[0],
          };
          cols.map((col, key) => {
            if (key > 8) {
              tempData[col] = 0;
            }
          });
          if (dataSo[element[1]] == undefined) {
            dataSo[element[1]] = [];
          }
          dataSo[element[1]].push(tempData);
          data.push(tempData);
        }
      }
    });
    //** Snippet to add missing orders sku from delivery to sheet end */

    // console.log("data so numbers", dataSo);
    // let testData = [];
    // for (let key in dataSo) {
    //   let temp = dataSo[key].map((ele) => {
    //     testData.push(ele);
    //   });
    // }
    // console.log("testData so numbers", testData);

    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });
    data = data.sort((a, b) => {
      return collator.compare(a.so_number, b.so_number);
    });

    // data = data.sort((a, b) => {
    //   return collator.compare(a.priority, b.priority);
    // });
    // console.log(data);

    // data.sort((a, b) => (a.so_number > b.so_number ? 1 : -1));
    data.sort((a, b) => (a.priority > b.priority ? 1 : -1));

    let sortSku = [];
    data.map((ele, ind) => {
      if (sortSku[ele.so_number] == undefined) {
        sortSku[ele.so_number] = [];
      }
      sortSku[ele.so_number].push(ele);
    });

    let tempSorted = [];
    for (let sorted in sortSku) {
      sortSku[sorted].sort((a, b) => {
        return collator.compare(a.sku, b.sku);
      });
      sortSku[sorted].map((ele) => {
        tempSorted.push(ele);
      });
    }
    data = [...tempSorted];

    // const collator = new Intl.Collator(undefined, {
    //   numeric: true,
    //   sensitivity: "base",
    // });
    // data = data.sort((a, b) => {
    //   return collator.compare(a.so_number, b.so_number);
    // });

    let old_so_no = 0;
    let check = 0;
    data.map((d, i) => {
      if (old_so_no == 0) {
        d["class"] += " bg-yellow";
      } else if (old_so_no != d["so_number"]) {
        // console.log("else if old_so_no", old_so_no);
        check++;
        if (check % 2 == 0) {
          d["class"] += " bg-yellow";
        } else {
          d["class"] += " bg-gray";
        }
      } else {
        // console.log("else old_so_no", old_so_no);
        if (check % 2 == 0) {
          d["class"] += " bg-yellow";
        } else {
          d["class"] += " bg-gray";
        }
      }
      old_so_no = d["so_number"];
    });

    // console.log("data", data);
    let newStockCheck = [...stockCheck];
    let newAllocate = [...allocate];

    data.map((row, index) => {
      newAllocate[index] = false;
      let col = {};
      cols.map((column, i) => {
        if (i > 8) {
          col[column] = false;
        }
      });
      newStockCheck[index] = col;
    });
    setAllocate(newAllocate);
    setStockCheck(newStockCheck);

    // console.log("data", data);
    // console.log("cols", cols);
    setColumns(cols);
    let data3 = JSON.parse(JSON.stringify(data));
    setOrignalSheet(data3);
    data.map((d, i) => {
      cols.map((col, key) => {
        if (key > 8) {
          if (cols.length - 1 === key) {
            return;
          }
          d[cols[key + 1]] += d[col];
        }
      });
    });
    itemsRef.current = itemsRef.current.slice(0, data.length);
    let data2 = JSON.parse(JSON.stringify(data));
    setOrignalPlannerData(data2);
    let data1 = JSON.parse(JSON.stringify(data));
    setPlannerData(data1);
    console.timeEnd("Request");
    // setTableWidth(tableRef.current.offsetWidth);
  }
  function handleChange(val, event) {
    setPlanner(val);
  }

  /**Auto allocation Logic*/
  function autoBulkAllocate() {
    console.time("Request");
    let newPlannerData = [...plannerData];
    let newStockCheck = [...stockCheck];
    let newAllocate = [...allocate];

    plannerData.map((row, key) => {
      // if (row['so_number'] == 'PL-SO67147' && row['sku'] == '21730NB-4') {

      const data = allocateFn(
        row,
        key,
        newPlannerData,
        newAllocate,
        newStockCheck
      );
      newPlannerData = data.newPlannerData;
      newStockCheck = data.newStockCheck;
      newAllocate = data.newAllocate;
      // }
    });
    newPlannerData = highLightOrder(newPlannerData);
    setPlannerData(newPlannerData);
    setAllocate(newAllocate);
    setStockCheck(newStockCheck);
    updateErrors(newPlannerData);
    console.timeEnd("Request");
  }
  function highLightOrder(newPlannerData) {
    let soNoOutOfStock = [];
    newPlannerData.map((data, index) => {
      if (data["class"].indexOf("bg-red") >= 0) {
        // if (data["partial_order"] == "NO") {
        soNoOutOfStock.push(data["so_number"]);
        // }
      }
    });
    soNoOutOfStock = [...new Set(soNoOutOfStock)];
    console.log("out of stock", soNoOutOfStock);

    for (let i = 0; i < plannerData.length; i++) {
      if (soNoOutOfStock.includes(plannerData[i]["so_number"])) {
        if (!(plannerData[i]["class"].indexOf("bg-red") >= 0)) {
          if (!(plannerData[i]["class"].indexOf("bg-orange") >= 0)) {
            newPlannerData[i]["class"] += " bg-orange";
          }
        }
      }
    }

    console.log("here inside");

    // setAllocate(newAllocate);
    // setStockCheck(newStockCheck);
    // setPlannerData(newPlannerData);
    return newPlannerData;
  }
  function updateErrors(newPlannerData) {
    let newErrors = [];
    newPlannerData.map((row, i) => {
      if (newPlannerData[i]["class"].indexOf("bg-red") >= 0) {
        newErrors.push(i);
      }
    });
    newErrors.sort((a, b) => (a > b ? 1 : -1));
    setErrors(newErrors);
  }
  function allocateFn(
    planner,
    index,
    newPlannerData,
    newAllocate,
    newStockCheck
  ) {
    var dateArray = [];
    columns.map((column, key) => {
      if (key > 8) {
        if (orignalPlannerData[index][column] != 0) {
          // console.log('here')
          let temp = column.split("/");
          let date = temp[1] + "/" + temp[0] + "/" + temp[2];
          if (plannerData[index]["qty"] <= plannerData[index][column]) {
            let tempClass = newPlannerData[index]["class"].replace(
              " bg-red out_of_stock",
              ""
            );
            newPlannerData[index]["class"] = tempClass;
            dateArray.push(date);
            // newAllocate[index] = true;
          } else {
            if (!(newPlannerData[index]["class"].indexOf("bg-red") >= 0)) {
              newPlannerData[index]["class"] += " bg-red out_of_stock";
            }
            console.log(
              "cant allocate",
              plannerData[index]["so_number"] + "--" + plannerData[index]["sku"]
            );
          }
        } else {
          // console.log(
          //   "zero in orignal data",
          //   orignalPlannerData[index]["so_number"] +
          //   "--" +
          //   orignalPlannerData[index]["sku"]
          // );
        }
      }
    });

    let temp1 = plannerData[index]["req_ship"].split("/");
    let date1 = temp1[1] + "/" + temp1[0] + "/" + temp1[2];

    let column_name = findClosestNextDate(dateArray, date1);
    // if (
    //   plannerData[index]["so_number"] == "PL-SO10002" &&
    //   plannerData[index]["sku"] == "23009AA-3"
    // ) {
    //   console.log("datearrayt", dateArray);
    //   console.log("column_name", column_name);
    //   console.log("date1", date1);
    // }
    let date2 = 0;
    if (column_name != "") {
      let temp2 = column_name.split("/");
      date2 = temp2[1] + "/" + temp2[0] + "/" + temp2[2]; //closest date
    }
    let checked_col_index = 0;
    columns.map((column, key) => {
      if (date2 == column) {
        checked_col_index = key;
      }
    });

    if (date2 != 0) {
      columns.map((column, key) => {
        if (key > 8) {
          // console.log("column", column);
          if (date2 == column) {
            plannerData.map((row, i) => {
              if (newPlannerData[index]["sku"] == row["sku"]) {
                if (newPlannerData[index]["qty"] <= newPlannerData[i][column]) {
                  let rem_qty = 0;

                  /**current stock can full fill stock*/
                  for (let k = checked_col_index; k < columns.length; k++) {
                    //checking if delivered quantity is greater than requested quantity
                    // console.log(
                    //   "newPlannerData[i][columns[k]]",
                    //   newPlannerData[i][columns[k]]
                    // );
                    // console.log(
                    //   'newPlannerData[index]["qty"]',
                    //   newPlannerData[index]["qty"]
                    // );

                    if (
                      newPlannerData[i][columns[k]] >=
                      newPlannerData[index]["qty"]
                    ) {
                      newPlannerData[i][columns[k]] =
                        newPlannerData[i][columns[k]] -
                        newPlannerData[index]["qty"];
                    } else {
                      newPlannerData[i][columns[k]] = 0;
                    }
                  }
                  /**current stock can full fill stock ends*/

                  /**if current checked orignal stock can not fulfill required quantity get from previous ones*/
                  if (
                    orignalSheet[index][column] < newPlannerData[index]["qty"]
                  ) {
                    rem_qty =
                      newPlannerData[index]["qty"] -
                      orignalSheet[index][column];
                    // console.log("rem qty", rem_qty);
                    for (let j = columns.length; j > 8; j--) {
                      if (rem_qty == 0) break;

                      if (rem_qty > 0) {
                        //condition for current and previous quantities
                        if (j < checked_col_index) {
                          if (orignalSheet[index][columns[j]] == 0) {
                            newPlannerData[i][columns[j]] =
                              newPlannerData[i][columns[j]] -
                              newPlannerData[index]["qty"];
                            continue;
                          }
                          if (
                            newPlannerData[i][columns[j]] >= rem_qty //checking if delivered quantity is greater than requested quantity
                          ) {
                            newPlannerData[i][columns[j]] =
                              newPlannerData[i][columns[j]] - rem_qty;
                            rem_qty = 0;
                          } else {
                            rem_qty = rem_qty - newPlannerData[i][columns[j]];
                            newPlannerData[i][columns[j]] = 0;
                          }
                        }
                      }
                    }
                  }
                  /**if current checked stock can not fulfill required quantity ends*/

                  newStockCheck[index][column] = true;
                  newAllocate[index] = true;
                } else {
                  // newPlannerData[index]['class'] +=' bg-red out_of_stock';
                }
              }
            });
          }
        }
      });
    } else {
      if (!(newPlannerData[index]["class"].indexOf("bg-red") >= 0)) {
        newPlannerData[index]["class"] += " bg-red out_of_stock";
      }
    }

    return { newPlannerData, newAllocate, newStockCheck };
  }
  function findClosestNextDate(arr, target) {
    let targetDate = new Date(target);
    // console.log("targetdate", targetDate);
    let nextDates = arr.filter((e) => {
      // console.log("difference", new Date(e) - targetDate);
      if (new Date(e) - targetDate >= 0) {
        return true;
      } else {
        return false;
      }
    });
    console.log("next dates", nextDates);
    let sortedNextDates = nextDates.filter((a, b) => {
      // console.log("new Date(a)", new Date(a));
      // console.log("new Date(b)", new Date(b));

      if (new Date(b) - new Date(a)) {
        return true;
      } else {
        return false;
      }
    });
    if (sortedNextDates.length == 0) {
      let prevDates = arr.filter((e) => new Date(e) - targetDate <= 0);
      // console.log("prevDates dates", prevDates);
      let sortedPrevDates = prevDates.filter(
        (a, b) => new Date(b) - new Date(a)
      );
      return sortedPrevDates[sortedPrevDates.length - 1] || "";
    }
    // console.log("sortedNextDates", sortedNextDates);
    return sortedNextDates[0] || "";
  }
  /**Auto allocation Logic ends*/
  function handleCheckboxChange(event, index, row) {
    const { checked } = event.target;

    // console.log("orignalSheet", orignalSheet);
    let newStockCheck = [...stockCheck];
    let is_any_checked = false;
    let checked_col_index = 0;
    columns.map((column, key) => {
      if (stockCheck[index][column]) {
        is_any_checked = true;
        checked_col_index = key;
      }
    });
    if (checked) {
      if (!is_any_checked) {
        toast.error("Please Select Stock Checkbox", {
          autoClose: true,
        });
        return;
      }
      let newAllocate = [...allocate];
      newAllocate[index] = true;
      setAllocate(newAllocate);
      let newPlannerData = [...plannerData];
      columns.map((column, key) => {
        if (key > 8) {
          if (stockCheck[index][column]) {
            /**Iterating through each row */
            plannerData.map((row, i) => {
              if (newPlannerData[index]["sku"] === row["sku"]) {
                if (newPlannerData[index]["qty"] <= newPlannerData[i][column]) {
                  let rem_qty = 0;

                  /**current stock can full fill stock*/
                  for (let k = checked_col_index; k < columns.length; k++) {
                    //checking if delivered quantity is greater than requested quantity
                    // console.log(
                    //   "newPlannerData[i][columns[k]]",
                    //   newPlannerData[i][columns[k]]
                    // );
                    // console.log(
                    //   'newPlannerData[index]["qty"]',
                    //   newPlannerData[index]["qty"]
                    // );

                    if (
                      newPlannerData[i][columns[k]] >=
                      newPlannerData[index]["qty"]
                    ) {
                      newPlannerData[i][columns[k]] =
                        newPlannerData[i][columns[k]] -
                        newPlannerData[index]["qty"];
                    } else {
                      newPlannerData[i][columns[k]] = 0;
                    }
                  }
                  /**current stock can full fill stock ends*/

                  /**if current checked orignal stock can not fulfill required quantity get from previous ones*/
                  if (
                    orignalSheet[index][column] < newPlannerData[index]["qty"]
                  ) {
                    rem_qty =
                      newPlannerData[index]["qty"] -
                      orignalSheet[index][column];
                    console.log("rem qty", rem_qty);
                    for (let j = columns.length; j > 8; j--) {
                      if (rem_qty == 0) break;

                      if (rem_qty > 0) {
                        //condition for current and previous quantities
                        if (j < checked_col_index) {
                          if (orignalSheet[index][columns[j]] == 0) {
                            newPlannerData[i][columns[j]] =
                              newPlannerData[i][columns[j]] -
                              newPlannerData[index]["qty"];
                            continue;
                          }
                          if (
                            newPlannerData[i][columns[j]] >= rem_qty //checking if delivered quantity is greater than requested quantity
                          ) {
                            newPlannerData[i][columns[j]] =
                              newPlannerData[i][columns[j]] - rem_qty;
                            rem_qty = 0;
                          } else {
                            rem_qty = rem_qty - newPlannerData[i][columns[j]];
                            newPlannerData[i][columns[j]] = 0;
                          }
                        }
                      }
                    }
                  }
                  /**if current checked stock can not fulfill required quantity ends*/

                  newStockCheck[index][column] = true;
                }
                // if (i != index) {
                //   if (stockCheck[index][column]) {
                //     if (!allocate[i]) {
                //       if (
                //         newPlannerData[i][column] >= newPlannerData[i]["qty"]
                //       ) {
                //         let tempClass = newPlannerData[i]["class"].replace(
                //           "bg-red out_of_stock",
                //           ""
                //         );
                //         newPlannerData[i]["class"] = tempClass;
                //       } else {
                //         if (
                //           !(newPlannerData[i]["class"].indexOf("bg-red") >= 0)
                //         )
                //           newPlannerData[i]["class"] += " bg-red out_of_stock";
                //       }
                //     }
                //   }
                // }
              }
            });
            /**Iterating through each row ends */
          }
        }
      });
      newPlannerData = highLightOrder(newPlannerData);
      console.log("here new");
      setPlannerData(newPlannerData);
    } else {
      // console.log("newOrignalPlannerData", orignalPlannerData[index]);
      let newAllocate = [...allocate];
      newAllocate[index] = false;
      setAllocate(newAllocate);
      let newPlannerData = [...plannerData];

      /**updating current clicked allocate button row*/

      let rem_qty = 0;

      // console.log("checked_col_index", checked_col_index);
      /**current stock can full fill stock*/
      for (let k = checked_col_index; k < columns.length; k++) {
        if (stockCheck[index][columns[k]]) {
          if (orignalSheet[index][columns[k]] == 0) {
            rem_qty = newPlannerData[index]["qty"];
            // continue;
          }
          if (newPlannerData[index]["qty"] > orignalSheet[index][columns[k]]) {
            rem_qty =
              newPlannerData[index]["qty"] - orignalSheet[index][columns[k]];
            console.log("tempSum rem_qty", rem_qty);
          }
        }
        newPlannerData[index][columns[k]] =
          newPlannerData[index][columns[k]] + newPlannerData[index]["qty"];
      }
      /**current stock can full fill stock ends*/
      let is_done = 0;
      let count = 0;
      for (let j = checked_col_index - 1; j > 8; j--) {
        // if (orignalSheet[index][columns[j]] == 0) {
        //   rem_qty = newPlannerData[index]["qty"];
        //   // continue;
        // } else {

        // if (rem_qty < newPlannerData[index][columns[j]]) {
        //   is_done = 1;
        // }
        // }
        console.log("remaining quantity", rem_qty);

        if (rem_qty != 0) {
          // if (count > 0) {
          //   if (orignalPlannerData[index][columns[j]] != 0) {
          //     if (rem_qty > orignalSheet[index][columns[j]]) {
          //       rem_qty =
          //         newPlannerData[index]["qty"] -
          //         orignalSheet[index][columns[j]];
          //     } else {
          //       rem_qty = 0;
          //     }
          //   }
          // }
          // console.log("prev rem qty", rem_qty);

          if (
            orignalSheet[index][columns[j]] == 0 &&
            orignalPlannerData[index][columns[j]] == 0
          ) {
            break;
          } else if (
            orignalSheet[index][columns[j]] == 0 &&
            orignalPlannerData[index][columns[j]] != 0
          ) {
            newPlannerData[index][columns[j]] =
              newPlannerData[index][columns[j]] + rem_qty;
          } else if (
            orignalSheet[index][columns[j]] != 0 &&
            orignalPlannerData[index][columns[j]] != 0
          ) {
            newPlannerData[index][columns[j]] =
              newPlannerData[index][columns[j]] + rem_qty;
            rem_qty = 0;
            // if (is_done) {
            //   rem_qty = 0;
            // }
            // if (rem_qty < newPlannerData[index][columns[j]]) {
            //   is_done = 1;
            // }
          }
        }
        count++;
      }
      columns.map((column, key) => {
        if (key > 8) {
          if (stockCheck[index][column]) {
            newStockCheck[index][column] = false;
          }
        }
      });
      /**updating current clicked allocate button row end*/

      /**updating other similar skus*/
      plannerData.map((row, i) => {
        if (i !== index) {
          if (newPlannerData[index]["sku"] == row["sku"]) {
            columns.map((column, key) => {
              if (key > 8) {
                newPlannerData[i][column] = newPlannerData[index][column];
              }
            });
          }
        }
      });
      /**updating other similar skus end*/
      newPlannerData = highLightOrder(newPlannerData);
      setStockCheck(newStockCheck);
      setPlannerData(newPlannerData);
    }
  }

  function validateSheet() {
    let newPlannerData = [...plannerData];
    plannerData.map((row, index) => {
      let flag = 0;
      for (let i = 9; i < columns.length; i++) {
        if (allocate[index]) {
          let tempClass = newPlannerData[index]["class"].replace(
            " bg-red out_of_stock",
            ""
          );
          newPlannerData[index]["class"] = tempClass;
          break;
        }

        if (newPlannerData[index]["class"].indexOf("bg-red") >= 0) {
          if (parseInt(row["qty"]) <= parseInt(row[columns[i]])) {
            let tempClass = newPlannerData[index]["class"].replace(
              " bg-red out_of_stock",
              ""
            );
            newPlannerData[index]["class"] = tempClass;
            console.log("removing Red");
            flag = 1;
            let check = 0;
            for (let pi = 0; pi < plannerData.length; pi++) {
              if (plannerData[pi]["so_number"] == row["so_number"]) {
                console.log("removing Orange");
                console.log(
                  'row["so_number"]',
                  newPlannerData[pi]["so_number"]
                );

                console.log('row["sku"]', newPlannerData[pi]["sku"]);
                console.log("tempClass1 before", newPlannerData[pi]["class"]);

                let tempClass1 = newPlannerData[pi]["class"].replace(
                  " bg-orange",
                  ""
                );
                console.log("tempClass1 after", tempClass1);
                newPlannerData[pi]["class"] = tempClass1;

                check = 1;
                console.log("all data", newPlannerData[pi]);
              } else if (plannerData[pi]["so_number"] != row["so_number"]) {
                if (check == 1) {
                  break;
                }
              }
            }

            break;
          }
        } else if (row["qty"] > row[columns[i]]) {
          if (!allocate[index]) {
            if (!(row["class"].indexOf("bg-red") >= 0))
              newPlannerData[index]["class"] += " bg-red out_of_stock";
          }
        }
      }

      // if (row["sku"] == "21482D-4" && row["so_number"] == "PL-SO67797") {
      //   console.log("flag", flag);
      //   console.log("row data", row);
      // }
      // if (flag == 0) {
      //   if (!(row["class"].indexOf("bg-red") >= 0))
      //     newPlannerData[index]["class"] += " bg-red out_of_stock";
      // }
    });
    // SO NUMBER :PL-SO67797
    newPlannerData = highLightOrder(newPlannerData);
    setPlannerData(newPlannerData);
    updateErrors(newPlannerData);
  }

  function StockCheckboxChange(event, index, key) {
    const { name, value, checked } = event.target;
    if (allocate[index]) {
      toast.error("Please Unselect Allocate and then check stock boxes", {
        autoClose: true,
      });
      return false;
    }
    let is_any_checked = false;

    if (checked) {
      columns.map((column, key) => {
        if (key > 8) {
          if (stockCheck[index][column]) is_any_checked = true;
        }
      });
      console.log("is_any_checked", is_any_checked);
      if (is_any_checked) {
        toast.error("Please Uncheck other checkbox from same row", {
          autoClose: true,
        });
        return;
      }
      if (
        plannerData[index][key] == 0 ||
        plannerData[index]["qty"] > plannerData[index][key]
      ) {
        toast.error("Quantity should be greater than required quantity", {
          autoClose: true,
        });
        return;
      }
      let newStockCheck = [...stockCheck];
      newStockCheck[index][key] = true;
      setStockCheck(newStockCheck);
    } else {
      let newStockCheck = [...stockCheck];
      newStockCheck[index][key] = false;
      setStockCheck(newStockCheck);
    }
  }
  function submitData() {
    console.log("planner", planner);
    if (!planner) {
      toast.error("Please Write Planner Name", {
        autoClose: true,
      });
      return;
    }
    if (plannerData) {
      // if (validatePlannerData() == 0) {
      //   toast.error(
      //     "Please check whole sheet for errors and perform advised actions",
      //     {
      //       autoClose: true,
      //     }
      //   );
      //   return;
      // }

      let data = {
        plannerData: plannerData,
        allocate: allocate,
        orignal_planner_data: orignalPlannerData,
        orignal_sheet: orignalSheet,
      };
      let formData = {
        data: data,
        columns: columns,
        stockCheck: stockCheck,
        planner_name: planner,
      };
      console.log("formdata", formData);

      insertPlanner(formData, adminAuth)
        .then((response) => {
          console.log("response", response);
          let msg = "Planner Saved Successfully";
          toast.success(msg, {
            autoClose: true,
          });
          // window.reload();
          setPlannerData([]);
          setStockCheck([]);
          setAllocate([false, false]);
          setColumns([]);
          history.push("/admins/orderplanner");
        })
        .catch((e) => {
          toast.error("Unable to save Data, Try again", {
            autoClose: true,
          });
          // setOnline(false);
        });
    }
  }
  function validatePlannerData() {
    let is_valid = 1;
    let soNoOutOfStock = [];
    plannerData.map((data, index) => {
      if (data["class"].indexOf("bg-red") >= 0) {
        if (data["partial_order"] == "NO") {
          soNoOutOfStock.push(data["so_number"]);
        }
      }
    });
    soNoOutOfStock = [...new Set(soNoOutOfStock)];
    console.log("out of stock", soNoOutOfStock);
    let oos_ids = [];
    let newAllocate = [...allocate];
    let newPlannerData = [...plannerData];
    let newStockCheck = [...stockCheck];

    for (let i = 0; i < plannerData.length; i++) {
      if (soNoOutOfStock.includes(plannerData[i]["so_number"])) {
        let is_clicked = 0;
        if (allocate[i]) {
          //if allocate is checked
          columns.map((clm, key) => {
            if (key > 8) {
              if (stockCheck[i][clm]) {
                is_valid = 0;
                // oos_ids.push(plannerData[i]["so_number"]);
                // if (is_clicked == 0) {
                //   let data = checkboxTrigger(
                //     i,
                //     newAllocate,
                //     newPlannerData,
                //     newStockCheck
                //   );
                //   newPlannerData = data.newPlannerData;
                //   newStockCheck = data.newStockCheck;
                //   newAllocate = data.newAllocate;
                // }
                // is_clicked = 1;
              }
            }
          });
          if (is_valid == 0) {
            console.log("not valid");
            break;
          }
        }
      }
    }

    // setAllocate(newAllocate);
    // setStockCheck(newStockCheck);
    // setPlannerData(newPlannerData);

    console.log("valid", is_valid);

    return is_valid;
  }
  function checkboxTrigger(index, newAllocate, newPlannerData, newStockCheck) {
    let is_any_checked = false;
    let checked_col_index = 0;
    columns.map((column, key) => {
      if (stockCheck[index][column]) {
        is_any_checked = true;
        checked_col_index = key;
      }
    });

    newAllocate[index] = false;
    setAllocate(newAllocate);

    /**updating current clicked allocate button row*/

    let rem_qty = 0;

    // console.log("checked_col_index", checked_col_index);
    /**current stock can full fill stock*/
    for (let k = checked_col_index; k < columns.length; k++) {
      if (stockCheck[index][columns[k]]) {
        if (orignalSheet[index][columns[k]] == 0) {
          rem_qty = newPlannerData[index]["qty"];
          // continue;
        }
        if (newPlannerData[index]["qty"] > orignalSheet[index][columns[k]]) {
          rem_qty =
            newPlannerData[index]["qty"] - orignalSheet[index][columns[k]];
          console.log("tempSum rem_qty", rem_qty);
        }
      }
      newPlannerData[index][columns[k]] =
        newPlannerData[index][columns[k]] + newPlannerData[index]["qty"];
    }
    /**current stock can full fill stock ends*/
    let is_done = 0;
    let count = 0;
    for (let j = checked_col_index - 1; j > 8; j--) {
      if (rem_qty != 0) {
        if (
          orignalSheet[index][columns[j]] == 0 &&
          orignalPlannerData[index][columns[j]] == 0
        ) {
          break;
        } else if (
          orignalSheet[index][columns[j]] == 0 &&
          orignalPlannerData[index][columns[j]] != 0
        ) {
          newPlannerData[index][columns[j]] =
            newPlannerData[index][columns[j]] + rem_qty;
        } else if (
          orignalSheet[index][columns[j]] != 0 &&
          orignalPlannerData[index][columns[j]] != 0
        ) {
          newPlannerData[index][columns[j]] =
            newPlannerData[index][columns[j]] + rem_qty;
          rem_qty = 0;
        }
      }
      count++;
    }
    columns.map((column, key) => {
      if (key > 8) {
        if (stockCheck[index][column]) {
          newStockCheck[index][column] = false;
        }
      }
    });
    /**updating current clicked allocate button row end*/

    /**updating other similar skus*/
    plannerData.map((row, i) => {
      if (i !== index) {
        if (newPlannerData[index]["sku"] == row["sku"]) {
          columns.map((column, key) => {
            if (key > 8) {
              newPlannerData[i][column] = newPlannerData[index][column];
            }
          });
        }
      }
    });
    /**updating other similar skus end*/

    return { newStockCheck, newPlannerData, newAllocate };
  }
  const goToError = (dir) => {
    if (errors.length == 0) {
      toast.error("There are no Errors on sheet", {
        autoClose: true,
      });
      return;
    }
    errors.map((ele, index) => {
      itemsRef.current[errors[index]].classList.remove("current_error");
    });
    let newCurrentErrorIndex = currentErrorIndex;
    let errorCount = errors.length - 1;
    if (dir == 0) {
      if (newCurrentErrorIndex == -1 || newCurrentErrorIndex == 0) {
        newCurrentErrorIndex = errorCount;
      } else {
        newCurrentErrorIndex -= 1;
      }
    } else {
      if (newCurrentErrorIndex == errorCount) {
        newCurrentErrorIndex = 0;
      } else {
        newCurrentErrorIndex += 1;
      }
    }
    console.log("error index", newCurrentErrorIndex);
    itemsRef.current[errors[newCurrentErrorIndex]].classList.add(
      "current_error"
    );
    itemsRef.current[errors[newCurrentErrorIndex]].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    setcurrentErrorIndex(newCurrentErrorIndex);
    // if (dir == 0) {
    //   if (newCurrentErrorIndex == 0) {
    //     newCurrentErrorIndex = errorCount;
    //   }
    //   // temp = errorCount - currentErrorIndex;
    //   setcurrentErrorIndex(newCurrentErrorIndex - 1);
    // } else {
    //   setcurrentErrorIndex(newCurrentErrorIndex + 1);
    // }
  };
  return (
    <>
      <h2 style={{ textAlign: "center" }}>Order Planner</h2>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={4}>
          <label>Planner Name</label>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Input
            size="md"
            placeholder="Planner"
            name="planner_name"
            onChange={handleChange}
            value={planner}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={4}>
          <label>Order</label>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <input
            type="file"
            onChange={orderFileHandler.bind(this)}
            style={{ padding: "10px" }}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={4}>
          <label>Delivery</label>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <input
            type="file"
            onChange={deliveryFileHandler.bind(this)}
            style={{ padding: "10px" }}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={8}>
          <Button color="green" appearance="primary" onClick={createPlanner}>
            Create Planner
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={8}>
          <Divider />
          <Button color="green" appearance="primary" onClick={submitData}>
            Save Planner
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {plannerData.length > 0 ? (
        <FlexboxGrid
          justify="end"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <FlexboxGrid.Item colspan={10}>
            <Button
              style={{ marginRight: "10px" }}
              color="green"
              appearance="primary"
              onClick={validateSheet}
            >
              Validate Sheet
            </Button>
            <Button
              style={{ marginRight: "10px" }}
              color="green"
              appearance="primary"
              onClick={autoBulkAllocate}
            >
              Auto Allocate
            </Button>
            <FaExclamationTriangle
              style={{ marginRight: "3px" }}
              className="custom_icon"
            />
            <span className="error_text">{errors.length}</span>
            <FaCaretLeft className="custom_icon" onClick={() => goToError(0)} />
            <span className="error_text" style={{ color: "black" }}>
              {currentErrorIndex + 1}
            </span>
            <FaCaretRight
              className="custom_icon"
              onClick={() => goToError(1)}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      ) : null}

      {plannerData.length > 0 ? (
        <>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={24}>
              {isSticky && (
                <>
                  <div
                    className="custom_div sticky"
                    style={{
                      width: tableWidth,
                    }}
                  >
                    <FaExclamationTriangle
                      style={{ marginRight: "3px" }}
                      className="custom_icon"
                    />
                    <span className="error_text">{errors.length}</span>
                    <FaCaretLeft
                      className="custom_icon"
                      onClick={() => goToError(0)}
                    />
                    <span className="error_text" style={{ color: "black" }}>
                      {currentErrorIndex + 1}
                    </span>
                    <FaCaretRight
                      className="custom_icon"
                      onClick={() => goToError(1)}
                    />
                  </div>
                  <table
                    className="sticky table custom_table"
                    style={{
                      width: tableWidth,
                    }}
                  >
                    <thead>
                      <tr>
                        {columns.map((column, index) => (
                          <th key={"header_" + index}>{column}</th>
                        ))}
                        <th>Allocate</th>
                      </tr>
                    </thead>
                  </table>
                </>
              )}
              <table ref={tableRef} className="table custom_table">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={"header_" + index}>{column}</th>
                    ))}
                    <th>Allocate</th>
                  </tr>
                </thead>
                <tbody>
                  {plannerData.map((row, index) => {
                    return (
                      <Fragment key={"fragment_" + index}>
                        <tr
                          key={"plannerData_" + index}
                          className={row["class"]}
                          ref={(el) => (itemsRef.current[index] = el)}
                        >
                          <td>{row["account"]}</td>
                          <td>{row["so_number"]}</td>
                          <td>{row["order_ref"]}</td>
                          <td>{row["sku"]}</td>
                          <td>{row["desc"]}</td>
                          <td style={{ textAlign: "center" }}>
                            {row["partial_order"]}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {row["priority"]}
                          </td>
                          <td>{row["req_ship"]}</td>
                          <td style={{ textAlign: "center" }}>
                            <b>{row["qty"]}</b>
                          </td>
                          {columns.map((column, key) => {
                            return key > 8 ? (
                              <td key={"dynamic_" + key}>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    StockCheckboxChange(e, index, column)
                                  }
                                  checked={stockCheck[index][column]}
                                />{" "}
                                <b
                                  className={
                                    stockCheck[index][column]
                                      ? "green-color"
                                      : null
                                  }
                                >
                                  {row[column]}
                                </b>
                              </td>
                            ) : null;
                          })}
                          <td>
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckboxChange(e, index, row)
                              }
                              checked={allocate[index]}
                              ref={(el) => (checkboxes.current[index] = el)}
                            />
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </>
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPlanner);
