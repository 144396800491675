export default {
  counter: 0,
  styles: [],
  apiCallsInProgress: 0,
  auth: null,
  adminAuth: null,
  customer: { account: "-" },
  cart: [],
  addresses: [],
  billingAddresses: [],
  shippingAddresses: [],
  totals: { qty: 0, price: 0 },
  currency: "-",
  seasons: [],
  options: {},
  accounts: [],
  orders: [],
  orderAmends: [],
  pos: [],
  season: [],
  report: [],
  user: null,
  userData: null,
  stock: [],
  amend: false,
  currentSeason: 70,
  confirm: {
    signature: null,
    discount: null,
    promo: null,
    comments: null,
    shipping: null,
    images: null,
  },
};
