import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function currencySaveInState(response) {
  return { type: types.CURRENCY_SAVE_IN_STATE, response };
}

export function currencySaveInStateSuccess() {
  return { type: types.CURRENCY_SAVE_IN_STATE_SUCCESS };
}

export function saveCurrency(currency) {
  return function (dispatch) {
    try {
      dispatch(currencySaveInState(currency));
      dispatch(currencySaveInStateSuccess());
    } catch (error) {
      dispatch(apiCallError());
    }
  };
}
