import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function orderReducer(state = initialState.orders, action) {
  switch (action.type) {
    case types.LOAD_ORDERS_SUCCESS:
      return action.orders;
    case types.AUTH_SESSION_CLEAR:
      return (state = null);
    case types.REQUEST_UPDATE:
      return [];
    case types.CHANGE_ORDER_STATUS:
      return [];
    default:
      return state;
  }
}
