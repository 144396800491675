import React from "react";
import "./Terms.css";

export default function Terms({ amend }) {
  return !amend ? (
    <div className="confirm-terms">
      <h3>Terms and Conditions</h3>
      <div className="terms">
        <p>SETTLEMENTS:</p>
        <p>
          Payment will be due 30 days from the date of invoice and shall be
          strictly NETT. Title to the goods supplied by the company shall not
          pass to the purchaser until all outstanding indebtedness to the seller
          has been discharged. The company reserve the right to levy a surcharge
          - Overdue accounts are subject to a 4% interest charge on the month's
          total and may be placed on credit stop, which may cause a delay in the
          dispatch of outstanding orders. To avoid incorrect interest charges
          being applied to your account, please immediately notify us when
          payment has been made via the Bank Automated Clearing System (BACS).
        </p>
        <p>CARRIAGE:</p>
        <p>
          Please allow 3 days for delivery. Carriage charges will be added if
          ordering less than 6 pairs at one time.
        </p>
        <p>CANCELLATIONS:</p>
        <p>
          Forward orders placed by wholesale accounts are received in good faith
          &amp; are placed with our factory with this in mind. As a result of
          this, we do not accept any cancellations after an initial 30 days
          ‘cooling off’ period.
        </p>
        <p>REQUESTED DELIVERY DATE</p>
        <p>
          We will always endeavor to deliver your order during the requested
          delivery period, however this is not guaranteed due to external
          influences on manufacturing lead times.
        </p>
        <p>RETURNS:</p>
        <p>
          Returns are only accepted if there is a manufacturing fault. You must
          contact the Customer Care team prior to their return to obtain a valid
          returns number. It is our policy to issue credit note refunds for all
          products returned in their original packaging and condition. Unless
          the product is faulty, the cost of returning items to the company is
          payable by the purchaser. Faulty unworn shoes must be raised to us in
          writing within 6 months of invoice. Faulty worn shoes must be raised
          to us in writing within 12 months of invoice. All faulty returns will
          either be replaced or credited at our discretion.
        </p>
        <p>FULL TERMS AND CONDITIONS:</p>
        <p>Full terms and conditions are available on our website.</p>
      </div>
    </div>
  ) : null;
}
