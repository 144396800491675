import React from "react";
import { NavLink } from "react-router-dom";
import ChangeCustomerButton from "./ChangeCustomerButton";

const CustomerDetails = ({
  customer,
  customerFormOpen,
  reOpenCustomerForm,
  confirm = false,
}) => {
  const getEditButton = () => {
    return confirm === true ? (
      <NavLink className="btn btn-secondary" to="/customer">
        Edit
      </NavLink>
    ) : null;
  };

  const getConfirmButton = () => {
    return confirm === false ? (
      <ChangeCustomerButton
        className="btn btn-primary"
        customerFormOpen={customerFormOpen}
        reOpenCustomerForm={reOpenCustomerForm}
      />
    ) : null;
  };

  return Object.keys(customer).length > 1 && !customerFormOpen ? (
    <div className="customer-details">
      {getEditButton()}
      <div>
        <h3>Customer Details</h3>
        <p>
          <span>Account Number | </span>
          {customer.account}
        </p>
        <p>
          <span>Store | </span>
          {customer.storeName}
        </p>
        <p>
          <span>Contact | </span>
          {customer.contactNameFirst + " " + customer.contactNameLast}
        </p>
        <p>
          <span>Phone | </span>
          {customer.phone}
        </p>
        <p>
          <span>Email | </span>
          {customer.email}
        </p>
      </div>
      {getConfirmButton()}
    </div>
  ) : null;
};

export default CustomerDetails;
