import React from "react";
import SelectInput from "../shared/SelectInput";

export default function ({ styles, onChange }) {
  let options = [];

  function getNameOptions() {
    let seasonOptions = [];

    if (styles && styles.length > 0) {
      let names = collateNames();
      names.map((name) => {
        options.push({
          value: name.name,
          text: name.name,
        });
      });
    }
    return seasonOptions;
  }

  function collateNames() {
    let result = [];
    styles.map((style, index) => {
      if (result.length === 0) {
        result.push(style);
      } else {
        let i = result.findIndex((item) => {
          return item.Name === styles[index].Name;
        });
        if (i === -1) {
          result.push(style);
        }
      }
    });
    return result;
  }

  return (
    <div className="season">
      <SelectInput
        name="name"
        label="Name"
        onChange={onChange}
        defaultOption={options && options.length > 0 ? options[1] : "not found"}
        options={getNameOptions()}
      />
    </div>
  );
}
