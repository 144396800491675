import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "../shared/Spinner";
import List from "./List";
import { loadOfflineOrder } from "../../redux/actions/orderActions";


function Index({ user, loadOfflineOrder, history }) {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!orders || orders.length === 0) {
       getOrders();
    }
  },[]);

  const getOrders = () => {
    let storageOrders = [];

    for(var i=0, len=localStorage.length; i<len; i++) {
      var key = localStorage.key(i);
      var value = localStorage[key];
      if(key !== 'persist:root'){
        storageOrders.push(JSON.parse(value))
      }
   }

    setOrders(storageOrders);
  }

  const handleDelete = (id) => {
    localStorage.removeItem(id);
    getOrders();
    toast.success("Order Deleted", { autoClose: true });
  }

  const handleEdit = (id) => {
    let order = JSON.parse(localStorage.getItem(id));
    loadOfflineOrder(order);
    history.push('/customer');


    toast.success("Order Opened for Editing", { autoClose: true });
  };

  const handleSubmit = (id) => {
    getOrders();
    toast.success("Order Submitted", { autoClose: true });
  };

  const showOrders = () => {
    return orders ? (
      <div className="content orders">
        <h1>My Offline Orders</h1>
        <p>Here you can submit orders which you have saved offline</p>
        <List 
          user={user} 
          handleDelete={handleDelete} 
          handleSubmit={handleSubmit} 
          handleEdit={handleEdit} 
          orders={orders} />
      </div>
    ) : null;
  };

  return orders && orders.length === 0 ? (
    <Spinner />
  ) : (
    showOrders()
  );
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

const mapDispatchToProps = {
  loadOfflineOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
