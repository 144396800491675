import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_BASE_URL;
const categoryId = process.env.REACT_APP_MAIN_CATEGORY;

function getName(res) {
  return res.json().then((response) => {
    if (response && response.name) {
      return response.name;
    } else {
      return null;
    }
  });
}

function getCategory(category, auth) {
  return fetch(baseUrl + "/category?category=" + category, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then(getName)
    .then((name) => {
      return { id: category, name: name };
    });
}

function getMainCategory(auth) {
  return fetch(baseUrl + "/mainCategory?category=" + categoryId, {
    method: "GET",
    headers: {
      authorization: "Bearer " + auth,
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then((response) => {
      return response.json().then((res) => {
        return res;
      });
    });
}

let seasons = [];

function getChildCategories(mainCategory, auth) {
  seasons = [];
  let childCategories = mainCategory.children.split(",");
  childCategories.sort(function (a, b) {
    return a - b;
  });

  if (childCategories && childCategories.length > 0) {
    return childCategories
      .reduce((chain, category) => {
        return chain.then(() => {
          return getCategory(category, auth).then((season) => {
            seasons.push(season);
          });
        });
      }, Promise.resolve())
      .then(() => {
        return seasons;
      });
  }
}

export function getSeasons(auth) {
  return getMainCategory(auth)
    .then((mainCategory) => {
      if (mainCategory && mainCategory.children) {
        return getChildCategories(mainCategory, auth);
      }
    })
    .catch(handleError);
}
