import React from "react";
import { Uploader } from "rsuite";

export default function ImageUploader({
  defaultFileList,
  setDefaultFileList,
  auth,
}) {
  const setFile = (file) => {
    let newFileList = defaultFileList;
    if (newFileList && newFileList.length > 0) {
      newFileList.push({
        name: file.name,
        filename: file.filename,
        imageKey: file.imageKey,
      });
    } else {
      newFileList = [
        {
          name: file.name,
          filename: file.filename,
          imageKey: file.imageKey,
        },
      ];
    }

    setDefaultFileList(newFileList);
  };

  return (
    <div className="confirm-declaration">
      <h3>Images</h3>
      <label>
        Enter any images such as order forms to attach to this order
      </label>
      <Uploader
        action={process.env.REACT_APP_IMAGE_URL}
        defaultFileList={
          defaultFileList && defaultFileList.length > 0
            ? defaultFileList
            : defaultFileList
        }
        name="images"
        headers={{
          authorization: "Bearer " + auth,
        }}
        accept="image/*"
        draggable
        onSuccess={setFile}
        renderFileInfo={(file) => {
          return (
            <div>
              <span>File Name: {file.name}</span>
            </div>
          );
        }}
      >
        <div>Click or Drag files to this area to upload</div>
      </Uploader>
    </div>
  );
}
