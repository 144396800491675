import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { cancelOrder } from "../../../redux/actions/cartActions";
import { orderSuccess } from "../../../redux/actions/orderActions";
import "./Index.css";

const Index = ({ location, orderSuccess, auth, history, adminAuth }) => {
  const [setErrors] = useState({});
  useEffect(() => {
    if (location && location.state && location.state.result) {
    } else {
      if (adminAuth) {
        history.push({
          pathname: "/admins/orders",
        });
      } else {
        history.push({
          pathname: "/",
        });
      }
    }
  }, []);

  const showNewAccountMessage = (type, account) => {
    return type === "NEW" ? (
      <div className="new-account">
        <p>
          We have also received your request for a credit account. This is
          currently being reviewed by the accounts team. You will be provided
          with your account number once the setup has been complete (usually
          within 24 hours).
        </p>
      </div>
    ) : null;
  };

  const shouldBeHere = () => {
    if (location && location.state && location.state.result) {
      orderSuccess();
      return true;
    }
    return false;
  };

  const handleClearCart = (event) => {
    event.preventDefault();
    try {
      if (adminAuth) {
        cancelOrder(adminAuth);
        return history.replace({
          pathname: "/admins/orders",
        });
      } else {
        cancelOrder(auth);
        return history.replace({
          pathname: "/",
        });
      }
    } catch (error) {
      setErrors({ onSave: error.message });
    }
  };

  const handleSearch = (event) => {
    return history.replace({
      pathname: "/admins/orders",
    });
  };

  return shouldBeHere() ? (
    location.state.result.is_draft ? (
      <div className="content success">
        <h1>Order Saved As Draft</h1>
        <div className="message">
          <p>
            Thank you for your forward order, this has been added to your
            account and an email confirmation has been sent to you.
          </p>
          {showNewAccountMessage(
            location.state.result.type,
            location.state.result.account
          )}
        </div>
        {adminAuth ? (
          <button className="btn btn-clear-cart" onClick={handleSearch}>
            Search Order
          </button>
        ) : (
          <button className="btn btn-clear-cart" onClick={handleClearCart}>
            Start New Order
          </button>
        )}
        <img
          alt="strive footwear mara"
          src="https://strivefootwear.com/media/catalog/product/cache/faf5684043e134731bca051c1014d502/m/a/mara_cuban_gold_8.jpg"
        />
      </div>
    ) : (
      <div className="content success">
        <h1>Success</h1>
        <div className="message">
          <p>
            Thank you for your forward order, this has been added to your
            account and an email confirmation has been sent to you.
          </p>
          {showNewAccountMessage(
            location.state.result.type,
            location.state.result.account
          )}
        </div>
        {adminAuth ? (
          <button className="btn btn-clear-cart" onClick={handleSearch}>
            Search Order
          </button>
        ) : (
          <button className="btn btn-clear-cart" onClick={handleClearCart}>
            Start New Order
          </button>
        )}
        <img
          alt="strive footwear mara"
          src="https://strivefootwear.com/media/catalog/product/cache/faf5684043e134731bca051c1014d502/m/a/mara_cuban_gold_8.jpg"
        />
      </div>
    )
  ) : null;
  // (
  //   <Redirect
  //     to={{
  //       pathname: "/",
  //     }}
  //   />
  // );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {
  orderSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
