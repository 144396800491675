import React from "react";
import SelectInput from "../shared/SelectInput";

const AccountType = ({ onChange, accountType }) => {
  return (
    <SelectInput
      name="customerType"
      onChange={onChange}
      defaultOption="-"
      value={accountType}
      label="Customer Type"
      options={[
        { value: "-", text: "Select customer type" },
        { value: "NEW", text: "NEW" },
        { value: "CURRENT", text: "CURRENT" },
      ]}
      disabled={false}
    />
  );
};

export default AccountType;
