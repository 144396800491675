import React from "react";
import TextInput from "../../shared/TextInput";

export default function Comment({ comments, onChange }) {
  return (
    <div className="confirm-declaration">
      <h3>Comments</h3>
      <TextInput
        name="comments"
        label="Enter any further order details"
        value={comments}
        placeholder="Enter comments"
        onChange={onChange}
      />
    </div>
  );
}
