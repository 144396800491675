import React from "react";
import TextInput from "../shared/TextInput";
import SelectInput from "../shared/SelectInput";
import CustomerAccounts from "./CustomerAccounts";
import AccountType from "./AccountType";

const CustomerForm = ({
  customer,
  currency,
  accounts,
  account,
  customerFormOpen = true,
  onChange,
  errors = {},
  accountType,
}) => {
  const showCustomerDropDown = () => {
    return accountType === "CURRENT" ? (
      <div className="customer-details">
        <h3>Account Search</h3>
        <div className="account-number-group">
          <CustomerAccounts
            customer={customer}
            accounts={accounts}
            account={account}
            onChange={onChange}
            errors={errors}
            accountType={accountType}
          />
        </div>
      </div>
    ) : null;
  };

  const showInputFields = () => {
    return (accountType === "CURRENT" && account !== "-" && account !== "") ||
      accountType === "NEW" ? (
      <>
        <TextInput
          name="storeName"
          label="Store Name"
          value={customer.storeName}
          placeholder="Store Name"
          onChange={onChange}
          error={errors.storeName}
          className="checkbox"
        />
        <TextInput
          name="contactNameFirst"
          label="Contact Name"
          value={customer.contactNameFirst}
          placeholder="Forename"
          onChange={onChange}
          error={errors.contactNameFirst}
        />
        <TextInput
          name="contactNameLast"
          label="Contact Surname"
          value={customer.contactNameLast}
          placeholder="Surname"
          onChange={onChange}
          error={errors.contactNameLast}
        />
        <TextInput
          name="phone"
          label="Phone"
          value={customer.phone}
          placeholder="Phone"
          onChange={onChange}
          error={errors.phone}
        />
        <TextInput
          name="email"
          label="Email"
          value={customer.email}
          placeholder="Email"
          onChange={onChange}
          error={errors.email}
        />
      </>
    ) : null;
  };

  return customerFormOpen ? (
    <>
      <div className="customer-details">
        <h3>Currency &amp; Customer Type</h3>
        <SelectInput
          name="currency"
          onChange={onChange}
          defaultOption="Select currency"
          value={currency}
          label="Currency"
          disabled={false}
          options={[
            { value: "-", text: "Select currency" },
            { value: "£", text: "GBP" },
            { value: "€", text: "EUR" },
            { value: "$", text: "USD" },
          ]}
        />
        <AccountType accountType={accountType} onChange={onChange} />
      </div>
      {showCustomerDropDown()}
      <div className="customer-details">
        <h3>Customer Details</h3>
        {showInputFields()}
      </div>
    </>
  ) : null;
};

export default CustomerForm;
