import React, { useEffect, useState } from "react";
import CustomerForm from "./CustomerForm";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AddressBook from "../address/AddressBook";
import CustomerDetails from "./CustomerDetails";
import ConfirmCustomerButton from "./ConfirmCustomerButton";
import { saveCustomer } from "../../redux/actions/customerActions";
import { loadAccounts } from "../../redux/actions/accountsReducer";
import { saveCurrency } from "../../redux/actions/currencyActions";
import { setInitialAddress } from "../../redux/actions/addressActions";
import { setInitialShippingAddress } from "../../redux/actions/shippingAddressActions";
import { setInitialBillingAddress } from "../../redux/actions/billingAddressActions";
import { addPos } from "../../redux/actions/posActions";
import "./Index.css";
import * as EmailValidator from "email-validator";
import { requestUpdate } from "../../redux/actions/updateActions";

function CustomerPage({
  history,
  accounts,
  saveCustomer,
  saveCurrency,
  requestUpdate,
  setInitialAddress,
  setInitialShippingAddress,
  setInitialBillingAddress,
  loadAccounts,
  addPos,
  auth,
  adminAuth,
  amend,
  ...props
}) {
  const [customer, setCustomer] = useState(props.customer);
  const [currency, setCurrency] = useState(props.currency);
  const [pos, setPos] = useState(null);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [account, setAccount] = useState(props.customer.account);
  const [accountType, setAccountType] = useState(
    props.customer.type ? props.customer.type : "-"
  );
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [customerFormOpen, setCustomerFormOpen] = useState(true);

  useEffect(() => {
    if (Object.keys(customer).length > 1) {
      setCustomerFormOpen(false);
    }
    if (accounts.length === 0 || accounts[0].update) {
      if (adminAuth) {
        auth = adminAuth;
      }
      loadAccounts(auth)
        .then(() => {
          filterAccountsByCurrency(currency);
        })
        .catch((error) => {
          console.log("connection not avaialable to get accounts");
        });
    } else {
      filterAccountsByCurrency(currency);
    }
  }, []);

  useEffect(() => {
    filterAccountsByCurrency(currency);
  }, [currency]);

  function formIsValid() {
    const { storeName, contactNameFirst, phone, email } = customer;
    const errors = {};

    if (!storeName || storeName === null)
      errors.storeName = "Store Name is required.";
    if (!contactNameFirst || contactNameFirst === null)
      errors.contactNameFirst = "First name is required";
    if (!phone || phone === null) errors.phone = "Phone is required";
    if (!email || email === null) errors.email = "Email is required";
    if (!EmailValidator.validate(email)) errors.email = "Valid email required";
    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  const getAccount = (selectedAccount) => {
    if (selectedAccount && selectedAccount.value) {
      let accountNumber = selectedAccount.value.split("/")[0].trim();
      let foundAccount = accounts.find((account) => {
        return account.account === accountNumber;
      });
      return foundAccount;
    }
  };

  const filterAccountsByCurrency = (value) => {
    let transCurrency = "";
    let filter = [];

    switch (value) {
      case "$":
        transCurrency = "U";
        break;
      case "£":
        transCurrency = "";
        break;
      case "€":
        transCurrency = "EUR";
        break;
      default:
        transCurrency = "";
        break;
    }

    accounts
      .filter((account) => account.currency === transCurrency)
      .map((filterAccount) => {
        filter.push(filterAccount);
      });

    if (filter && filter.length > 0) {
      setFilteredAccounts(filter);
    } else {
      setFilteredAccounts(accounts);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("name", name);
    if (name.indexOf("currency") !== -1) {
      setCurrency(value);
      filterAccountsByCurrency(value);
      requestUpdate("currency");
    } else if (name.indexOf("customerType") !== -1) {
      setAccountType(value);
      if (value === "NEW") {
        setAccount(value);
        setCustomer({
          storeName: "",
          phone: "",
          email: "",
          account: "NEW",
          type: value,
        });
      } else {
        if (account === "NEW") {
          setCustomer({
            storeName: "",
            phone: "",
            email: "",
            account: value,
            type: value,
          });
          setAccount("-");
        } else {
          let newCustomerValue = customer;
          customer.type = value;
          setCustomer(newCustomerValue);
        }
      }
    } else if (name.indexOf("account") !== -1) {
      setAccount(value);
      console.log("name", name);

      let selectedAccount = getAccount(value);
      if (selectedAccount != null) {
        console.log("selectedAccount", selectedAccount);

        let country = "United Kingdom";
        if (currency) {
          switch (currency) {
            case "€":
              country = "Ireland";
              break;
            case "$":
              country = "United States";
              break;
            default:
              country = "United Kingdom";
              break;
          }
        }
        setCustomer({
          storeName: selectedAccount.name,
          phone: selectedAccount.telephone,
          email: selectedAccount.email,
          account: selectedAccount.account,
          type: accountType,
          contactNameFirst: "",
          contactNameLast: "",
        });
        setInitialShippingAddress({
          country: country,
          address1: selectedAccount.address1,
          address2: selectedAccount.address2,
          town: selectedAccount.address3,
          county: selectedAccount.address4,
          postcode: selectedAccount.postcode,
        });
        setInitialBillingAddress({
          country: country,
          address1: selectedAccount.address1,
          address2: selectedAccount.address2,
          town: selectedAccount.address3,
          county: selectedAccount.address4,
          postcode: selectedAccount.postcode,
        });
        setInitialAddress({
          country: country,
          address1: selectedAccount.address1,
          address2: selectedAccount.address2,
          town: selectedAccount.address3,
          county: selectedAccount.address4,
          postcode: selectedAccount.postcode,
        });
        setPos(null);
      } else {
        setCustomer({
          storeName: "",
          phone: "",
          email: "",
          account: "NEW",
        });
        setPos([
          {
            sku: "L734",
            name: "A6 Showcard",
            qty: "1",
          },
          {
            sku: "L751",
            name: "A6 Name Block",
            qty: "1",
          },
          {
            sku: "L500",
            name: "Insole POS",
            qty: "1",
          },
        ]);
      }
    } else {
      setCustomer((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.name === "isNew" ? target.checked : target.value;
    const name = target.name;
    setIsNew(value);
    setCustomer((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (value) {
      setCustomer((prev) => ({
        ...prev,
        ["accountNumber"]: null,
      }));
    }
  };

  const handleCustomerSave = (event) => {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    try {
      saveCustomer(customer);
      saveCurrency(currency);
      addPos(pos);
      setCustomerFormOpen(false);
      toast.success("Customer has been saved", { autoClose: true });
      setSaving(false);
      if (adminAuth) {
        history.push("/admins/styles");
      } else {
        history.push("/styles");
      }
    } catch (error) {
      setSaving(false);
      setErrors({ onSave: error.message });
    }
  };

  const reOpenCustomerForm = () => {
    setCustomerFormOpen(true);
  };

  return (
    <div className="content customer">
      <h1>Customer</h1>
      <p class="message">
        If you have an ongoing order, you will need to start the order again if
        you change the account number
      </p>
      <CustomerDetails
        customer={props.customer}
        currency={props.currency}
        customerFormOpen={customerFormOpen}
        reOpenCustomerForm={reOpenCustomerForm}
      />
      <CustomerForm
        onChange={handleChange}
        onSave={handleCustomerSave}
        errors={errors}
        saving={saving}
        customer={customer}
        currency={currency}
        accounts={filteredAccounts}
        account={account}
        isNew={isNew}
        customerFormOpen={customerFormOpen}
        handleInputChange={handleInputChange}
        amend={amend}
        accountType={accountType}
      />
      <AddressBook currency={currency} customer={customer} />
      <ConfirmCustomerButton
        onClick={handleCustomerSave}
        customer={customer}
        addresses={props.shippingAddresses}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    customer: state.customer,
    currency: state.currency,
    addresses: state.addresses,
    billingAddresses: state.billingAddresses,
    shippingAddresses: state.shippingAddresses,
    accounts: state.accounts,
    pos: state.pos,
    adminAuth: state.adminAuth,
    auth: state.auth,
    amend: state.amend,
  };
}

const mapDispatchToProps = {
  saveCustomer,
  saveCurrency,
  loadAccounts,
  setInitialAddress,
  setInitialShippingAddress,
  setInitialBillingAddress,
  requestUpdate,
  addPos,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
