import React, {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import {
  FaCaretLeft,
  FaCaretRight,
  FaExclamationTriangle,
} from "react-icons/fa";
import "./index.css";
import { connect } from "react-redux";

// import { Button, Form, Input, Jumbotron } from "reactstrap";
// import { submitOrder } from "../../api/orderApi";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { FlexboxGrid, Input, Button, Loader } from "rsuite";
import { updatePlanner, getPlannerById } from "../../../api/orderApi";
import useStickyHeader from "./useStickyHeader.js";

function EditOrderPlanner({ adminAuth }) {
  const [planner, setPlanner] = useState("");
  const [plannerData, setPlannerData] = useState([]);
  const [orignalPlannerData, setOrignalPlannerData] = useState();
  const [orignalSheet, setOrignalSheet] = useState(false);
  const [allocate, setAllocate] = useState([false, false]);
  const [columns, setColumns] = useState([]);
  const [stockCheck, setStockCheck] = useState([]);
  const [errors, setErrors] = useState([]);
  const [currentErrorIndex, setcurrentErrorIndex] = useState(-1);
  const itemsRef = useRef([]);
  const [tableWidth, setTableWidth] = useState(0);
  const { tableRef, isSticky } = useStickyHeader();

  const { Id } = useParams();

  let history = useHistory();
  useEffect(() => {
    console.log("currentErrorIndex", currentErrorIndex);
  }, [currentErrorIndex]);
  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);
  useEffect(() => {
    console.log("tableWidth 1", tableWidth);
    if (plannerData.length > 0) {
      setTableWidth(tableRef.current.offsetWidth);
    }
    console.log("errors", errors);
  }, [plannerData]);
  useEffect(() => {
    if (columns.length === 0) {
      console.log("initiate call");
      getPlannerById(Id, adminAuth)
        .then((response) => {
          let data = response[0];
          // console.log("data", data);
          let clms = JSON.parse(data.Columns);
          // console.log("clms", clms);
          let excelCol = [];
          JSON.parse(data.Columns).map((col, key) => {
            if (key > 8) {
              excelCol.push(col);
            }
          });
          let tempPlannerData = JSON.parse(data.PlannerData);

          let tempData = JSON.parse(
            JSON.stringify(tempPlannerData.plannerData)
          );
          let tempExcelData = [];
          tempData.map((row, key) => {
            let tempData = row;
            tempData["allocate"] = allocate[key];
            tempExcelData.push(tempData);
          });
          itemsRef.current = itemsRef.current.slice(
            0,
            tempPlannerData.plannerData.length
          );
          setColumns(clms);
          setPlanner(data.PlannerName);
          setAllocate(tempPlannerData.allocate);
          setStockCheck(tempPlannerData.stockCheck);
          setPlannerData(tempPlannerData.plannerData);
          setOrignalPlannerData(tempPlannerData.orignal_planner_data);
          setOrignalSheet(tempPlannerData.orignal_sheet);
          updateErrors(tempPlannerData.plannerData);
        })
        .catch((e) => {
          console.log("catch", e);
          setPlannerData([]);
          setAllocate([]);
          setStockCheck([]);
        });
    } else {
      console.log("here i am");
    }
  }, []);
  const ExcelDateToJSDate = (date) => {
    if (date == "Totals") {
      return date;
    }
    // console.log('date', date);
    let converted_date = new Date(Math.round((date - 25569) * 864e5));
    converted_date = String(converted_date).slice(4, 15);
    date = converted_date.split(" ");
    let day = date[1];
    let month = date[0];
    month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1;
    if (month.toString().length <= 1) month = "0" + month;
    let year = date[2];
    return String(month + "/" + day + "/" + year.slice(2, 4));
  };
  function handleChange(val, event) {
    setPlanner(val);
  }
  function handleCheckboxChange(event, index, row) {
    const { checked } = event.target;

    // console.log("orignalSheet", orignalSheet);
    let newStockCheck = [...stockCheck];
    let is_any_checked = false;
    let checked_col_index = 0;
    columns.map((column, key) => {
      if (stockCheck[index][column]) {
        is_any_checked = true;
        checked_col_index = key;
      }
    });
    if (checked) {
      if (!is_any_checked) {
        toast.error("Please Select Stock Checkbox", {
          autoClose: true,
        });
        return;
      }
      let newAllocate = [...allocate];
      newAllocate[index] = true;
      setAllocate(newAllocate);
      let newPlannerData = [...plannerData];
      columns.map((column, key) => {
        if (key > 8) {
          if (stockCheck[index][column]) {
            /**Iterating through each row */
            plannerData.map((row, i) => {
              if (newPlannerData[index]["sku"] === row["sku"]) {
                if (newPlannerData[index]["qty"] <= newPlannerData[i][column]) {
                  let rem_qty = 0;

                  /**current stock can full fill stock*/
                  for (let k = checked_col_index; k < columns.length; k++) {
                    //checking if delivered quantity is greater than requested quantity
                    // console.log(
                    //   "newPlannerData[i][columns[k]]",
                    //   newPlannerData[i][columns[k]]
                    // );
                    // console.log(
                    //   'newPlannerData[index]["qty"]',
                    //   newPlannerData[index]["qty"]
                    // );

                    if (
                      newPlannerData[i][columns[k]] >=
                      newPlannerData[index]["qty"]
                    ) {
                      newPlannerData[i][columns[k]] =
                        newPlannerData[i][columns[k]] -
                        newPlannerData[index]["qty"];
                    } else {
                      newPlannerData[i][columns[k]] = 0;
                    }
                  }
                  /**current stock can full fill stock ends*/

                  /**if current checked stock can not fulfill required quantity look into previous ones*/
                  if (
                    orignalSheet[index][column] < newPlannerData[index]["qty"]
                  ) {
                    rem_qty =
                      newPlannerData[index]["qty"] -
                      orignalSheet[index][column];
                    // console.log("rem qty", rem_qty);
                    for (let j = columns.length; j > 8; j--) {
                      if (rem_qty == 0) break;

                      if (rem_qty > 0) {
                        //condition for current and previous quantities
                        if (j < checked_col_index) {
                          if (orignalSheet[index][columns[j]] == 0) {
                            newPlannerData[i][columns[j]] =
                              newPlannerData[i][columns[j]] -
                              newPlannerData[index]["qty"];
                            continue;
                          }
                          if (
                            newPlannerData[i][columns[j]] >= rem_qty //checking if delivered quantity is greater than requested quantity
                          ) {
                            newPlannerData[i][columns[j]] =
                              newPlannerData[i][columns[j]] - rem_qty;
                            rem_qty = 0;
                          } else {
                            rem_qty = rem_qty - newPlannerData[i][columns[j]];
                            newPlannerData[i][columns[j]] = 0;
                          }
                        }
                      }
                    }
                  }
                  /**if current checked stock can not fulfill required quantity ends*/

                  newStockCheck[index][column] = true;
                }
                // if (i != index) {
                //   if (stockCheck[index][column]) {
                //     if (!allocate[i]) {
                //       if (
                //         newPlannerData[i][column] >= newPlannerData[i]["qty"]
                //       ) {
                //         let tempClass = newPlannerData[i]["class"].replace(
                //           "bg-red out_of_stock",
                //           ""
                //         );
                //         newPlannerData[i]["class"] = tempClass;
                //       } else {
                //         if (
                //           !(newPlannerData[i]["class"].indexOf("bg-red") >= 0)
                //         )
                //           newPlannerData[i]["class"] += " bg-red out_of_stock";
                //       }
                //     }
                //   }
                // }
              }
            });
            /**Iterating through each row ends */
          }
        }
      });
      setPlannerData(newPlannerData);
    } else {
      // console.log("newOrignalPlannerData", orignalPlannerData[index]);
      let newAllocate = [...allocate];
      newAllocate[index] = false;
      setAllocate(newAllocate);
      let newPlannerData = [...plannerData];

      /**updating current clicked allocate button row*/

      let rem_qty = 0;

      // console.log("checked_col_index", checked_col_index);
      /**current stock can full fill stock*/
      for (let k = checked_col_index; k < columns.length; k++) {
        if (stockCheck[index][columns[k]]) {
          if (orignalSheet[index][columns[k]] == 0) {
            rem_qty = newPlannerData[index]["qty"];
            // continue;
          }
          if (newPlannerData[index]["qty"] > orignalSheet[index][columns[k]]) {
            rem_qty =
              newPlannerData[index]["qty"] - orignalSheet[index][columns[k]];
            // console.log("tempSum rem_qty", rem_qty);
          }
        }
        newPlannerData[index][columns[k]] =
          newPlannerData[index][columns[k]] + newPlannerData[index]["qty"];
      }
      /**current stock can full fill stock ends*/
      let is_done = 0;
      let count = 0;
      for (let j = checked_col_index - 1; j > 8; j--) {
        // if (orignalSheet[index][columns[j]] == 0) {
        //   rem_qty = newPlannerData[index]["qty"];
        //   // continue;
        // } else {

        // if (rem_qty < newPlannerData[index][columns[j]]) {
        //   is_done = 1;
        // }
        // }
        // console.log("remaining quantity", rem_qty);

        if (rem_qty != 0) {
          // if (count > 0) {
          //   if (orignalPlannerData[index][columns[j]] != 0) {
          //     if (rem_qty > orignalSheet[index][columns[j]]) {
          //       rem_qty =
          //         newPlannerData[index]["qty"] -
          //         orignalSheet[index][columns[j]];
          //     } else {
          //       rem_qty = 0;
          //     }
          //   }
          // }
          // console.log("prev rem qty", rem_qty);

          if (
            orignalSheet[index][columns[j]] == 0 &&
            orignalPlannerData[index][columns[j]] == 0
          ) {
            break;
          } else if (
            orignalSheet[index][columns[j]] == 0 &&
            orignalPlannerData[index][columns[j]] != 0
          ) {
            newPlannerData[index][columns[j]] =
              newPlannerData[index][columns[j]] + rem_qty;
          } else if (
            orignalSheet[index][columns[j]] != 0 &&
            orignalPlannerData[index][columns[j]] != 0
          ) {
            newPlannerData[index][columns[j]] =
              newPlannerData[index][columns[j]] + rem_qty;
            rem_qty = 0;
            // if (is_done) {
            //   rem_qty = 0;
            // }
            // if (rem_qty < newPlannerData[index][columns[j]]) {
            //   is_done = 1;
            // }
          }
        }
        count++;
      }
      columns.map((column, key) => {
        if (key > 8) {
          /**if current checked stock can not fulfill required quantity look into previous ones*/

          /**if current checked stock can not fulfill required quantity ends*/

          // console.log("asdasd", newPlannerData[index]["class"]);
          // if (
          //   orignalPlannerData[index][column] != 0 &&
          //   orignalPlannerData[index][column] >=
          //     orignalPlannerData[index]["qty"]
          // ) {
          //   newPlannerData[index][column] =
          //     newPlannerData[index]["qty"] + newPlannerData[index][column];
          // }
          if (stockCheck[index][column]) {
            newStockCheck[index][column] = false;
          }
        }
      });
      /**updating current clicked allocate button row end*/

      /**updating other similar skus*/
      plannerData.map((row, i) => {
        if (i !== index) {
          if (newPlannerData[index]["sku"] == row["sku"]) {
            columns.map((column, key) => {
              if (key > 8) {
                newPlannerData[i][column] = newPlannerData[index][column];
                // console.log("data row", row);
                // console.log("stock check", stockCheck[index][column]);
              }
            });
          }
        }
      });
      /**updating other similar skus end*/

      setStockCheck(newStockCheck);
      setPlannerData(newPlannerData);
    }
  }

  function StockCheckboxChange(event, index, key) {
    const { name, value, checked } = event.target;
    if (allocate[index]) {
      toast.error("Please Unselect Allocate and then check stock boxes", {
        autoClose: true,
      });
      return false;
    }
    let is_any_checked = false;

    if (checked) {
      columns.map((column, key) => {
        if (key > 8) {
          if (stockCheck[index][column]) is_any_checked = true;
        }
      });
      // console.log("is_any_checked", is_any_checked);
      if (is_any_checked) {
        toast.error("Please Uncheck other checkbox from same row", {
          autoClose: true,
        });
        return;
      }
      if (
        plannerData[index][key] == 0 ||
        plannerData[index]["qty"] > plannerData[index][key]
      ) {
        toast.error("Quantity should be greater than required quantity", {
          autoClose: true,
        });
        return;
      }
      let newStockCheck = [...stockCheck];
      newStockCheck[index][key] = true;
      setStockCheck(newStockCheck);
    } else {
      let newStockCheck = [...stockCheck];
      newStockCheck[index][key] = false;
      setStockCheck(newStockCheck);
    }
  }
  function submitData() {
    // console.log("planner", planner);
    // console.log("plannerData", plannerData);
    //   return;

    if (!planner) {
      toast.error("Please Write Planner Name", {
        autoClose: true,
      });
      return;
    }
    if (plannerData) {
      // if (validatePlannerData() == 0) {
      //   toast.error(
      //     "Please check whole sheet for errors and perform advised actions",
      //     {
      //       autoClose: true,
      //     }
      //   );
      //   return;
      // }
      let data = {
        plannerData: plannerData,
        allocate: allocate,
        orignal_planner_data: orignalPlannerData,
        orignal_sheet: orignalSheet,
      };
      let formData = {
        data: data,
        columns: columns,
        stockCheck: stockCheck,
        planner_name: planner,
        id: Id,
      };
      console.log("formdata", formData);

      updatePlanner(formData, adminAuth)
        .then((response) => {
          console.log("response", response);
          let msg = "Planner updated Successfully";
          toast.success(msg, {
            autoClose: true,
          });
          // window.reload();
          setPlannerData([]);
          setStockCheck([]);
          setAllocate([false, false]);
          setColumns([]);
          history.push("/admins/orderplanner");
        })
        .catch((e) => {
          toast.error("Something Went Wrong, Try again", {
            autoClose: true,
          });
          // setOnline(false);
        });
    }
  }
  function validatePlannerData() {
    let is_valid = 1;
    let soNoOutOfStock = [];
    plannerData.map((data, index) => {
      if (data["class"].indexOf("bg-red") >= 0) {
        if (data["partial_order"] == "NO") {
          soNoOutOfStock.push(data["so_number"]);
        }
      }
    });
    soNoOutOfStock = [...new Set(soNoOutOfStock)];
    // console.log("out of stock", soNoOutOfStock);
    for (let i = 0; i < plannerData.length; i++) {
      if (soNoOutOfStock.includes(plannerData[i]["so_number"])) {
        if (allocate[i]) {
          //if allocate is checked
          columns.map((clm, key) => {
            if (key > 8) {
              if (stockCheck[i][clm]) {
                is_valid = 0;
              }
            }
          });
        }
        if (is_valid == 0) {
          // console.log("not valid");
          break;
        }
      }
    }

    // console.log("valid", is_valid);

    return is_valid;
  }
  function validateSheet() {
    let newPlannerData = [...plannerData];
    plannerData.map((row, index) => {
      let flag = 0;
      for (let i = 9; i < columns.length; i++) {
        if (allocate[index]) {
          let tempClass = newPlannerData[index]["class"].replace(
            " bg-red out_of_stock",
            ""
          );
          newPlannerData[index]["class"] = tempClass;
          break;
        }

        if (newPlannerData[index]["class"].indexOf("bg-red") >= 0) {
          if (parseInt(row["qty"]) <= parseInt(row[columns[i]])) {
            let tempClass = newPlannerData[index]["class"].replace(
              " bg-red out_of_stock",
              ""
            );
            newPlannerData[index]["class"] = tempClass;
            console.log("removing Red");
            flag = 1;
            let check = 0;
            for (let pi = 0; pi < plannerData.length; pi++) {
              if (plannerData[pi]["so_number"] == row["so_number"]) {
                console.log("removing Orange");
                console.log(
                  'row["so_number"]',
                  newPlannerData[pi]["so_number"]
                );

                console.log('row["sku"]', newPlannerData[pi]["sku"]);
                console.log("tempClass1 before", newPlannerData[pi]["class"]);

                let tempClass1 = newPlannerData[pi]["class"].replace(
                  " bg-orange",
                  ""
                );
                console.log("tempClass1 after", tempClass1);
                newPlannerData[pi]["class"] = tempClass1;

                check = 1;
                console.log("all data", newPlannerData[pi]);
              } else if (plannerData[pi]["so_number"] != row["so_number"]) {
                if (check == 1) {
                  break;
                }
              }
            }

            break;
          }
        } else if (row["qty"] > row[columns[i]]) {
          if (!allocate[index]) {
            if (!(row["class"].indexOf("bg-red") >= 0))
              newPlannerData[index]["class"] += " bg-red out_of_stock";
          }
        }
      }

      // if (row["sku"] == "21482D-4" && row["so_number"] == "PL-SO67797") {
      //   console.log("flag", flag);
      //   console.log("row data", row);
      // }
      // if (flag == 0) {
      //   if (!(row["class"].indexOf("bg-red") >= 0))
      //     newPlannerData[index]["class"] += " bg-red out_of_stock";
      // }
    });
    // SO NUMBER :PL-SO67797
    newPlannerData = highLightOrder(newPlannerData);
    setPlannerData(newPlannerData);
    updateErrors(newPlannerData);
  }
  const goToError = (dir) => {
    if (errors.length == 0) {
      toast.error("There are no Errors on sheet", {
        autoClose: true,
      });
      return;
    }
    errors.map((ele, index) => {
      itemsRef.current[errors[index]].classList.remove("current_error");
    });
    let newCurrentErrorIndex = currentErrorIndex;
    let errorCount = errors.length - 1;
    if (dir == 0) {
      if (newCurrentErrorIndex == -1 || newCurrentErrorIndex == 0) {
        newCurrentErrorIndex = errorCount;
      } else {
        newCurrentErrorIndex -= 1;
      }
    } else {
      if (newCurrentErrorIndex == errorCount) {
        newCurrentErrorIndex = 0;
      } else {
        newCurrentErrorIndex += 1;
      }
    }
    console.log("error index", newCurrentErrorIndex);
    itemsRef.current[errors[newCurrentErrorIndex]].classList.add(
      "current_error"
    );
    itemsRef.current[errors[newCurrentErrorIndex]].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    setcurrentErrorIndex(newCurrentErrorIndex);
  };
  function highLightOrder(newPlannerData) {
    let soNoOutOfStock = [];
    newPlannerData.map((data, index) => {
      if (data["class"].indexOf("bg-red") >= 0) {
        // if (data["partial_order"] == "NO") {
        soNoOutOfStock.push(data["so_number"]);
        // }
      }
    });
    soNoOutOfStock = [...new Set(soNoOutOfStock)];
    console.log("out of stock", soNoOutOfStock);

    for (let i = 0; i < plannerData.length; i++) {
      if (soNoOutOfStock.includes(plannerData[i]["so_number"])) {
        if (!(plannerData[i]["class"].indexOf("bg-red") >= 0)) {
          if (!(plannerData[i]["class"].indexOf("bg-orange") >= 0)) {
            newPlannerData[i]["class"] += " bg-orange";
          }
        }
      }
    }

    console.log("here inside");

    // setAllocate(newAllocate);
    // setStockCheck(newStockCheck);
    // setPlannerData(newPlannerData);
    return newPlannerData;
  }
  function updateErrors(newPlannerData) {
    let newErrors = [];
    newPlannerData.map((row, i) => {
      if (newPlannerData[i]["class"].indexOf("bg-red") >= 0) {
        newErrors.push(i);
      }
    });
    newErrors.sort((a, b) => (a > b ? 1 : -1));
    setErrors(newErrors);
  }
  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
        Order Planner
      </h2>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={4}>
          <label>Planner Name</label>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Input
            size="md"
            placeholder="Planner"
            name="planner_name"
            onChange={handleChange}
            value={planner}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {plannerData ? (
        <FlexboxGrid
          justify="end"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <FlexboxGrid.Item colspan={10}>
            <Button
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                marginRight: "10px",
              }}
              color="green"
              appearance="primary"
              onClick={submitData}
            >
              Update Planner
            </Button>
            <Button
              style={{ marginRight: "10px" }}
              color="green"
              appearance="primary"
              onClick={validateSheet}
            >
              Validate Sheet
            </Button>
            <FaExclamationTriangle
              style={{ marginRight: "3px" }}
              className="custom_icon"
            />
            <span className="error_text">{errors.length}</span>
            <FaCaretLeft className="custom_icon" onClick={() => goToError(0)} />
            <span className="error_text" style={{ color: "black" }}>
              {currentErrorIndex + 1}
            </span>
            <FaCaretRight
              className="custom_icon"
              onClick={() => goToError(1)}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      ) : null}
      {plannerData && plannerData.length > 0 ? (
        <>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={24}>
              {isSticky && (
                <>
                  <div
                    className="custom_div sticky"
                    style={{
                      width: tableWidth,
                    }}
                  >
                    <FaExclamationTriangle
                      style={{ marginRight: "3px" }}
                      className="custom_icon"
                    />
                    <span className="error_text">{errors.length}</span>
                    <FaCaretLeft
                      className="custom_icon"
                      onClick={() => goToError(0)}
                    />
                    <span className="error_text" style={{ color: "black" }}>
                      {currentErrorIndex + 1}
                    </span>
                    <FaCaretRight
                      className="custom_icon"
                      onClick={() => goToError(1)}
                    />
                  </div>
                  <table
                    className="sticky table custom_table"
                    style={{
                      width: tableWidth,
                    }}
                  >
                    <thead>
                      <tr>
                        {columns.map((column, index) => (
                          <th key={"header_" + index}>{column}</th>
                        ))}
                        <th>Allocate</th>
                      </tr>
                    </thead>
                  </table>
                </>
              )}
              <table ref={tableRef} className="table custom_table">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={index}>{column}</th>
                    ))}
                    <th>Allocate</th>
                  </tr>
                </thead>
                <tbody>
                  {plannerData.map((row, index) => {
                    return (
                      // index > 0 ?
                      <Fragment key={"fragment_" + index}>
                        <tr
                          key={"plannerData_" + index}
                          className={row["class"]}
                          ref={(el) => (itemsRef.current[index] = el)}
                        >
                          <td>{row["account"]}</td>
                          <td>{row["so_number"]}</td>
                          <td>{row["order_ref"]}</td>
                          <td>{row["sku"]}</td>
                          <td>{row["desc"]}</td>
                          <td style={{ textAlign: "center" }}>
                            {row["partial_order"]}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {row["priority"]}
                          </td>
                          <td>{row["req_ship"]}</td>
                          <td style={{ textAlign: "center" }}>
                            <b>{row["qty"]}</b>
                          </td>
                          {columns.map((column, key) => {
                            return key > 8 ? (
                              <td key={"dynamic_" + key}>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    StockCheckboxChange(e, index, column)
                                  }
                                  checked={stockCheck[index][column]}
                                />{" "}
                                <b
                                  className={
                                    stockCheck[index][column]
                                      ? "green-color"
                                      : null
                                  }
                                >
                                  {row[column]}
                                </b>
                              </td>
                            ) : null;
                          })}
                          <td>
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckboxChange(e, index, row)
                              }
                              checked={allocate[index]}
                            />
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </>
      ) : (
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={24}>
            <Loader size="md" center content="loading" />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    adminAuth: state.adminAuth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderPlanner);
