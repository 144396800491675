import React from "react";
import SelectInput from "../../shared/SelectInput";

export default function Promo({ promo, onChange }) {
  return (
    <div className="confirm-declaration">
      <h3>Promo</h3>
      <SelectInput
        name="promo"
        label="Enter promo for this order"
        onChange={onChange}
        defaultOption={promo}
        value={promo}
        options={[
          {
            value: "48 & 4",
            text: "48 & 4",
          },
          // {
          //   value: "30:36 program",
          //   text: "30:36 program",
          // },
          {
            value: "SLIPPER PROMO",
            text: "SLIPPER PROMO",
          },
        ]}
      />
    </div>
  );
}
