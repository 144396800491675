import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function adminAuthReducer(
  state = initialState.userData,
  action
) {
  switch (action.type) {
    case types.API_CALL_SUCCESS:
      return (state = { email: action.response.user });
    case types.ADMIN_AUTH_SESSION_CLEAR:
      return (state = null);
    default:
      return state;
  }
}
