import React from "react";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/authActions";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function Logout({ logout, auth, ...props }) {
  let history = useHistory();

  const handleLogout = () => {
    try {
      logout();
      toast.success("You are now logged Out.", { autoClose: true });
      history.push("/login");
    } catch (error) {
      toast.error("Sorry and error occured. " + error, { autoClose: true });
    }
  };

  return auth ? (
    <>
      <button className="btn btn-primary signOutbtn" onClick={handleLogout}>
        Sign Out
      </button>
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
