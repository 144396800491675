import React, { useEffect } from "react";
import { connect } from "react-redux";
import { requestUpdate } from "../../redux/actions/updateActions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./Update.css";
import * as cacheApi from "../../api/cacheApi";

const RequestUpdate = ({ requestUpdate, auth }) => {
  // useEffect(() => {
  //   // setTimeout(() => {
  //   //   requestUpdate();
  //   // }, 1000);

  //   cacheApi.getVersions(auth).then((versions) => {
  //     console.log("hello4", versions.data.new, versions.data.old);
  //     if (versions.data.new !== versions.data.old) {
  //       console.log("not same");
  //       cacheApi.updateOldVersion(auth).then((updatedoldVersion) => {
  //         console.log("hello5", updatedoldVersion);
  //         console.log("hello6");
  //         requestUpdate();
  //         // return updatedoldVersion;
  //       });
  //     }
  //   });
  // }, []);
  function handleConfirm() {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => requestUpdate(),
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  }
  return (
    <span
      className="btn btn-primary btn-update"
      onClick={() => handleConfirm()}
    >
      Update Content
    </span>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {
  requestUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestUpdate);
